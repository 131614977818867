import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {LocalStorageService, SessionStorageService, LocalStorage, SessionStorage} from 'angular-web-storage';
import {throwError, Subject, concat, Observable, of, EMPTY } from 'rxjs';
import {catchError, map} from 'rxjs/operators';
import {GlobalConstants} from '../global-constants';
import {Router} from '@angular/router';
import {ToastrService} from "ngx-toastr";

@Injectable({
    providedIn: 'root'
})
export class DataService {


    constructor(private httpClient: HttpClient, private local: LocalStorageService, private router: Router, private toastr: ToastrService) {
    }

    public handleError(error: HttpErrorResponse) {
        console.log("error", error);

        let errorMessage = 'Unknown error!';
        if (error.error instanceof ErrorEvent) {
            // Client-side errors
            errorMessage = `Error: ${error.error.message}`;
        } else {
            switch (error.status) {
                case 401:
                    errorMessage = `Errore: ${error.status} ${error.statusText}\nMessage: ${error.error.message}`;
                    this.toastr.error(`${error.error.message}`, "Accesso negato");
                    this.router.navigate(['/login']); //redirect (alla login?)

                    //svuotiamo anche il jwt dal localStorage????? (si)...

                    break
                default:
                    // Server-side errors
                    window.alert(errorMessage); //da togliere in futuro
                    errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
            }
        }

        //window.alert(errorMessage); //da togliere in futuro

        return throwError(errorMessage);
    }


    //gestione variabile condivisa
    private _decodedJwt = new Subject<any>();
    decodedJwt$ = this._decodedJwt.asObservable();
    changeDecodedJwt(title) {
        this._decodedJwt.next(title);
    }


    public isAuthenticated(): boolean {    // Get token from localstorage
        let token = localStorage.getItem('jwt');    // Check if token is null or empty
        if (token){
            // Check whether the token is expired and return
            // true or false
            return true;
        }
        else{
            return false
        }
    }

    loginCheck(form) {
        return this.httpClient.post(GlobalConstants.API_LOGIN_CHECK, form)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    public getGeolocalizzazione(search) {
        //https://developer.here.com/projects
        var apiKey = "ftcGDzObNRMV5134yXS2V-j9j9NjfbAF4J_Y5MnsX3I";

        const httpOptions = {headers: new HttpHeaders({'Authorization': ''})};

        if (search != "") {
            return this.httpClient.get<any[]>("https://geocode.search.hereapi.com/v1/geocode?apiKey=" + apiKey + "&in=countryCode:ITA&q=" + search.replace(" ", "+"), httpOptions);
        } else {
            return EMPTY;
        }
    }


    public getListaSitiMicrozonazione() {
        return this.httpClient.get(GlobalConstants.API_SITI + 'Microzonazione').pipe(
          catchError((error: HttpErrorResponse) => this.handleError(error))
        );
      }
    public getListaSiti(userId) {
        return this.httpClient.get(GlobalConstants.API_SITI + '?userId=' + userId).pipe(
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }
    public getSito(id, tipo) {
        return this.httpClient.get(GlobalConstants.API_SITI + "/" + tipo + "/" + id ).pipe(
            catchError((error: HttpErrorResponse) => this.handleError(error))
        );
    }

    public getSitiGis(tipo) {
      return this.httpClient.get(GlobalConstants.liveSite + "/sitiGis/" +tipo ).pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
    }
    public getIndaginiGis(tipo) {
      return this.httpClient.get(GlobalConstants.liveSite + "/indaginiGis/" + tipo ).pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
    }
    public getComuniProvincia(provincia) {
      return this.httpClient.get(GlobalConstants.liveSite + "/comuni/provincia/" + provincia ).pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
    }

  public getListaUtenti(userId) {
    return this.httpClient.get(GlobalConstants. API_UTENTI+ '?userId=' + userId).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }



    /** CREATE: create sito to the database */
    createSito(id, tipo, sito) {
        const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

        return this.httpClient.post<any>(GlobalConstants.API_SITI + "/" + tipo + "/" + id, sito, httpOptions)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }
    /** PUT: update item to the database */
    updateSito(id, tipo, sito) {
        const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

        return this.httpClient.put<any>(GlobalConstants.API_SITI + "/" + tipo + "/" + id, sito, httpOptions)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }

    /** DELETE: file item from database */
    deleteFile(id) {
        const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

        return this.httpClient.delete<any>(GlobalConstants.API_FILES + "/" + id, httpOptions)
            .pipe(
                catchError((error: HttpErrorResponse) => this.handleError(error))
            );
    }


  /** POST: create/update indagine */
  createIndagine(idSito, tipo, idIndagine, indagine) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    return this.httpClient.post<any>(GlobalConstants.API_SITI + "/" + idSito + "/" + tipo +"/indagine/" + idIndagine, indagine, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }

  /** DELETE: file item from database */
  deleteIndagine(idSito, tipo, idIndagine) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    return this.httpClient.delete<any>(GlobalConstants.API_SITI + "/" + idSito + "/" + tipo +"/indagine/" + idIndagine, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }


  /** POST: duplico indagini selezionate */
  duplicaIndaginiEsistenti(idSito, tipo, arrayIndagini) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    return this.httpClient.post<any>(GlobalConstants.API_SITI + "/" + idSito + "/" + tipo +"/duplicaIndagini", arrayIndagini, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }

  /** DELETE: file item from database */
  deleteSito(id, tipo) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    return this.httpClient.delete<any>(GlobalConstants.API_SITI + "/" + tipo + "/" + id, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }

  /** CREATE: registrazione utente */
  userRegistration(utente) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    return this.httpClient.post<any>(GlobalConstants.liveSite + "/registration", utente, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }

  public recuperaPassword(username) {
    return this.httpClient.get(GlobalConstants.liveSite + "/recuperaPassword/" + username.recuperaPassword ).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }

  /** DELETE: file item from database */
  deleteUtente(id) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    return this.httpClient.delete<any>(GlobalConstants.API_UTENTI + "/" + id, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }

  /** POST: create/update indagine */
  createUpdateUtente(idUtente, utente) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    return this.httpClient.post<any>(GlobalConstants.API_UTENTI + "/" + idUtente, utente, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }

  /** POST: export siti */
  exportSiti(siti) {
    //const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    return this.httpClient.post<any>(GlobalConstants.API_EXPORT_SITI, siti, { responseType: 'blob' as 'json' })
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }


  public getListaStorico(id) {
    return this.httpClient.get(GlobalConstants.liveSite + "/api/listaStorico/" + id ).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }
  public getComuniStorico() {
    return this.httpClient.get(GlobalConstants.liveSite + "/api/comuniStorico").pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }
  public getVistaStorico(id, username) {
    return this.httpClient.get(GlobalConstants.liveSite + "/api/vistaStorico/" + id + "?utente=" + username).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }



  public verificaGA(jwt) {
    const httpOptions = {headers: new HttpHeaders({'Content-Type': 'application/json'})};

    return this.httpClient.post<any>(GlobalConstants.liveSite + "/verificaGA", jwt, httpOptions)
      .pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
      );
  }

  public recuperaCodiceGA(username) {
    return this.httpClient.get(GlobalConstants.liveSite + "/recuperaCodice/" + username ).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }
  public confermaCodiceGA(username, code) {
    return this.httpClient.get(GlobalConstants.liveSite + "/confermaCodice/" + username + "/" + code).pipe(
      catchError((error: HttpErrorResponse) => this.handleError(error))
    );
  }



}
