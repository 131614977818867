<section>
    <div class="container-fluid">
        <div class="row">
            <div class="col-12 col-md-9">
              <ng-template [ngIf]="comune?.numeroSiti == 0">
                <div class="alert alert-danger text-center" role="alert">
                  Nessun sito presente per il Comune di {{ comune?.nomeComune }}
                </div>

              </ng-template>

              <ng-template [ngIf]="comune?.numeroSiti > 0">
                <div id="map"
                     leaflet
                     [leafletOptions]="optionMap"
                     [leafletLayers]="arrayMarker"
                     [leafletFitBounds]="markerBounds"
                     (leafletMapReady)="onMapReady($event)">
                </div>
              </ng-template>

            </div>
            <div class="col-12 col-md pt-3">
                <h3>Comune di {{ comune?.nomeComune }}</h3>
                <div class="card">
                    <div class="card-body text-center">
                        <div class="row">
                            <div class="col-12 col-md">
                                Siti <i class="fal fa-info-circle"  data-toggle="tooltip" data-placement="top" title="{{ comune?.numeroSiti }}" data-html="true"></i>
                                <h3>{{ comune?.numeroSiti }}</h3>
                            </div>
                            <div class="col-12 col-md">
                                Indagini <i class="fal fa-info-circle"  data-toggle="tooltip" data-placement="top" title="{{ comune?.numeroIndaginiLineari }} lineari e {{ comune?.numeroIndaginiPuntuali }} puntuali" data-html="true"></i>
                                <h3>{{ comune?.numeroIndaginiLineari + comune?.numeroIndaginiPuntuali }}</h3>
                            </div>
                            <div class="col-12 col-md">
                                <br>
                                <a [routerLink]="['/']"><i class="fas fa-caret-right"></i> Vai alla mappa</a><br>
                                <a [routerLink]="['/comuni']"><i class="fas fa-caret-right"></i> Tutti i comuni</a>
                            </div>
                        </div>
                    </div>
                </div>
                <hr>
                <div id="search-collapse">
                <form id="search">
                  <div class="form-row">
                    <div class="col-12 mb-3">
                      <label for="filter-sisma">Tipo intervento</label>
                      <ng-select class="" id="filter-edilizia" name="filterEdilizia" placeholder="Inizia a scrivere" #filterEdilizia="ngModel" [(ngModel)]="filtroEdilizia" (change)="onChangeFiltroEdilizia(filterEdilizia)" [clearable]="false">
                        <ng-option value="" selected>Tutti</ng-option>
                        <ng-option value="int-pubblico">Intervento Pubblico</ng-option>
                        <ng-option value="pubblica">Edilizia Pubblica</ng-option>
                        <ng-option value="privata">Edilizia Privata</ng-option>
                        <ng-option value="microzonazione">Microzonazione</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-12 mb-3">
                      <label for="filter-sisma">Ambito indagine</label>
                      <ng-select class="" id="filter-sisma" name="filter-sisma" placeholder="Inizia a scrivere"  #filterSisma="ngModel" [(ngModel)]="filtroSisma" (change)="onChangeFiltroSisma(filterSisma)"  [clearable]="false">
                        <ng-option value="">Tutti</ng-option>
                        <ng-option value="sisma-2009">Sisma 2009</ng-option>
                        <ng-option value="sisma-2016">Sisma 2016</ng-option>
                        <ng-option value="nosisma">No sisma</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-12 mb-3">
                      <label for="filter_classe_indagine">Classe di indagine</label>
                      <ng-select class="" id="filter_classe_indagine" name="filter_classe_indagine" placeholder="Inizia a scrivere"  #filterClasseIndagine="ngModel" [(ngModel)]="filtroClasseIndagine" (change)="onChangeFiltroClasseIndagine(filterClasseIndagine)" [disabled]="!filtroSisma"  [clearable]="false">
                        <ng-option value="">Tutte</ng-option>
                        <ng-option [value]="item.sigClasseIndagine" *ngFor="let item of classeIndagine">{{ item.sigClasseIndagine }} - {{ item.desClasseIndagine }}</ng-option>
                      </ng-select>
                    </div>
                    <div class="col-12 mb-3">
                      <label for="filter_tipo_indagine">Tipo di indagine</label>
                      <ng-select class="" id="filter_tipo_indagine" name="filter_tipo_indagine" placeholder="Inizia a scrivere"  #filterTipoIndagine="ngModel" [(ngModel)]="filtroTipoIndagine" (change)="onChangeFiltroTipoIndagine(filterTipoIndagine)" [disabled]="!filtroClasseIndagine"  [clearable]="false">
                        <ng-option value="">Tutti</ng-option>

                        <ng-container *ngFor="let item of tipoIndagine">
                          <ng-template [ngIf]="filtroClasseIndagine == '' || filtroClasseIndagine == item.classe">
                            <ng-option [value]="item.sigTipoIndagine">{{ item.sigTipoIndagine }} - {{ item.desTipoIndagine }}</ng-option>
                          </ng-template>
                        </ng-container>
                      </ng-select>
                    </div>
                    <div class="col">
                      <label>Periodo da</label>
                      <input type="text"
                             placeholder="Da"
                             class="form-control"
                             bsDatepicker
                             [bsConfig]="{ containerClass: 'theme-default', dateInputFormat: 'DD/MM/YYYY', isAnimated: true , adaptivePosition: true }"
                             (bsValueChange)="onFilterDAchange($event)">
                    </div>
                    <div class="col">
                      <label>Periodo a</label>
                      <input type="text"
                             placeholder="A"
                             class="form-control"
                             bsDatepicker
                             [bsConfig]="{ containerClass: 'theme-default', dateInputFormat: 'DD/MM/YYYY', isAnimated: true , adaptivePosition: true }"
                             (bsValueChange)="onFilterAchange($event)">
                    </div>
                  </div>
                </form>
              </div>

            </div>
        </div>
    </div>

</section>


