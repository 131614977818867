import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'checkLabelGis',
  pure: false
})
export class CheckLabelGisPipe implements PipeTransform {

  transform(value: any[]): any {
    var mostraLabelGis = 0;
    for(let i=0; i<value.length; i++) {
      if (value[i].mostra == 1) {
        mostraLabelGis = 1;
      }
    }

    return mostraLabelGis;
  }

}
