import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtraMostra',
  pure: false
})
export class FiltraMostraPipe implements PipeTransform {

  transform(item: any): any {
    //console.log('item', item);

    let arrayList = [];
    for (let i=0; i< item.length; i++) {
      for (let k=0; k< item[i].value.length; k++) {
        if (item[i].value[k].mostra != undefined && item[i].value[k].mostra === 1) {
          arrayList.push(item[i]);
        }
      }
    }

    return arrayList;
  }
}
