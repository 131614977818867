<section id="actions" class="mt-4 mb-4">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 col-md">
                <h1>Siti indagini
                    <small><a href="javascript: void(0)" class="ml-3 d-none d-sm-none d-md-inline d-lg-inline" (click)="exportSiti()"><i class="fal fa-download"></i> Esporta CSV</a></small>
                </h1>
            </div>
            <div class="col col-md-3 text-md-right text-left">

                <div class="btn-group" dropdown>
                    <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                        Nuovo sito <span class="caret"></span>
                    </button>
                    <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                        role="menu" aria-labelledby="button-basic">
                        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/siti/lineari/new']">Sito lineare</a></li>
                        <li role="menuitem"><a class="dropdown-item" [routerLink]="['/siti/puntuali/new']">Sito puntuale</a></li>
                    </ul>
                </div>

            </div>
        </div>
    </div>
</section>


<section class="mb-3">
    <div class="container-fluid">
        <div class="row">
            <div class="col text-right">

                <div class="card text-left">
                    <div class="card-body p-2">
                        <div class="row align-items-center">
                            <div class="col text-left pb-2 mb-2 border-bottom">
                                <span class="">Cerca tra le indagini</span>
                            </div>
                        </div>
                        <div id="search-collapse">
                            <form id="search">
                                <div class="form-row">
                                    <div class="col-12 col-md-2">
                                        <label for="cerca">Testo libero</label>
                                        <input id="cerca" name="filter_cerca" type="text" class="form-control cercaFilter" placeholder="CUP, Protocollo, ID Genio Civile" #filterCerca="ngModel" [(ngModel)]="filtroCerca" (keyup)="onChangeFiltro(filterCerca)">
                                    </div>


                                    <!-- AGGIUNGERE FILTRO UTENTE SE SIAMO ADMIN -->

                                    <div class="col-12 col-md">
                                        <label for="filter-sisma">Sito</label>
                                        <select class="form-control chosen sitoFilter" id="filter-sito" name="filter-sito" placeholder="Inizia a scrivere"  #filterTipo="ngModel" [(ngModel)]="filtroTipo" (change)="onChangeFiltro(filterTipo)">
                                            <option value="">Tutti</option>
                                            <option value="puntuale">Sito puntuale</option>
                                            <option value="lineare">Sito lineare</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md">
                                        <label for="filter-edilizia">Tipologia intervento</label>
                                        <select class="form-control chosen ediliziaFilter" id="filter-edilizia" name="filter-edilizia" placeholder="Inizia a scrivere" #filterEdilizia="ngModel" [(ngModel)]="filtroEdilizia" (change)="onChangeFiltro(filterEdilizia)">
                                            <option value="">Tutti</option>
                                            <option value="int-pubblico">Intervento Pubblico</option>
                                            <option value="pubblica">Edilizia Pubblica</option>
                                            <option value="privata">Edilizia Privata</option>
                                            <option value="microzonazione">Microzonazione</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md">
                                        <label for="filter-sisma">Ambito indagine</label>
                                        <select class="form-control chosen sismaFilter" id="filter-sisma" name="filter-sisma" placeholder="Inizia a scrivere" #filterSisma="ngModel" [(ngModel)]="filtroSisma" (change)="onChangeFiltro(filterSisma)">
                                            <option value="">Tutti</option>
                                            <option value="sisma-2009">Sisma 2009</option>
                                            <option value="sisma-2016">Sisma 2016</option>
                                            <option value="nosisma">Altro</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md">
                                        <label for="filter-comune">Comune</label>
                                        <select class="form-control chosen comuneFilter" id="filter-comune" name="filter-comune" placeholder="Inizia a scrivere" #filterComune="ngModel" [(ngModel)]="filtroComune" (change)="onChangeFiltro(filterComune)">
                                            <option value="">Tutti</option>
                                            <option [value]="item.id" *ngFor="let item of arrayComuni">{{ item.comune }}</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md">
                                        <label for="filter_classe_indagine">Classe di indagine</label>
                                        <select class="form-control chosen" id="filter_classe_indagine" name="filter_classe_indagine" placeholder="Inizia a scrivere"  #filterClasseIndagine="ngModel" [(ngModel)]="filtroClasseIndagine" (change)="onChangeFiltro(filterClasseIndagine)">
                                            <option value="">Tutte</option>
                                            <option [value]="item.sigClasseIndagine" *ngFor="let item of classeIndagine">{{ item.sigClasseIndagine }} - {{ item.desClasseIndagine }}</option>
                                        </select>
                                    </div>
                                    <div class="col-12 col-md">
                                        <label for="filter_tipo_indagine">Tipo di indagine</label>
                                        <select class="form-control chosen" id="filter_tipo_indagine" name="filter_tipo_indagine" placeholder="Inizia a scrivere"  #filterTipoIndagine="ngModel" [(ngModel)]="filtroTipoIndagine" (change)="onChangeFiltro(filterTipoIndagine)">
                                            <option value="">Tutti</option>

                                            <ng-container *ngFor="let item of tipoIndagine">
                                                <ng-template [ngIf]="filtroClasseIndagine == '' || filtroClasseIndagine == item.classe">
                                                    <option [value]="item.sigTipoIndagine">{{ item.sigTipoIndagine }} - {{ item.desTipoIndagine }}</option>
                                                </ng-template>
                                            </ng-container>
                                        </select>
                                    </div>

                                  <div class="col-12 col-md">
                                    <label>Periodo da</label>
                                    <input type="text" name="filterDataDa"  #filterDataDa="ngModel" [(ngModel)]="filtroDataDa" readonly style="background-color: transparent"
                                           placeholder="Da"
                                           class="form-control"
                                           bsDatepicker
                                           [bsConfig]="{ containerClass: 'theme-default', dateInputFormat: 'DD/MM/YYYY', isAnimated: true , adaptivePosition: true, showClearButton: true }"
                                           (bsValueChange)="onChangeFiltro(filterDataDa)">
                                  </div>
                                  <div class="col-12 col-md">
                                    <label>Periodo a</label>
                                    <input type="text" name="filterDataA" #filterDataA="ngModel" [(ngModel)]="filtroDataA" readonly style="background-color: transparent"
                                           placeholder="A"
                                           class="form-control"
                                           bsDatepicker
                                           [bsConfig]="{ containerClass: 'theme-default', dateInputFormat: 'DD/MM/YYYY', isAnimated: true , adaptivePosition: true, showClearButton: true }"
                                           (bsValueChange)="onChangeFiltro(filterDataA)">
                                  </div>

                                  <div class="col-12 col-md">
                                        <label>Stato</label>
                                        <select class="form-control chosen statoFilter" id="stato" name="stato" placeholder="Inizia a scrivere"  #filterStato="ngModel" [(ngModel)]="filtroStato" (change)="onChangeFiltro(filterStato)">
                                            <option value="">Stato</option>
                                            <option value="sent">Inviata</option>
                                            <option value="open">Da inviare</option>
                                            <option value="tocomplete">Da completare</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>



<section id="list" class="request">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="table-responsive-sm">
                    <table class="table table-bordered text-left">
                        <thead>
                        <tr>
                            <th scope="col" class="text-center" style="width:3%">
                                #ID <a href="" class="sortById desc"><i class="fas fa-caret-down"></i></a>
                            </th>

                            <th scope="col">
                                ID GIS
                            </th>

                            <th scope="col">
                                Denominazione intervento/utente
                            </th>

                            <th scope="col">
                                Ambito
                            </th>

                            <th scope="col">
                                Comune
                            </th>
                            <th scope="col">
                                Tipologia
                            </th>
                            <th scope="col" class="text-center">
                                Data creazione <a href="#" class="sortByFrom desc"><i class="fas fa-caret-down"></i></a>
                            </th>
                            <th scope="col" class="text-center">
                                Stato
                            </th>
                            <th scope="col" class="text-center">
                                Azioni
                            </th>
                        </tr>
                        </thead>
                        <tbody>

                        <ng-container *ngFor="let itemGis of sitiList | keyvalue">
                            <tr [ngClass]="(itemGis.value | checkLabelGis) == 1 ? 'd-table-row' : 'd-none'" style="border-bottom: 2px solid #000000;">
                                <td colspan="9">
                                  <strong>Siti con ID GIS: {{ itemGis.value[0].gis }}</strong>
                                </td>
                            </tr>
                            <tr class="task-list-row" *ngFor="let item of itemGis.value | keyvalue; first as isFirst" [class.sent]="item.value.stato == 'sent'" [ngClass]="item.value.mostra == 1 ? 'd-table-row' : 'd-none'" >
                                <td scope="row" class="text-center row-id">
                                    {{ item.value.id }}
                                </td>

                                <ng-template [ngIf]="isFirst || item.value?.primoVisibile == 1">
                                  <td  scope="row" [attr.rowspan]="isFirst || item.value?.primoVisibile == 1 ? item.value.countVisible : ''" class="text-center rowIdGis">
                                    {{ item.value.gis }}
                                  </td>
                                </ng-template>

                                <td class="title text-uppercase">
                                    <h2><a routerLink="/siti/{{item.value.tipo | replaceEtichette }}/{{item.value.id}}">{{ item.value.denominazione }}</a></h2>
                                    {{ item.value.nomeUtente }}
                                </td>
                                <td>
                                    <strong>
                                        {{ item.value.edilizia | replaceEtichette }} / {{item.value.sisma | replaceEtichette}}
                                    </strong>
                                </td>
                                <td>
                                    <strong>{{ item.value.comune.comune }}</strong>
                                </td>
                                <td>
                                    <strong>Sito {{item.value.tipo}}</strong>
                                </td>
                                <td class="text-center">
                                    <strong>{{ item.value.dataSitoFormat }}</strong>
                                </td>

                                <ng-template [ngIf]="isFirst || item.value?.primoVisibile == 1">
                                    <td class="text-center" [attr.rowspan]="isFirst || item.value?.primoVisibile == 1 ? item.value.countVisible : ''">
                                        <strong>
                                            <container-element [ngSwitch]="item.value.stato">
                                                <some-element *ngSwitchCase="'sent'">

                                                  <ng-template [ngIf]="item?.value.stato == 'sent' && item?.value.certificato != ''" >
                                                    <a href="javascript: void(0)" class="btn btn-success btn-sm"  (click)="leggiFileById(item.value.certificato, itemGis.value[0].idGis)" tooltip="Visualizza certificato">
                                                      Inviata <fa-icon [icon]="['fas', 'file-pdf']"></fa-icon>
                                                    </a>
                                                    <br><small>({{ item.value.dataCertificato}})</small>
                                                  </ng-template>

                                                </some-element>
                                                <some-element *ngSwitchCase="'open'">
                                                  <a href="javascript: void(0)" class="btn btn-danger btn-sm" (click)="openModalGeneraRicevuteGis(modalGeneraRicevutaAll, itemGis)" tooltip="Invia e genera certificato">
                                                    Da inviare
                                                  </a>
                                                </some-element>
                                                <some-element *ngSwitchCase="'tocomplete'">Da completare</some-element>
                                                <some-element *ngSwitchDefault>-</some-element>
                                            </container-element>
                                        </strong>
                                    </td>
                                </ng-template>

                                <td class="actions text-center">

                                  <ng-template [ngIf]="item?.value.stato != 'sent'">
                                    <a routerLink="/siti/{{item.value.tipo | replaceEtichette }}/{{item.value.id}}" class="ml-2 mr-2" title="" tooltip="Modifica indagine" container="body" placement="left">
                                      <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                                    </a>

                                    <a href="javascript: void(0)" tooltip="Elimina indagine" (click)="openModalDeleteSito(modalDeleteSito,item)" class="ml-2 mr-2 delete-modal" title="Elimina indagine" container="body" placement="left" >
                                       <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                                     </a>
                                  </ng-template>
                                  <ng-template [ngIf]="item?.value.stato == 'sent'">
                                    <a routerLink="/siti/{{item.value.tipo | replaceEtichette }}/{{item.value.id}}/vista" class="ml-2 mr-2" title="" tooltip="Visualizza indagine" container="body" placement="left">
                                      <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                                    </a>
                                  </ng-template>
                                </td>
                            </tr>
                        </ng-container>

                        </tbody>
                    </table>


                    <div class="alert alert-danger no-result-filter" style="display: none;">
                        Nessuna richiesta trovata con i seguenti filtri
                    </div>

                </div>
            </div>
        </div>
    </div>
</section>


<!-- ############################# MODAL GENERA RICEVUTE ############################# -->
<ng-template #modalGeneraRicevutaAll>
  <form id="formGeneraRicevuta" #formGeneraRicevuta="ngForm" (ngSubmit)="onSubmitFormGeneraRicevuta(formGeneraRicevuta, bsModalRef.content.idGis, bsModalRef.content.tipo)">

  <div class="modal-header">
    <h4 class="modal-title pull-left">Genera ricevute per l'ID GIS: {{bsModalRef.content.gis}}</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      <ng-template [ngIf]="bsModalRef.content.blockGenera == true">
        <div class="alert alert-danger" role="alert">
          Attenzione, impossibile geneare ricevuta per siti senza indagini.
        </div>
      </ng-template>
      <ng-template [ngIf]="bsModalRef.content.blockGenera == false">
        <div class="alert alert-danger" role="alert">
          Attenzione: una volta generata la ricevuta TUTTE le indagini associate a questo GIS non saranno più modificabili.
        </div>

        <div class="form-check">
          <input class="form-check-input" type="checkbox" id="autorizzo" name="autorizzo"  #campoAutorizzo="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submittedGenera && campoAutorizzo.invalid)}" required>
          <label class="form-check-label" for="autorizzo">
            Dichiaro di essere autorizzato ad utilizzare le indagini
          </label>
        </div>

        <input type="hidden" name="idGis" #idGis="ngModel" [(ngModel)]="bsModalRef.content.idGis">

      </ng-template>

    </p>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">Annulla</button>
    <ng-template [ngIf]="bsModalRef.content.blockGenera == false">
      <button type="submit" class="btn btn-success">Invia</button>
    </ng-template>
  </div>
  </form>
</ng-template>


<!-- ############################# MODAL DELETE SITO ############################# -->
<ng-template #modalDeleteSito>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Elimina Sito indagine</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Sei sicuro di voler eliminare il sito indagine <strong>{{bsModalRef.content.denominazione}}</strong>? <br>
      Verranno eliminate anche tutte le sue indagini con parametri e stratigrafie collegate.<br>
      <strong>L'Azione è irreversibile.</strong>

    </p>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">Annulla</button>
    <button type="button" class="btn btn-danger" (click)="deleteSito(bsModalRef.content.id, bsModalRef.content.tipo)">Elimina</button>
  </div>
</ng-template>
