import {Component, OnInit, Input, Output, EventEmitter} from '@angular/core';
import {NgxFileDropEntry, FileSystemFileEntry, FileSystemDirectoryEntry} from 'ngx-file-drop';
import {GlobalConstants} from '../../../global-constants';
import {ToastrService} from 'ngx-toastr';
import {trigger, transition, style, animate} from "@angular/animations";
import {DataService} from '../../../service/data.service';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";

@Component({
  selector: 'app-allegati-siti',
  templateUrl: './allegati-siti.component.html',
  styleUrls: ['./allegati-siti.component.less'],
  animations: [
    trigger(
        'deleteAnimation', [
          transition(':leave', [
            style({backgroundColor: "#FF0000", opacity: 1}),
            animate('1500ms', style({opacity: 0}))
          ])
        ]
    )
  ]
})
export class AllegatiSitiComponent implements OnInit {

  @Input() idSito: number;
  @Input() tipoSito: string;
  @Input() arrayFileRelGeologica;
  @Input() arrayFileRelGeotecnica;
  @Input() arrayFileAltro;
  @Input() textButton;
  @Input() accept;
  @Input() type;

  @Output() unFileCaricatoGeologica = new EventEmitter<string>();
  @Output() unFileCaricatoGeotecnica = new EventEmitter<string>();
  @Output() riabilitaValidazioneGeologica = new EventEmitter<string>();
  @Output() riabilitaValidazioneGeotecnica = new EventEmitter<string>();

  constructor(private httpClient: HttpClient, private dataService: DataService, private http: HttpClient, private toastr: ToastrService) { }

  public fileRelGeologica: NgxFileDropEntry[] = [];
  public fileRelGeotecnica: NgxFileDropEntry[] = [];
  public fileAltro: NgxFileDropEntry[] = [];
  liveSite = GlobalConstants.liveSite;
  api = GlobalConstants.apiPath;

  ngOnInit(): void {

  }
  ngOnChanges(): void {
    console.log("this.arrayFileRelGeologica",this.arrayFileRelGeologica);
    console.log("this.arrayFileRelGeotecnica",this.arrayFileRelGeotecnica);
    console.log("this.arrayFileAltro",this.arrayFileAltro);

    if (this.arrayFileRelGeologica) { //aggiungiamo la nuova riga html del file
      for (let i = 0; i < this.arrayFileRelGeologica.length; i++) {
        this.fileRelGeologica.push({"relativePath": this.arrayFileRelGeologica[i].name, "fileEntry": this.arrayFileRelGeologica[i]});
      }
    }
    if (this.arrayFileRelGeotecnica) { //aggiungiamo la nuova riga html del file
      for (let i = 0; i < this.arrayFileRelGeotecnica.length; i++) {
        this.fileRelGeotecnica.push({"relativePath": this.arrayFileRelGeotecnica[i].name, "fileEntry": this.arrayFileRelGeotecnica[i]});
      }
    }
    if (this.arrayFileAltro) { //aggiungiamo la nuova riga html del file
      for (let i = 0; i < this.arrayFileAltro.length; i++) {
        this.fileAltro.push({"relativePath": this.arrayFileAltro[i].name, "fileEntry": this.arrayFileAltro[i]});
      }
    }

  }



  openFileSelector () {
  }

  public dropped(files: NgxFileDropEntry[]) {
    var apiUpload = GlobalConstants.API_UPLOADS.replace("{apientity}", this.tipoSito);
    apiUpload = apiUpload.replace("{id}", this.idSito.toString());
    apiUpload = apiUpload.replace("{type}", this.type);
    console.log(apiUpload);

    for (const droppedFile of files) {
      // Is it a file?
      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        fileEntry.file((file: File) => {

          const formData = new FormData();
          formData.append('file', file, file.name);

          // Headers
          const headers = new HttpHeaders({
            //'security-token': 'mytoken'
          })

          switch (this.type) {
            case "relazioneGeologica":
            case "relazioneGeotecnica":
            case "altro":
              //controllo del formato del file
              if (file.type != "application/pdf") {
                this.toastr.error(GlobalConstants.ERRORE_FORMATO_UPLOAD_TESTO.replace("{tipoFile}", file.type), GlobalConstants.ERRORE_FORMATO_UPLOAD_TITOLO);
                return;
              }

              this.http.post(apiUpload, formData)
                  .subscribe(data => {
                    console.log("Risposta apiUpload", data);

                    droppedFile.fileEntry['id'] = data; //aggiungiamo l'id del file appena creato
                    if (this.type == "relazioneGeologica") {
                      this.fileRelGeologica = (this.fileRelGeologica.concat(droppedFile));
                      this.unFileCaricatoGeologica.emit('geologica');//serve per togliere la validazione al file
                    }
                    if (this.type == "relazioneGeotecnica") {
                      this.fileRelGeotecnica = (this.fileRelGeotecnica.concat(droppedFile));
                      this.unFileCaricatoGeotecnica.emit('geotecnica');//serve per togliere la validazione al file

                    }
                    if (this.type == "altro") {
                      this.fileAltro = (this.fileAltro.concat(droppedFile));
                    }
                  })
              break;
          }

        });
      } else {
        // It was a directory (empty directories are added, otherwise only files)
        const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        console.log(droppedFile.relativePath, fileEntry);
      }


    }
  }

  public onDeleteFile(id) {
    //chiamo l'api della delete passando id
    this.dataService.deleteFile(id).subscribe((risposta: any[]) => {
      console.log("Risultato deleteFile api", risposta);
      var nomeFile = "";

      //eliminiamo la riga nel template (fileRelGeologica)
      for (let i = 0; i < (this.fileRelGeologica).length; i++) {
        var temp = this.fileRelGeologica[i].fileEntry;
        //usiamo questa procedura diversa perchè abbiamo aggiunto un id all'array di tipo NgxFileDropEntry
        //noinspection TypeScriptUnresolvedFunction
        for (const [key, value] of Object.entries(temp)) {
          if (`${key}` == 'id' && `${value}` == id) {
            console.log(`${key} ${value}`);
            nomeFile = this.fileRelGeologica[i].relativePath;
            this.fileRelGeologica.splice(i, 1);
            break;
          }
        }
        if ((this.fileRelGeologica).length == 0) {
          this.riabilitaValidazioneGeologica.emit('geologica');//serve per rimettere la validazione al file
        }
      }


      //eliminiamo la riga nel template (fileRelGeotecnica)
      for (let i = 0; i < (this.fileRelGeotecnica).length; i++) {
        var temp = this.fileRelGeotecnica[i].fileEntry;
        //usiamo questa procedura diversa perchè abbiamo aggiunto un id all'array di tipo NgxFileDropEntry
        //noinspection TypeScriptUnresolvedFunction
        for (const [key, value] of Object.entries(temp)) {
          if (`${key}` == 'id' && `${value}` == id) {
            console.log(`${key} ${value}`);
            nomeFile = this.fileRelGeotecnica[i].relativePath;
            this.fileRelGeotecnica.splice(i, 1);
            break;
          }
        }
        if ((this.fileRelGeotecnica).length == 0) {
          this.riabilitaValidazioneGeotecnica.emit('geotecnica');//serve per rimettere la validazione al file
        }
      }

      //eliminiamo la riga nel template (fileRelGeotecnica)
      for (let i = 0; i < (this.fileAltro).length; i++) {
        var temp = this.fileAltro[i].fileEntry;
        //usiamo questa procedura diversa perchè abbiamo aggiunto un id all'array di tipo NgxFileDropEntry
        //noinspection TypeScriptUnresolvedFunction
        for (const [key, value] of Object.entries(temp)) {
          if (`${key}` == 'id' && `${value}` == id) {
            console.log(`${key} ${value}`);
            nomeFile = this.fileAltro[i].relativePath;
            this.fileAltro.splice(i, 1);
            break;
          }
        }
      }

      this.toastr.success(GlobalConstants.DELETE_FILES_TESTO.replace("{nomeFile}", nomeFile), GlobalConstants.DELETE_FILES_TITOLO);
    })
  }


  leggiFileById(fileId) {
    //https://stackoverflow.com/questions/51682514/angular-how-to-download-a-file-from-httpclient
    this.httpClient.get(GlobalConstants.API_READ_FILE + fileId,{
      responseType: 'arraybuffer',headers:{'Content-Type': 'application/json'}}
    ).subscribe(response => {
      console.log("risposta",response);
      this.downLoadFile(response, "application/pdf")
    });
  }
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
    }
  }





  public fileOver(event) {
    //console.log(event);
  }

  public fileLeave(event) {
    //console.log(event);
  }


}
