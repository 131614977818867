import { Component, OnInit } from '@angular/core';
import {DataService} from "../service/data.service";
import {LocalStorageService} from "angular-web-storage";
import {Router} from "@angular/router";
import jwt_decode from "jwt-decode";
import {GlobalConstants} from "../global-constants";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-recupera-password',
  templateUrl: './recupera-password.component.html',
  styleUrls: ['./recupera-password.component.less']
})
export class RecuperaPasswordComponent implements OnInit {

  constructor(private dataService: DataService, private local: LocalStorageService, private router: Router, private toastr: ToastrService) { }

  recuperaPassword: string = "";
  submitted = false;

  ngOnInit(): void {
  }

  onSubmit(form) { //al submit
    this.submitted = true; // abbiamo fatto un submit, serve per la validazione
    if (form.invalid) { // stop here if form is invalid
      return;
    }

    var valori = form.form.value; //prendo i valori inseriti nella form
    console.log("valori form", valori);

    this.dataService.recuperaPassword(valori).subscribe((risposta: any) => {
      console.log("response", risposta);
      if (risposta.stato === 'non esiste') {
        this.toastr.error( GlobalConstants.ERRORE_RECUPERA_PWD_TESTO.replace("{username}",risposta.username), GlobalConstants.ERRORE_RECUPERA_PWD_TITOLO);
      } else if (risposta.stato === 'ok') {
        this.toastr.success( GlobalConstants.SUCCESS_RECUPERA_PWD_TESTO.replace("{username}",risposta.username), GlobalConstants.SUCCESS_RECUPERA_PWD_TITOLO);
      }

    })

  }
}
