import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {GetFromObjectPipe} from '../../shared/pipe/get-from-object.pipe';
import { ReplaceEtichettePipe } from '../../shared/pipe/replace-etichette.pipe';
import { CountVisibleFilterPipe } from '../../shared/pipe/countVisibleFilter.pipe';
import { FiltraListaPipe } from '../../shared/pipe/filtraLista.pipe';
import {FiltraMostraPipe} from "../pipe/filtraMostra.pipe";


@NgModule({
  declarations: [GetFromObjectPipe, ReplaceEtichettePipe, CountVisibleFilterPipe, FiltraListaPipe, FiltraMostraPipe],
  exports: [GetFromObjectPipe, ReplaceEtichettePipe, CountVisibleFilterPipe, FiltraListaPipe, FiltraMostraPipe],
  imports: [
    CommonModule
  ]
})
export class SharedModuleModule { }
