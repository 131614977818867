import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'filtraLista',
  pure: false
})
export class FiltraListaPipe implements PipeTransform {

  transform(item: any, filtroCerca: any, filtroComune: any): any {

    let arrayList = [];
    for (let i=0; i< item.length; i++) {
      if ( (filtroCerca !== '' && filtroCerca !== null) &&
        (
          (
            (!item[i].titolo.toLowerCase().includes(filtroCerca.toLowerCase())) &&
            ((!item[i].username.toLowerCase().includes(filtroCerca.toLowerCase())) && filtroCerca.length >= 3) &&
            (!item[i].indirizzo.toLowerCase().includes(filtroCerca.toLowerCase()))
          )
        ) ||
        (filtroComune !== null && item[i].comune !== filtroComune)
      ) {
        //return true;
      } else {
        arrayList.push(item[i]);
      }

    }


    return arrayList;



  }
}
