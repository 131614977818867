<section id="head" class="mt-4 mb-4">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 col-md">
                <h1>{{ sito?.denominazione }} </h1>
            </div>

            <div class="col-12 col-md">
                <div class="alert alert-success d-inline">Codice GIS sito <strong>{{ sito?.gis }}</strong> <sup data-toggle="tooltip" data-placement="top" title="Tooltip on top"><i class="fas fa-info-circle"></i></sup></div>
            </div>



            <div class="col col-md-3 text-md-right text-left">
                <a [routerLink]="['/comuni', sito?.comune.id]" >
                    <i class="fas fa-caret-right"></i>
                    Comune di {{ sito?.comune.comune }}
                </a>
            </div>

        </div>
    </div>
</section>

<section id="denominazione" class="request">
    <!--div class="container-fluid">
        <div class="card">
            <div class="card-header">
                Dati generici indagine | <i>* obbligatori</i>
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md">
                        <label for="denominazione">Denominazione intervento/progetto</label>
                        <h1>{{ sito?.denominazione }} </h1>
                    </div>
                    <div class="col-12 col-md">
                        <label>Commitente *</label>
                        <h1>{{ sito?.committente }} </h1>
                    </div>
                    <div class="col-12 col-md">
                        <label>Data indagine *</label>
                        <h1>{{ sito?.dataSito | date: 'dd/MM/yyyy' }} </h1>
                    </div>
                </div>
            </div>
        </div>
    </div-->
</section>

<section id="tipologia" class="mt-4">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                Tipologia
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-12 col-md-3">
                        <label>Tipologia intervento</label>
                        <h5>{{ sito?.edilizia }}</h5>
                    </div>
                    <div class="col-12 col-md">
                        <label>ID genio civile</label>
                        <h5>{{ sito?.genioCivile }}</h5>
                    </div>
                    <div class="col-12 col-md">
                        <label>CUP</label>
                        <h5>{{ sito?.cup }}</h5>
                    </div>
                    <div class="col-12 col-md">
                        <label>Ambito indagine</label>
                        <h5>{{ sito?.sisma }}</h5>
                    </div>
                    <div class="col-12 col-md prot">
                        <label>Protocollo sisma / ID Aggregato</label>
                        <h5>{{ sito?.protocollo }} / {{ sito?.protocollo }}</h5>
                    </div>

                </div>
                <div class="row">
                    <div class="col-12 col-md-3 mt-4">
                        <label>Finanziamento</label>
                        {{ sito?.finanziamento }}
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>


<section id="localizzazione" class="mt-3">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                Localizzazione intervento
            </div>
            <div class="card-body">
                <div class="row">
                    <div class="col-md">
                        <div class="box">
                            <div class="row">
                                <div class="col-md">
                                    <!-- SONO IN UN SITO LINEARE -->
                                    <ng-template [ngIf]="sito?.tipo == 'lineari'">

                                        <div class="row mt-3">
                                            <div class="col-md">
                                                <label>Modo identificazione Coordinate</label>
                                                <h5>{{ sito?.modIdentcoord }}</h5>
                                            </div>
                                            <div class="col-md">
                                                <label>Coordinate Ax</label><br>
                                                <h5>{{ sito?.AcoordX }}</h5>
                                            </div>
                                            <div class="col-md">
                                                <label>Coordinate Ay</label><br>
                                                <h5>{{ sito?.AcoordY }}</h5>
                                            </div>
                                            <div class="col-md">
                                                <label>Coordinate Bx</label><br>
                                                <h5>{{ sito?.BcoordX }}</h5>
                                            </div>
                                            <div class="col-md">
                                                <label>Coordinate By</label><br>
                                                <h5>{{ sito?.BcoordY }}</h5>
                                            </div>
                                            <div class="col-md border-left">
                                                <label>Quota A</label><br>
                                                <h5>{{ sito?.aquota }}</h5>
                                            </div>
                                            <div class="col-md">
                                                <label>Quota B</label><br>
                                                <h5>{{ sito?.bquota }}</h5>
                                            </div>
                                        </div>
                                    </ng-template>
                                    <ng-template [ngIf]="sito?.tipo == 'puntuali'">
                                        <div class="row mt-3">
                                            <div class="col-md">
                                                <label>Modo identificazione Coordinate</label>
                                                <h5>{{ sito?.modIdentcoord }}</h5>
                                            </div>
                                            <div class="col-md">
                                                <label>Coordinate X</label><br>
                                                <h5>{{ sito?.bquota }}</h5>
                                            </div>
                                            <div class="col-md">
                                                <label>Coordinate Y</label><br>
                                                <h5>{{ sito?.bquota }}</h5>
                                            </div>
                                            <div class="col-md border-left">
                                                <label>Modo identificazione quota</label>
                                                <h5>{{ sito?.bquota }}</h5>
                                            </div>
                                            <div class="col-md">
                                                <label>Quota SLM</label><br>
                                                <h5>{{ sito?.quotaSlm }}</h5>
                                            </div>
                                        </div>
                                    </ng-template>

                                </div>
                                <div class="col-md-12">
                                    <small id="emailHelp" class="form-text text-muted font-weight-bold">Formato coordinate WGS84 - Decimale</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr>
            <div class="card-body gllpLatlonPicker">
                <div class="row">
                    <div class="col-md">
                        <h4 class="mb-3">Visualizzazione su mappa</h4>
                    </div>
                </div>
                <div class="row">
                    <div class="col-md-6">
                        <div class="box ">
                            <div class="row">

                                <div class="col-md-5">
                                    <label>Comune</label>
                                    <h5>{{ sito?.comune.comune }}</h5>
                                </div>

                                <div class="col-md-2">
                                    <label>Comune</label>
                                    <h5>{{ sito?.cap }}</h5>
                                </div>

                                <div class="col-md">
                                    <label>Frazione</label>
                                    <h5>{{ sito?.frazione }}</h5>
                                </div>

                            </div>
                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <label>Indirizzo</label>
                                    <h5>{{ sito?.indirizzo }}</h5>
                                </div>
                            </div>

                            <hr>

                            <div class="row mt-3">
                                <div class="col-md-12">
                                    <input type="hidden" class="gllpZoom" value="16">

                                </div>
                            </div>

                        </div>
                    </div>
                    <div class="col-md-6">

                        <div style="height: 300px;"
                             leaflet
                             [leafletOptions]="optionMap"
                             [leafletLayers]="arrayMarker"
                             [leafletFitBounds]="markerBounds"
                             (leafletMapReady)="onMapReady($event)">
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</section>

<hr>


<section class="mt-4 mb-4">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 col-md">
                <h4>Indagini del sito</h4>
            </div>
        </div>

    </div>
</section>


<section id="list" class="request">
    <div class="container-fluid">
        <div class="row">
            <div class="col">
                <div class="table-responsive-sm">
                    <table class="table table-bordered text-left">
                        <thead>
                        <tr>
                            <th scope="col" class="text-center" style="width:3%">
                                #ID <a href="" class="sortById desc"><i class="fas fa-caret-down"></i></a>
                            </th>
                            <th scope="col">
                                Classe indagine
                            </th>
                            <th scope="col">
                                Tipologia indagine
                            </th>
                            <th scope="col">
                                Numero parametri
                            </th>
                            <th scope="col">
                                Data creazione
                            </th>

                            <th scope="col" class="text-center">
                                Azioni
                            </th>
                        </tr>
                        </thead>
                        <tbody>



                        <tr *ngFor="let item of indagini">
                            <td scope="row" class="text-center row-id">
                                {{ item.id }}
                            </td>
                            <td class="title">
                                {{ item.classeInd }} - {{ item.classeIndTitle }} <!--{{ classe[item.classeInd].desClasseIndagine }}-->
                            </td>
                            <td>
                                {{ item.tipoInd }} - {{ item.tipoIndTitle }}<!--{{ tipo[item.tipoInd].desTipoIndagine }}-->
                            </td>
                            <td>
                                {{ item.numParametri }}
                            </td>
                            <td>
                                <strong>{{ item.dataInd }}</strong>
                            </td>
                            <td class="actions text-center">
                                <a routerLink="/siti/{{sito?.tipo}}/{{sito?.id}}/indagini/{{item.id}}/vista" class="ml-2 mr-2" title="Visualizza parametri / modifica indagine">
                                <i class="fas fa-eye fa-lg"></i>visualizza
                                </a>
                            </td>
                        </tr>





                        </tbody>
                    </table>



                </div>
            </div>
        </div>
    </div>
</section>
