import { Injectable } from '@angular/core';
import {ActivatedRouteSnapshot, Resolve, RouterStateSnapshot} from "@angular/router";
import {Observable} from "rxjs";
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {GlobalConstants} from '../../global-constants';
import {throwError} from 'rxjs';
import {catchError, map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ComuniResolverService {

  constructor(private httpClient: HttpClient) { }


  public handleError(error: HttpErrorResponse) {
    console.log("error", error);

    let errorMessage = 'Unknown error!';
    if (error.error instanceof ErrorEvent) {
      // Client-side errors
      errorMessage = `Error: ${error.error.message}`;
    } else {
      switch (error.status) {
        case 401:
          errorMessage = `Errore: ${error.status} ${error.statusText}\nMessage: ${error.error.message}`;
          //svuotiamo anche il jwt dal localStorage????? (si)...
          break
        default:
          // Server-side errors
          window.alert(errorMessage); //da togliere in futuro
          errorMessage = `Error Code: ${error.status}\nMessage: ${error.message}`;
      }
    }

    window.alert(errorMessage); //da togliere in futuro
    return throwError(errorMessage);
  }


  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    // In my case i am using custom service for getting rest calls but you can use simple http.get()...
    return this.httpClient.get(GlobalConstants.API_COMUNI_BIG).pipe(
        catchError((error: HttpErrorResponse) => this.handleError(error))
    );

  }

}