<div class="container h-75">

  <div class="row justify-content-center h-100 login-form">
    <div class="col col-sm col-md-6 my-auto">
      <div class="card">
        <div class="card-header bg-white">
          <img src="assets/header-ricevuta.png" class="d-inline-block align-top w-100" alt="">
        </div>
        <div class="card-body">
          <h5 class="cart-title">
            Recupera Password @Geologi<br>
            <small>Inserisci la tua mail di registrazione per recuperare la password</small>
          </h5>

          <hr>

          <form id="formRecuperaPassword" class="form" #formRecuperaPassword="ngForm" (ngSubmit)="onSubmit(formRecuperaPassword)">

            <div class="row">
              <div class="form-group col">
                <div class="input-group mb-2">
                  <div class="input-group-prepend">
                    <div class="input-group-text"><fa-icon [icon]="['fas', 'at']"></fa-icon></div>
                  </div>
                  <input type="text" class="form-control" name="recuperaPassword" #username="ngModel" [(ngModel)]="recuperaPassword" [ngClass]="{ 'is-invalid': (submitted && username.invalid )}" required placeholder="Enter username">
                </div>
              </div>
            </div>

            <div class="row align-items-center">
              <div class="col-md-3">
                <button id="btn-login" class="btn btn-success">Recupera</button>
              </div>
              <div class="col-md-9 text-right">
                <a routerLink="/registration" class="pt-1"><strong>Registrati</strong></a> |
                <a routerLink="/login" class="pt-1">Effettua il login</a> |
                <!--a href="#" class="pt-1"><i class="fal fa-info-square"></i> Manuale</a-->
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>

  </div>
</div>
