<section id="head" class="mt-4 mb-4">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md">
        <h1>{{ intervento?.titolo }} </h1>
      </div>

    </div>
  </div>
</section>



<section id="tipologia" class="mt-4">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        DATI INTERVENTO
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md">
            <label>Titolo</label>
            <h5>{{ intervento?.titolo }}</h5>
          </div>
          <div class="col-12 col-md-3">
            <label>Codice</label>
            <h5>{{ intervento?.codiceIntervento }}</h5>
          </div>
          <div class="col-12 col-md">
            <label>Data intervento</label>
            <h5>{{ intervento?.data_inserimento | date: 'dd/MM/yyyy' }}</h5>
          </div>
          <div class="col-12 col-md">
            <label>Committente</label>
            <h5>{{ intervento?.committente }}</h5>
          </div>

        </div>
      </div>
    </div>
  </div>
</section>

<section id="localizzazione" class="mt-4">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        LOCALIZZAZIONE
      </div>
      <div class="card-body">
        <div class="row">
          <div class="col-md-6">

            <div class="row mb-4">
              <div class="col-12 col-md">
                <label>Ubicazione intervento</label>
                <h5>{{ intervento?.ubicazione_intervento }}</h5>
              </div>
              <div class="col-12 col-md">
                <label>Comune</label>
                <h5>{{ intervento?.comune }}</h5>
              </div>
              <div class="col-12 col-md">
                <label>Provincia</label>
                <h5>{{ intervento?.provincia }}</h5>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 col-md">
                <label>Cap</label>
                <h5>{{ intervento?.cap }}</h5>
              </div>
              <div class="col-12 col-md">
                <label>Indirizzo</label>
                <h5>{{ intervento?.indirizzo }}</h5>
              </div>
              <div class="col-12 col-md">
                <label>Frazione</label>
                <h5>{{ intervento?.frazione }}</h5>
              </div>
            </div>

            <div class="row mb-4">
              <div class="col-12 col-md">
                <label>Coordinate intervento</label>
                <h5>Latitudine: {{ intervento?.lat }}  Longitudine: {{ intervento?.lng }}</h5>
              </div>
            </div>

          </div>
          <div class="col-md-6">

            <div style="height: 300px;"
                 leaflet
                 [leafletOptions]="optionMap"
                 [leafletLayers]="arrayMarker"
                 [leafletFitBounds]="markerBounds"
                 (leafletMapReady)="onMapReady($event)">
            </div>

          </div>


        </div>
      </div>
    </div>
  </div>
</section>

<section id="relazioni" class="mt-4">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        RELAZIONI DISPONIBILI
      </div>
      <div class="card-body">
        <div class="row">
            <div class="col-12 col-md-3">
              <label>Relazione Geologica e modellazione sismica</label>
              <h5><a href="{{ liveSite }}/../uploads/storico/{{ intervento?.rel_geologica_mod_sismica }}" target="_blank">{{ intervento?.rel_geologica_mod_sismica }}</a></h5>
            </div>
            <div class="col-12 col-md-3">
              <label>Relazione Geotecnica sulle indagini</label>
              <h5><a href="{{ liveSite }}/../uploads/storico/{{ intervento?.rel_geotecnica_indagini }}" target="_blank">{{ intervento?.rel_geotecnica_indagini }}</a></h5>
            </div>
            <div class="col-12 col-md-3">
              <label>Relazione Geotecnica sulle fondazioni</label>
              <h5><a href="{{ liveSite }}/../uploads/storico/{{ intervento?.rel_geotecnica_fondazioni }}" target="_blank">{{ intervento?.rel_geotecnica_fondazioni }}</a></h5>
            </div>
            <div class="col-12 col-md-3">
              <label>Altro</label>
              <h5><a href="{{ liveSite }}/../uploads/storico/{{ intervento?.rel_altro }}" target="_blank">{{ intervento?.rel_altro }}</a></h5>
            </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="geognostiche" class="mt-4">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        INDAGINI GEOGNOSTICHE E GEOTECNICHE ESEGUITE
      </div>
      <div class="card-body">

        <div class="row mb-4">
            <div class="col-12 col-md-3">
              <label>N. sondaggi a carotaggio continuo</label>
              <h5>{{ intervento?.sondaggi_carotaggio_continuo }}</h5>
            </div>
            <div class="col-12 col-md-3">
              <label>N. prove penetrometriche statiche (CPT - CPTY)</label>
              <h5>{{ intervento?.prove_pntm_statiche }}</h5>
            </div>
            <div class="col-12 col-md-3">
              <label>N. prove penetrometriche dinamiche DPSH</label>
              <h5>{{ intervento?.prove_pntm_dinamiche_dpsh }}</h5>
            </div>
            <div class="col-12 col-md-3">
              <label>N. prove penetrometriche dinamiche Medio-Leggere</label>
              <h5>{{ intervento?.prove_pntm_dinamiche_medio_leggere }}</h5>
            </div>
        </div>

        <div class="row mb-4">
            <div class="col-12 col-md-3">
              <label>Prove dilatometriche</label>
              <h5>{{ intervento?.prove_dilatometriche }}</h5>
            </div>
            <div class="col-12 col-md-3">
              <label>N. campioni prove geotecniche di laboratorio</label>
              <h5>{{ intervento?.prove_geotecniche_laboratorio }}</h5>
            </div>
            <div class="col-12 col-md-3">
              <label>Altro</label>
              <h5>{{ intervento?.geognostiche_geotecniche_altro }}</h5>
            </div>
        </div>

      </div>
    </div>
  </div>
</section>

<section id="geofisiche" class="mt-4">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        INDAGINI GEOFISICHE ESEGUITE
      </div>
      <div class="card-body">
        <div class="row mb-4">
            <div class="col-12 col-md-3">
              <label>N. prospezioni sismiche a rifrazione (in onde P)</label>
              <h5>{{ intervento?.prosp_sismiche_rifrazione_p }}</h5>
            </div>
            <div class="col-12 col-md-3">
              <label>N. prospezioni sismiche a rifrazione (in onde S)</label>
              <h5>{{ intervento?.prosp_sismiche_rifrazione_s }}</h5>
            </div>
            <div class="col-12 col-md-3">
              <label>N. prospezioni sismiche a riflessione</label>
              <h5>{{ intervento?.prosp_sismiche_riflessione }}</h5>
            </div>
            <div class="col-12 col-md-3">
              <label>N. prospezioni sismiche in Down-Hole</label>
              <h5>{{ intervento?.prosp_sismiche_down_hole }}</h5>
            </div>
        </div>

        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <label>N. prospezioni sismiche in Cross-Hole</label>
            <h5>{{ intervento?.prosp_sismiche_cross_hole }}</h5>
          </div>
          <div class="col-12 col-md-3">
            <label>N. prospezioni geoelettriche</label>
            <h5>{{ intervento?.prosp_geoelettriche }}</h5>
          </div>
          <div class="col-12 col-md-3">
            <label>N. MASW-SASW)</label>
            <h5>{{ intervento?.masw_sasw }}</h5>
          </div>
          <div class="col-12 col-md-3">
            <label>N. misure sismico ambientale</label>
            <h5>{{ intervento?.rumore_sismico_ambientale }}</h5>
          </div>
        </div>

        <div class="row mb-4">
          <div class="col-12 col-md-3">
            <label>N. dilatometro sismico</label>
            <h5>{{ intervento?.dilatometro_sismico }}</h5>
          </div>
          <div class="col-12 col-md-3">
            <label>Altro</label>
            <h5>{{ intervento?.geofisiche_altro }}</h5>
          </div>
        </div>

      </div>
    </div>
  </div>
</section>


<section id="sondaggi" class="mt-4">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">
        SONDAGGI
      </div>
      <div class="card-body m-0 p-0">
        <div class="table-responsive-sm">



          <div *ngIf="intervento?.sondaggi.length > 0; then thenBlock else elseBlock"></div>
          <ng-template #thenBlock>
            <table class="table table-bordered text-left m-0 p-0">
              <thead>
              <tr style="background: #000000; color: #FFFFFF">
                <th scope="col" class="text-center" style="width:3%">
                  #ID <a href="" class="sortById desc"><i class="fas fa-caret-down"></i></a>
                </th>
                <th scope="col">Codice</th>
                <th scope="col">Numero</th>
                <th scope="col">Profondità</th>
                <th scope="col">Quota</th>
                <th scope="col">File</th>
              </tr>
              </thead>
              <tbody>

              <tr *ngFor="let item of intervento?.sondaggi">
                <td scope="row" class="text-center row-id">
                  {{ item.id }}
                </td>
                <td class="title">{{ item.codice }}</td>
                <td class="title">{{ item.numero_sondaggio }}</td>
                <td class="title">{{ item.profondita }}</td>
                <td class="title">{{ item.quota }}</td>
                <td class="title"><a href="{{ liveSite}}/../uploads/storico/{{ item.file }}" target="_blank">{{ item.file }}</a></td>
              </tr>

              </tbody>
            </table>
          </ng-template>

          <ng-template #elseBlock>
            <h5 class="p-3">Nessun sondaggio</h5>
          </ng-template>


        </div>
      </div>
    </div>
  </div>
</section>



