import {Component, OnInit, TemplateRef} from '@angular/core';
import {GlobalConstants} from '../../global-constants';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute, Router} from '@angular/router';
import {DataService} from '../../service/data.service';
import {LocalStorageService, SessionStorageService, LocalStorage, SessionStorage} from 'angular-web-storage';
import {DatePipe} from '@angular/common';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import jwt_decode from "jwt-decode";
import {animate, style, transition, trigger} from "@angular/animations";

@Component({
    selector: 'app-lista-siti',
    templateUrl: './lista-siti.component.html',
    styleUrls: ['./lista-siti.component.less'],
  animations: [
    trigger(
      'deleteAnimation', [
        transition(':leave', [
          style({backgroundColor:"#FF0000", opacity: 1}),
          animate('1500ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})

export class ListaSitiComponent implements OnInit {

    constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private dataService: DataService, private local: LocalStorageService, public datepipe: DatePipe, private modalService: BsModalService) {
    }

    sitiList = [];
    arrayComuni = [];
    arrayComuniIdTemp = [];
    classeIndagine = [];
    tipoIndagine = [];
    filtroCerca = "";
    filtroTipo = "";
    filtroSisma = "";
    filtroEdilizia = "";
    filtroComune = "";
    filtroClasseIndagine = "";
    filtroTipoIndagine = "";
    filtroDataDaA = "";
    filtroDataDa = "";
    filtroDataA = "";
    filtroStato = "";
    des_classe_indagine;
    des_tipo_indagine;

    bsModalRef: BsModalRef; //oggetto della modal
    submittedGenera = false;

    noAnimazione = 0; //serve per non far eseguire l'animazione in caso di modifica (solo in delete)

    logged = false;
    decodedJwt = null;

    ngOnInit(): void {
        this.des_classe_indagine = this.local.get("des_classe_indagine");
        this.des_tipo_indagine = this.local.get("des_tipo_indagine");

        //############################################ des_classe_indagine -----> CONTROLLO API IN LOCALSTORAGE
        if (this.des_classe_indagine == null) {
            console.log("api NON in localStorage, fare la chiamata");
            this.httpClient.get(GlobalConstants.API_DES_CLASSE_INDAGINE).subscribe((risposta: any) => {
                //console.log(risposta);
                this.classeIndagine = risposta;
                this.local.set("des_classe_indagine", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
            });
        } else {
            console.log("api presa da localStorage");
            this.classeIndagine = this.local.get("des_classe_indagine"); //prendo l'api dal localStorage
        }

        //############################################ des_tipo_indagine -----> CONTROLLO API IN LOCALSTORAGE
        if (this.des_tipo_indagine == null) {
            console.log("api NON in localStorage, fare la chiamata");
            this.httpClient.get(GlobalConstants.API_DES_TIPO_INDAGINE).subscribe((risposta: any) => {
                //console.log(risposta);
                this.tipoIndagine = risposta;
                this.local.set("des_tipo_indagine", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
            });
        } else {
            console.log("api presa da localStorage");
            this.tipoIndagine = this.local.get("des_tipo_indagine"); //prendo l'api dal localStorage
        }


      if (this.dataService.isAuthenticated()) {
        this.logged = true;
        if (this.decodedJwt != null) {
          this.decodedJwt = jwt_decode(this.decodedJwt);
        } else {
          var tok = localStorage.getItem('jwt');
          this.decodedJwt = jwt_decode(tok);// decodifica il token jwt per prendere le sue info
        }

        console.log("decodedJwt", this.decodedJwt.userId);
      }




      this.dataService.getListaSiti(this.decodedJwt.userId).subscribe((risposta: any) => {
            console.log("risposta", Object.values(risposta));
            this.sitiList = Object.values(risposta);


            //ricavo i soli comuni di tutti i siti (per la select)
            for (let i = 0; i < this.sitiList.length; i++) {
                for (let z = 0; z < this.sitiList[i].length; z++) {
                    if (this.arrayComuniIdTemp.indexOf(this.sitiList[i][z].comune.id) === -1) {
                        this.arrayComuni.push(this.sitiList[i][z].comune);
                        this.arrayComuniIdTemp.push(this.sitiList[i][z].comune.id);
                    }
                    this.sitiList[i][z].countVisible = this.sitiList[i].length;
                }
            }
            console.log("comuni dei siti", this.arrayComuni);
            console.log("this.sitiList", this.sitiList);
        });

    }


    aggiornaSitiByFiltri(search, tipo, edilizia, sisma, comune, classeIndagini, tipoIndagini, dataDa, dataA, stato) {
      let primoVisibile = 0;
      let countVisible = 0;
      for (let i = 0; i < this.sitiList.length; i++) {
            primoVisibile = 0;
            countVisible = 0;
            for (let z = 0; z < this.sitiList[i].length; z++) {
                if ((search == "" ||
                    (this.sitiList[i][z].denominazione.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (this.sitiList[i][z].cup != null && this.sitiList[i][z].cup != "" && this.sitiList[i][z].cup.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
                    (this.sitiList[i][z].protocollo != null && this.sitiList[i][z].protocollo != "" && this.sitiList[i][z].protocollo.toLowerCase().indexOf(search.toLowerCase()) !== -1)) &&
                    (tipo == "" || tipo == this.sitiList[i][z].tipo) &&
                    (edilizia == "" || edilizia == this.sitiList[i][z].edilizia) &&
                    (sisma == "" || sisma == this.sitiList[i][z].sisma) &&
                    (comune == "" || comune == this.sitiList[i][z].comune.id) &&
                    (classeIndagini == "" || this.sitiList[i][z].classeIndagini.indexOf(classeIndagini) !== -1) &&
                    (tipoIndagini == "" || this.sitiList[i][z].tipoIndagini.indexOf(tipoIndagini) !== -1) &&
                    (stato == "" || stato == this.sitiList[i][z].stato) &&
                    (dataDa == "" || dataDa == null || (this.sitiList[i][z].dataSito >= this.datepipe.transform(dataDa, 'yyyy-MM-dd'))) &&
                    (dataA == "" || dataA == null || (this.sitiList[i][z].dataSito <= this.datepipe.transform(dataA, 'yyyy-MM-dd')))
                ) {
                    this.sitiList[i][z].mostra = 1;
                    if (primoVisibile == 0) {
                      this.sitiList[i][z].primoVisibile = 1;
                      primoVisibile = 1;
                    } else {
                      this.sitiList[i][z].primoVisibile = 0;
                    }
                    countVisible++;
                } else {
                    this.sitiList[i][z].mostra = 0;
                    this.sitiList[i][z].primoVisibile = 0;
                }
            }


            for (let z = 0; z < this.sitiList[i].length; z++) {
              this.sitiList[i][z].countVisible = countVisible;
            }

        }

    }


    onChangeFiltro(filtro) {
      if (filtro != null) {
        console.log('filtro', filtro.value);
        this.aggiornaSitiByFiltri(this.filtroCerca, this.filtroTipo, this.filtroEdilizia, this.filtroSisma, this.filtroComune, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA, this.filtroStato);
      }
    }

    openModalGeneraRicevuteGis(modalRicevute: TemplateRef<any>, itemGis) {
      this.bsModalRef = this.modalService.show(modalRicevute, {class: 'modal-lg'}); //apre la modal
      var blockGenera = false;
      console.log(itemGis);
      for (let i = 0; i < itemGis.value.length; i++) {
        if ( itemGis.value[i].numIndagini == 0) {
          blockGenera = true;
        }
      }

        //riempie la modal con i dati passati
      this.bsModalRef.content = {"idGis": itemGis.value[0].idGis, "blockGenera" : blockGenera, "tipo":itemGis.value[0].tipo, "gis":itemGis.value[0].gis}
    }

  onSubmitFormGeneraRicevuta(formGeneraRicevuta, idGis, tipo) { //quando si fà submit della form  (nella modal)
    this.submittedGenera = true; // abbiamo fatto un submit, serve per la validazione
    console.log(formGeneraRicevuta.invalid);
    console.log(formGeneraRicevuta);
    console.log(idGis);
    console.log(tipo);
    if (formGeneraRicevuta.invalid) { // stop here if formContatti is invalid
      return;
    }

    console.log("OK GENERA");


    //https://stackoverflow.com/questions/51682514/angular-how-to-download-a-file-from-httpclient
     this.httpClient.post<any>(GlobalConstants.API_INVIA_SITI + tipo, idGis).subscribe((res: any) => {
      console.log("ricevuta generata",res.idGis);


      for (let i = 0; i < this.sitiList.length; i++) {
        for (let z = 0; z < this.sitiList[i].length; z++) {
          if (this.sitiList[i][z].idGis == res.idGis) {
            this.sitiList[i][z].stato = "sent";
            this.sitiList[i][z].certificato = res.certificato;
            this.sitiList[i][z].dataCertificato = res.dataCertificato;
          }
        }
      }
      this.bsModalRef.hide(); //chiudo la modal dei contatti
      return;


      //this.downLoadFile(response, "application/pdf")
    });
  }



  leggiFileById(fileId, idGis) {
    //https://stackoverflow.com/questions/51682514/angular-how-to-download-a-file-from-httpclient
    this.httpClient.get(GlobalConstants.API_READ_FILE + fileId,{
      responseType: 'arraybuffer',headers:{'Content-Type': 'application/pdf'}}
    ).subscribe(response => {
      console.log("risposta",response);
      this.downLoadFile(response, "application/pdf", idGis)
    });
  }
  downLoadFile(data: any, type: string, idGis) {

    const a = document.createElement('a');
    document.body.appendChild(a);
    //a.style = "display: none";

    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    console.log("url",url);

    a.href = url;
    a.download = "Certificati GIS " + idGis;
    a.click();


    //window.URL.revokeObjectURL(url); //o elimino l'url e non lo apro in modo che lo scarica solo
    let pwa = window.open(url); //oppure lo scarichiamo ma lo apriamo anche


    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
    }
  }



  openModalDeleteSito(modalDeleteSito: TemplateRef<any>, item) {
    this.bsModalRef = this.modalService.show(modalDeleteSito); //apre la modal
    this.noAnimazione = 0; //riattiviamo l'animazione

    //riempie la modal con i dati passati
    this.bsModalRef.content = item.value;
  }
  deleteSito(id, tipo) {
    console.log("Vogliamo eliminare il sito:  " + id);

    //chiamo l'api della delete passando id
    this.dataService.deleteSito(id, tipo).subscribe((data: any[])=>{
      console.log("Risultato delete api", data);

      // redirect
      this.router.navigate(['/siti'])
        .then(() => {
          window.location.reload();//forza reload
        });

      //this.toastr.success( GlobalConstants.DELETE_INDAGINE_TESTO.replace("{id}",id), GlobalConstants.DELETE_INDAGINE_TITOLO);
      this.bsModalRef.hide(); //chiudo la modal
    })

  }


  //EXPORT SITI
  blob;
  exportSiti(): any {
    let sitiExport = [];
    console.log(this.sitiList);
    for (let i = 0; i < this.sitiList.length; i++) {
      for (let z = 0; z < this.sitiList[i].length; z++) {
        if (this.sitiList[i][z].mostra == 1) {
          sitiExport.push({id: this.sitiList[i][z].id, tipo: this.sitiList[i][z].tipo });
        }
      }
    }
    console.log(sitiExport);

    this.dataService.exportSiti(sitiExport).subscribe((data: any) => {
      console.log("export risposta", data);

      this.blob = new Blob([data], {type: 'text/csv'});
      const downloadURL = window.URL.createObjectURL(data);
      const link = document.createElement('a');
      link.href = downloadURL;
      link.download = 'exportSiti.csv';
      link.click();

    });

  }


}
