import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'countVisibleFilter'
})
export class CountVisibleFilterPipe implements PipeTransform {
  transform(value, ...args: any[]): any {
    var countVisible = '';

    for (let i = 0; i < value.length; i++) {
      if (value[i].mostra == 1) {
        countVisible = countVisible + '|' + value[i].mostra;
      }
    }


      return countVisible;
  }
}
