import { Component, OnInit } from '@angular/core';
import {GlobalConstants} from '../../global-constants';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-lista-comuni',
  templateUrl: './lista-comuni.component.html',
  styleUrls: ['./lista-comuni.component.less']
})
export class ListaComuniComponent implements OnInit {

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) { }

  comuniBig;
  comuniList = [];
  numeroSitiLineari: number;
  numeroSitiPuntuali: number;
  numeroIndaginiLineari: number;
  numeroIndaginiPuntuali: number;
  numeroComuni: number;

  ngOnInit(): void {
      this.route.data.subscribe( (risposta: any) => {
      //this.httpClient.get(GlobalConstants.API_COMUNI_BIG).subscribe((risposta: any) => {
        risposta = risposta.singlePost;
      console.log("risposta", risposta);
      this.comuniBig = risposta;

      this.numeroSitiLineari = 0;
      this.numeroSitiPuntuali = 0;
      this.numeroIndaginiLineari = 0;
      this.numeroIndaginiPuntuali = 0;
      this.numeroComuni = 0;
      for (var item in risposta) {
        risposta[item]['numeroIndaginiLineari'] = 0;
        risposta[item]['numeroIndaginiPuntuali'] = 0;
        for (var item2 in risposta[item]['sitiLineari']) {
          this.numeroSitiLineari = this.numeroSitiLineari + 1;
          this.numeroIndaginiLineari = this.numeroIndaginiLineari + risposta[item]['sitiLineari'][item2].indagini.length;
          risposta[item]['numeroIndaginiLineari'] += risposta[item]['sitiLineari'][item2].indagini.length;

        }
        for (var item3 in risposta[item]['sitiPuntuali']) {
          this.numeroSitiPuntuali = this.numeroSitiPuntuali + 1;
          this.numeroIndaginiPuntuali = this.numeroIndaginiPuntuali + risposta[item]['sitiPuntuali'][item3].indagini.length;
          risposta[item]['numeroIndaginiPuntuali'] += risposta[item]['sitiPuntuali'][item3].indagini.length;
        }
        if (risposta[item]['numSitiLineari'] + risposta[item]['numSitiPuntuali'] > 0) {
          this.numeroComuni++;
          this.comuniList.push(risposta[item]);
        }
      }
    });

  }



}
