import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'replaceEtichette'
})
export class ReplaceEtichettePipe implements PipeTransform {
  transform(value: string, ...args: any[]): any {
    var temp = value;
    switch (temp) {
      case "int-pubblico":
        temp = "Intervento Pubblico";
        break;
      case "pubblica":
        temp = "Edilizia Pubblica";
        break;
      case "privata":
        temp = "Edilizia Privata";
        break;
      case "int-pubblica":
        temp = "Intervento Pubblica";
        break;
      case "microzonazione":
        temp = "Microzonazione";
        break;
      case "sisma-2009":
        temp = "Sisma 2019";
        break;
      case "sisma-2016":
        temp = "Sisma 2016";
        break;
      case "lineare":
        temp = "lineari";
        break;
      case "puntuale":
        temp = "puntuali";
        break;
      case "lineari":
        temp = "lineare";
        break;
      case "puntuali":
        temp = "puntuale";
        break;
      default:
        temp = "-";
        break;
    }

    return temp;
  }
}
