<section class="mt-4 mb-4">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md">
        <h1>Visualizza indagini per il sito {{ tipoLabel }} <strong>{{ indagine?.nomeSito }}</strong></h1>
      </div>
      <div class="col col-md-3 text-md-right text-left">

        <a routerLink="/siti/{{tipo}}/{{idSito}}/vista" class="btn btn-primary">Vai al sito {{ tipo }}
          <strong>{{ indagine?.nomeSito }}</strong></a>
        <a [routerLink]="['/siti']" class="btn btn-primary">Tutti i siti</a>

      </div>
    </div>
  </div>
</section>


<section id="sondaggio" class="mt-4">
  <div class="container-fluid">
    <div class="card">
      <div class="card-body">
        <div class="row">
          <div class="col-12 col-md">
            <label>Classe di indagine </label>
            <h5>{{ indagine?.classeInd }} - {{ indagine?.classeIndTitle }}</h5>
          </div>
          <div class="col-12 col-md">
            <label>Tipo di indagine </label>
            <h5>{{ indagine?.tipoInd }} - {{ indagine?.tipoIndTitle }}</h5>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>

<section class="mt-4">
  <div class="container-fluid">
    <div class="card">
      <div class="card-header">Parametri associati al sito {{ tipoLabel }} <strong>{{ indagine?.nomeSito }}</strong> |
        <strong>Classe:</strong> {{ indagine?.classeInd }} - {{ indagine?.classeIndTitle }} |
        <strong>Tipologia:</strong>: {{ indagine?.tipoInd }} - {{ indagine?.tipoIndTitle }}</div>
      <div class="card-body">
        <div class="row">
          <div class="col-md after-add-more">


            <div class="row" *ngFor="let item of indagine?.parametri">
              <input type="hidden" class="id" name="parametri[{{ item.id }}][id]"
                     value="{% if (item.id is defined) %}{{ item.id }}{% endif %}">
              <div class="col-md">
                <div class="row">
                  <div class="col-12 col-md-4">
                    <label>Parametro *</label>
                    <h5>{{ item.tipoPar }}</h5>
                  </div>
                  <div class="col-12 col-md">
                    <label>Valore *</label>
                    <h5>{{ item.valore }}</h5>
                  </div>
                  <div class="col-12 col-md">
                    <label>Prof TOP</label>
                    <h5>{{ item.profTop }}</h5>
                  </div>
                  <div class="col-12 col-md">
                    <label>Prof BOT</label>
                    <h5>{{ item.profBot }}</h5>
                  </div>
                  <div class="col-12 col-md">
                    <label>Spessore</label>
                    <h5>{{ item.spessore }}</h5>
                  </div>
                  <div class="col-12 col-md">
                    <label>Quota SLM TOP</label>
                    <h5>{{ item.quotaSlmTop }}</h5>
                  </div>
                  <div class="col-12 col-md">
                    <label>Quota SLM BOT</label>
                    <h5>{{ item.quotaSlmBot }}</h5>
                  </div>
                </div>


                <div class="row stratigrafia" *ngIf="item?.stratigrafie.length > 0">
                  <div class="col-12 col-md-12">
                    <hr>
                    <h5>Stratigrafia</h5>
                  </div>
                  <div class="col-12 col-md-12">
                    <table class="table">
                      <thead>
                      <tr>
                        <th>#ID</th>
                        <th>Profondità da (m)</th>
                        <th>Profondità a (m)</th>
                        <th>Spessore (m)</th>
                        <th>Sigla CARG</th>
                        <th>Descrizione Litologica</th>
                      </tr>
                      </thead>
                      <tbody>
                      <tr class="strat1" *ngFor="let itemStr of item?.stratigrafie">
                        <td class="idStrat">{{ itemStr.id }}</td>
                        <td>{{ itemStr?.profonditaDa }}</td>
                        <td>{{ itemStr?.profonditaA }}</td>
                        <td>{{ itemStr?.spessore }}</td>
                        <td>{{ itemStr?.siglaCarg }}</td>
                        <td>
                          {{ itemStr?.litologica }}
                        </td>
                      </tr>
                      </tbody>
                    </table>
                  </div>
                </div>


              </div>
              <div class="col-md-12">
                <hr class="mt-3 mb-3">
              </div>
            </div>


          </div>
        </div>
      </div>
    </div>
  </div>
</section>

