import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-simple-template',
  templateUrl: './simple-template.component.html',
  styleUrls: ['./simple-template.component.less']
})
export class SimpleTemplateComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
