<div class="container h-75">

  <div class="row justify-content-center h-100 login-form">
    <div class="col col-sm col-md-6 my-auto">
      <div class="card">
        <div class="card-header bg-white">
          <img src="assets/header-ricevuta.png" class="d-inline-block align-top w-100" alt="">
        </div>
        <div class="card-body">
          <h5 class="cart-title">
            Verifica a 2 passaggi - @Geologi<br>
            <small>Inserisci il codice generato dalla tua app</small>
          </h5>

          <hr>

          <form id="formGA" class="form" #formGA="ngForm" (ngSubmit)="onSubmit(formGA)">

            <div class="row">
              <div class="form-group col">
                <div class="input-group mb-2">
                  <input type="text" [maxLength]="6" class="form-control"  #fieldCodiceGA="ngModel" [ngModel]="" name="codiceGA" [ngClass]="{ 'is-invalid': (submitted && fieldCodiceGA.invalid )}" required placeholder="Codice di verifica">
                  <input type="hidden" #fieldUsername="ngModel" [(ngModel)]="jwtGA.username" name="username">
                  <input type="hidden" #fieldToken="ngModel" [(ngModel)]="jwtGA.token" name="token">

                </div>
              </div>
            </div>


            <div class="row align-items-center">
              <div class="col-md-3">
                <button id="btn-login" class="btn btn-success">Accedi</button>
              </div>
              <div class="col-md-9 text-right">
                <a href="javascript: void(0)" class="pt-1" (click)="mostraRecuperaCodice(recuperaCodice)">non riesci a ottenere il tuo codice?</a>
              </div>
            </div>

          </form>

          <form id="formCodiceGA" class="form" #formCodiceGA="ngForm" (ngSubmit)="onSubmitCodice(formCodiceGA)">

             <div class="row mt-5" *ngIf="recuperaCodice">

               <div class="col-12 text-center">
                 <hr>
                 <button type="button" class="btn btn-success" [disabled]="resetCode == true" (click)="inviaEmailResetCode()">Richiedi codice</button>
               </div>

               <div class="col-md-12 text-center">
                <p class="mt-3">Una volta richiesto il codice riceverai una email al tuo indirizzo <strong>{{usernamePrivate}}</strong> per confermare il reset della tua autenticazione 2FA.</p>
               </div>
               <div class="col-12 text-center">
                 <hr>
                 <p>Inserisci il codice ricevuto via email.</p>

               </div>
              <div class="col-md-9 text-center">
                <input type="text" [maxLength]="6" class="form-control w-100"  #fieldCodiceEmail="ngModel" [ngModel]="" name="codiceEmail" [ngClass]="{ 'is-invalid': (submittedCodice && fieldCodiceEmail.invalid )}" required placeholder="Codice email">
              </div>
              <div class="col-md">
                <button class="btn btn-success">Conferma</button>
              </div>

            </div>

          </form>
        </div>
      </div>
    </div>

  </div>
</div>
