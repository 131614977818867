import {BrowserModule, Title} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {PageNotFoundComponent} from './shared/component/page-not-found/page-not-found.component';
import {HeaderComponent} from './shared/component/header/header.component';
import {DefaultTemplateComponent} from './_layout/default-template/default-template.component';
import {LeafletModule} from '@asymmetrik/ngx-leaflet';
import {GlobalConstants} from './global-constants';
import {HttpClientModule, HTTP_INTERCEPTORS} from "@angular/common/http";
import {FormsModule} from '@angular/forms';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {HomeComponent} from './home/home.component';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {ModalModule} from 'ngx-bootstrap/modal';
import {DatePipe} from '@angular/common';
import {ListaComuniComponent} from './comuni/lista-comuni/lista-comuni.component';
import {SchedaComuniComponent} from './comuni/scheda-comuni/scheda-comuni.component'
import {NgxUiLoaderModule, NgxUiLoaderHttpModule, NgxUiLoaderConfig, SPINNER, POSITION, PB_DIRECTION} from 'ngx-ui-loader';
import {VistaSitiComponent} from './siti/vista-siti/vista-siti.component';
import {VistaIndaginiComponent} from './siti/vista-indagini/vista-indagini.component';
import {LoginComponent} from './login/login.component';
import {JwtInterceptorService} from './service/jwt-interceptor.service';
import { ListaSitiComponent } from './siti/lista-siti/lista-siti.component';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { SharedModuleModule } from './shared/module/shared-module.module';
import { CheckLabelGisPipe } from './shared/pipe/check-label-gis.pipe';
import { SchedaSitiComponent } from './siti/scheda-siti/scheda-siti.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { ToastrModule } from 'ngx-toastr';
import { NgxFileDropModule } from 'ngx-file-drop';
import { AllegatiSitiComponent } from './siti/scheda-siti/allegati-siti/allegati-siti.component';
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { faTrashAlt, faUpload, faPlus, faEdit, faChevronRight, faFilePdf, faEye, faAt, faLock, faUserEdit, faDownload, faQuestion } from '@fortawesome/free-solid-svg-icons';
import { SimpleTemplateComponent } from './_layout/simple-template/simple-template.component';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { RegistrationComponent } from './registration/registration.component';
import { RecuperaPasswordComponent } from './recupera-password/recupera-password.component';
import { ListaUtentiComponent } from './utenti/lista-utenti/lista-utenti.component';
import { SchedaUtentiComponent } from './utenti/scheda-utenti/scheda-utenti.component';
import { ListaStoricoComponent } from './siti/lista-storico/lista-storico.component';
import { VistaStoricoComponent } from './siti/vista-storico/vista-storico.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { ListaMicrozonazioneComponent } from './siti/lista-microzonazione/lista-microzonazione.component';
import { AttivazioneComponent } from './googleAuthenticator/attivazione/attivazione.component';
import { VerificaComponent } from './googleAuthenticator/verifica/verifica.component'; // <-- import the module

@NgModule({
    declarations: [
        AppComponent,
        PageNotFoundComponent,
        HeaderComponent,
        DefaultTemplateComponent,
        HomeComponent,
        ListaComuniComponent,
        SchedaComuniComponent,
        VistaSitiComponent,
        VistaIndaginiComponent,
        LoginComponent,
        ListaSitiComponent,
        CheckLabelGisPipe,
        SchedaSitiComponent,
        AllegatiSitiComponent,
        SimpleTemplateComponent,
        RegistrationComponent,
        RecuperaPasswordComponent,
        ListaUtentiComponent,
        SchedaUtentiComponent,
        ListaStoricoComponent,
        VistaStoricoComponent,
        ListaMicrozonazioneComponent,
        AttivazioneComponent,
        VerificaComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        LeafletModule,
        HttpClientModule,
        BrowserAnimationsModule,
        FormsModule,
        BsDatepickerModule.forRoot(),
        NgxUiLoaderModule.forRoot(GlobalConstants.ngxUiLoaderConfig), //per il loader (configurazioni messe in global-costants.ts)
        NgxUiLoaderHttpModule.forRoot({ //loader aggiunto automaticamente ad ogni richiesta http
            showForeground: true,
            exclude: [
                'https://geocode.search.hereapi.com/v1/geocode', //per la ricerca indirizzo
                "assets/Abruzzo.geojson"
            ],
            excludeRegexp: [
              '/comuni*', // per l'ordinamento
            ]
        }), //per il loader automatico nelle chiamate http (api)
        BsDropdownModule.forRoot(),
        SharedModuleModule,
        NgSelectModule,
        ToastrModule.forRoot({timeOut: 5000, progressBar:true, progressAnimation:"increasing", preventDuplicates:true, enableHtml:true}), // per le notifiche toast
        NgxFileDropModule, //per l'upload di file
        ModalModule.forRoot(), FontAwesomeModule, // per le modali
        TooltipModule.forRoot(),
        NgxPaginationModule

    ],
    providers: [DatePipe, Title, {provide: HTTP_INTERCEPTORS, useClass: JwtInterceptorService, multi: true}], //per cambiare il titolo della pagina ovunque
    bootstrap: [AppComponent]
})

export class AppModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(faTrashAlt,faUpload,faPlus,faEdit,faChevronRight,faFilePdf,faEye,faAt,faLock,faUserEdit,faDownload,faQuestion);//per usare le icone FontAwesomeModule ovunque
  }
}
