<nav class="navbar navbar-light bg-white navbar-expand-md border-bottom">
    <div class="container-fluid">
        <a class="navbar-brand" [routerLink]="['/']" routerLinkActive #homeSiti="routerLinkActive" [routerLinkActiveOptions]="{exact: true}">
            <img src="assets/header-ricevuta.png" class="d-inline-block align-center" alt="" height="60">
            <span class="d-inline pl-2">Portale Indagini Geologiche</span>
        </a>

        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false"
                aria-label="Toggle navigation">
            <span class="navbar-toggler-icon"></span>
        </button>


        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <container-element [ngSwitch]="decodedJwt?.roles.role" class="float-right w-100">
                <some-element *ngSwitchCase="'ROLE_ADMIN'" class="float-right w-100">
                    <ul class="navbar-nav ml-auto mr-5 float-right">
                        <li class="nav-item" [ngClass]="{'active': allSiti.isActive || homeSiti.isActive }">
                            <a class="btn" [routerLink]="['siti']" routerLinkActive #allSiti="routerLinkActive" >Tutti i siti e le indagini</a>
                        </li>
                      <li class="nav-item" routerLinkActive="active">
                        <a class="btn" [routerLink]="['storico']" (click)="mostraLoader()" >Storico</a>
                      </li>

                      <li class="nav-item" routerLinkActive="active">
                        <a class="btn" [routerLink]="['microzonazione']" (click)="mostraLoader()" >Microzonazione</a>
                      </li>

                        <li class="nav-item dropdown" >
                            <span dropdown>
                                <button class="btn ml-2 dropdown-toggle" type="button" id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown">Utenti <span class="caret"></span></button>
                                <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu ng-star-inserted" role="menu" aria-labelledby="basic-link">
                                    <li role="menuitem"><a class="dropdown-item" routerLink="/utenti">Elenco Utenti</a></li>
                                    <li class="divider dropdown-divider"></li>
                                    <li role="menuitem"><a class="dropdown-item" routerLink="/utenti/new" >Aggiungi Utente</a>
                                </ul>
                            </span>
                        </li>
                        <li class="nav-item dropdown">
                            <span dropdown>
                                <button class="btn ml-2 dropdown-toggle" type="button" id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown">{{ decodedJwt?.username }} <span class="caret"></span></button>
                                <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu ng-star-inserted" role="menu" aria-labelledby="basic-link">
                                    <li role="menuitem"><a class="dropdown-item" routerLink="utenti/{{decodedJwt?.userId}}">Modifica profilo</a></li>
                                    <li class="divider dropdown-divider"></li>
                                    <li role="menuitem"><a class="dropdown-item" href="#"  routerLink="/login" (click)="logOut()">Logout</a>
                                </ul>
                            </span>
                        </li>
                    </ul>
                </some-element>
                <some-element *ngSwitchCase="'ROLE_USER'" class="float-right">
                  <ul class="navbar-nav ml-auto mr-5 float-right">
                    <li class="nav-item active">
                      <a class="btn" [routerLink]="['siti']" >I miei i siti e indagini</a>
                    </li>

                    <li class="nav-item" *ngIf="decodedJwt?.idStorico != ''">
                      <a class="btn" [routerLink]="['storico']" (click)="mostraLoader()" >Storico</a>
                    </li>

                    <li class="nav-item">
                      <a class="btn" [routerLink]="['microzonazione']" (click)="mostraLoader()" >Microzonazione</a>
                    </li>

                    <li class="nav-item dropdown">
                            <span dropdown>
                                <button class="btn ml-2 dropdown-toggle" type="button" id="basic-link" dropdownToggle (click)="false" aria-controls="basic-link-dropdown">{{ decodedJwt?.username }} <span class="caret"></span></button>
                                <ul id="basic-link-dropdown" *dropdownMenu class="dropdown-menu ng-star-inserted" role="menu" aria-labelledby="basic-link">
                                    <li role="menuitem"><a class="dropdown-item" routerLink="utenti/{{decodedJwt?.userId}}">Modifica profilo</a></li>
                                    <li class="divider dropdown-divider"></li>
                                    <li role="menuitem"><a class="dropdown-item" href="#"  routerLink="/login" (click)="logOut()">Logout</a>
                                </ul>
                            </span>
                    </li>
                  </ul>
                </some-element>
                <some-element *ngSwitchDefault>
                    <ul class="navbar-nav ml-auto mr-5 float-right">

                        <li class="ml-2 nav-item">
                            <a class="nav-link" [routerLink]="['/']" role="button">Mappa</a>
                        </li>

                        <li class="ml-2 nav-item">
                            <a class="nav-link" [routerLink]="['comuni']" role="button">Comuni</a>
                        </li>

                        <li class="ml-2 nav-item">
                            <a class="nav-link" [routerLink]="['login']" role="button"><fa-icon [icon]="['fas', 'user-edit']"></fa-icon> Area riservata tecnici</a>
                        </li>
                    </ul>
                </some-element>
            </container-element>





        </div>
    </div>
</nav>
