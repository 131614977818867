
<!--<div class="input-group">
    <div class="custom-file">
        <input type="file" class="custom-file-input" id="inputGroupFile04">
        <label class="custom-file-label" for="inputGroupFile04">Choose file</label>
    </div>
    <div class="input-group-append">
        <button class="btn btn-danger" type="button">Elimina</button>
    </div>
</div>-->


<ngx-file-drop dropZoneLabel="Drop files here"
               dropZoneClassName="dropzone-upload"
               (onFileDrop)="dropped($event)"
               (onFileOver)="fileOver($event)"
               (onFileLeave)="fileLeave($event)"
               useDragEnter="true"
               accept="{{accept}}"
>
    <ng-template ngx-file-drop-content-tmp let-openFileSelector="openFileSelector">

        <div class="" style="line-height: 1em">
            <strong class="mb-3">Trascina qui </strong> oppure
            <!--<div style="height: 100%; width: 100%" (click)="openFileSelector()">-->

            <button type="button" class="btn btn-primary" (click)="openFileSelector()"><fa-icon [icon]="['fas', 'upload']"></fa-icon> Carica {{textButton}}</button>

            <!--</div>-->
        </div>

    </ng-template>
</ngx-file-drop>




<div *ngIf="fileRelGeologica?.length > 0">
    <div class="upload-table mt-2" [ngClass]="fileRelGeologica.length > 3 ? 'overflow-auto' : 'overflow-hidden'" style="max-height: 130px;">
        <table class="table table-borderless table-sm">
            <tbody class="upload-name-style">
            <tr *ngFor="let item of fileRelGeologica; let i=index"  [@deleteAnimation] style="border-bottom: 1px solid #dee2e6">
                <td>
                    <a href="javascript: void(0)" style="line-height: 30px" (click)="leggiFileById(item.fileEntry?.id)">{{ item.fileEntry?.name }}</a>
                    <a [routerLink]="" class="btn-sm btn-danger float-right" (click)="onDeleteFile(item.fileEntry?.id)"><fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>Elimina</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="fileRelGeotecnica?.length">
    <div class="upload-table mt-2" [ngClass]="fileRelGeotecnica.length > 3 ? 'overflow-auto' : 'overflow-hidden'" style="max-height: 130px;">
        <table class="table table-borderless table-sm">
            <tbody class="upload-name-style">
            <tr *ngFor="let item of fileRelGeotecnica; let i=index"  [@deleteAnimation] style="border-bottom: 1px solid #dee2e6">
                <td>
<!--
                    <a href="{{ liveSite }}/getFileById/{{item.fileEntry?.id}}" style="line-height: 30px">{{ item.fileEntry?.name }}</a>
-->
                  <a href="javascript: void(0)" style="line-height: 30px" (click)="leggiFileById(item.fileEntry?.id)">{{ item.fileEntry?.name }}</a>
                    <a [routerLink]="" class="btn-sm btn-danger float-right" (click)="onDeleteFile(item.fileEntry?.id)"><fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>Elimina</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>

<div *ngIf="fileAltro?.length">
    <div class="upload-table mt-2" [ngClass]="fileAltro.length > 3 ? 'overflow-auto' : 'overflow-hidden'" style="max-height: 130px;">
        <table class="table table-borderless table-sm">
            <tbody class="upload-name-style">
            <tr *ngFor="let item of fileAltro; let i=index"  [@deleteAnimation] style="border-bottom: 1px solid #dee2e6">
                <td>
                  <a href="javascript: void(0)" style="line-height: 30px" (click)="leggiFileById(item.fileEntry?.id)">{{ item.fileEntry?.name }}</a>
                    <a [routerLink]="" class="btn-sm btn-danger float-right" (click)="onDeleteFile(item.fileEntry?.id)"><fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>Elimina</a>
                </td>
            </tr>
            </tbody>
        </table>
    </div>
</div>
