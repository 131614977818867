<form id="formSito" #sitoForm="ngForm" (ngSubmit)="onSubmit(sitoForm)">


<section class="mt-4 mb-4">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 col-md">
              <div *ngIf="idSito == 'new'; then titleNewBlock else titleBlock"></div>
              <ng-template #titleNewBlock>
                <h1>Senza nome<small> | {{ tipo | replaceEtichette }}</small></h1>
              </ng-template>
              <ng-template #titleBlock>
                <h1>{{ sito?.denominazione }} <small> | {{ tipo | replaceEtichette }}</small></h1>
              </ng-template>
            </div>

            <div *ngIf="idSito == 'new'; then gisNewBlock else gisBlock"></div>
            <ng-template #gisNewBlock>
                <div class="col-6 col-md">
                    <label>E' necessario indicare se il sito che si sta creando è o meno associato ad uno esistente.</label>
                    <select name="codiceGis" class="form-control form-control-sm gis" #codiceGis="ngModel" [(ngModel)]="new_gis" >
                        <option value="">Nuovo GIS</option>

                        <optgroup label="GIS Lineari">
                            <ng-container *ngFor="let item of arrayGisL">
                                <option value="{{ item }}">{{ item }}</option>
                            </ng-container>
                        </optgroup>
                        <optgroup label="GIS Puntuali">
                            <ng-container *ngFor="let item of arrayGisP">
                                <option value="{{ item }}">{{ item }}</option>
                            </ng-container>
                        </optgroup>
                    </select>
                </div>
            </ng-template>
            <ng-template #gisBlock>
                <div class="col-6 col-md">
                    <div class="alert alert-success d-inline">Codice GIS sito <strong> {{sito?.gis}} </strong> <sup data-toggle="tooltip" data-placement="top" title="Descrizione del valore"><i class="fas fa-info-circle"></i></sup></div>
                </div>
            </ng-template>

          <div *ngIf="decodedJwt?.roles.role == 'ROLE_USER'; then userSiti else adminSiti"></div>
          <ng-template #userSiti>
            <div class="col col-md-3 text-md-right text-left">
              <a [routerLink]="['/siti']" class="btn btn-secondary float-right">I miei siti</a>
            </div>
          </ng-template>
          <ng-template #adminSiti>
            <div class="col col-md-3 text-md-right text-left">
              <a [routerLink]="['/siti']" class="btn btn-secondary float-right">Tutti i siti</a>
            </div>
          </ng-template>



        </div>
    </div>
</section>


<section class="request">
    <div class="container-fluid">
        <div class="card">
            <div class="card-header">
                <h1>Sito dell'indagine</h1>
                <span class="red">I dati contrassegnati con * (asterisco) sono oblbigatori.</span>
            </div>
            <div class="card-body">
                <h5 class="card-title">Dati del sito</h5>

                <div class="row">
                    <!-- <input type="hidden" id="idUtente" name="idUtente" #idUtente="ngModel" [(ngModel)]="decodedJwt.userId" > -->
                    <div *ngIf="decodedJwt.roles.role == 'ROLE_ADMIN'" class="col-12 col-md" >
                        <label>Proprietario *</label>
                        <ng-select id="idUtente" name="idUtente" placeholder="Proprietario" #idUtente="ngModel" [(ngModel)]="sito?.idUtente != null ? sito?.idUtente : decodedJwt.userId" [clearable]="false">
                          <ng-option [value]="item.id" *ngFor="let item of utenti">{{ item.nome }} {{ item.cognome}} | {{ item.username }}</ng-option>
                        </ng-select>
                    </div>
                    <div *ngIf="decodedJwt.roles.role == 'ROLE_USER'" >
                      <input type="hidden" id="idUtente" name="idUtente" #idUtente="ngModel" [(ngModel)]="decodedJwt.userId" >
                    </div>

                    <div class="col-12 col-md">
                        <label>Denominazione intervento/progetto</label>
                        <input type="text" class="form-control form-control-sm" name="denominazione" #denominazione="ngModel" [(ngModel)]="sito.denominazione" placeholder="Denominazione intervento/progetto | Facoltativa"
                               [ngClass]="{ 'is-invalid': (submitted && denominazione.invalid)}" required>
                    </div>
                    <div class="col-12 col-md">
                        <label>Commitente *</label>
                        <input type="text" class="form-control form-control-sm" name="committente" #committente="ngModel" [(ngModel)]="sito.committente" placeholder="Committente indagine *"
                               [ngClass]="{ 'is-invalid': (submitted && committente.invalid)}" required>
                    </div>
                    <div class="col-12 col-md">
                        <label>Data inserimento *</label>
                        <input type="text" name="dataSito" #dataSito="ngModel" [(ngModel)]="sito.dataSito" [ngClass]="{ 'is-invalid': (submitted && dataSito.invalid)}" required
                               placeholder="Data"
                               class="form-control form-control-sm"
                               bsDatepicker
                               [bsConfig]="{ containerClass: 'theme-default', dateInputFormat: 'DD/MM/YYYY', isAnimated: true , adaptivePosition: true }" >
                    </div>
                </div>

                <hr class="mt-4 mb-4">

                <h5 class="card-title">Tipologia sito</h5>
                <div class="row">
                    <div class="col-12 col-md-3">
                        <label>Tipologia di intervento *</label>
                        <select name="edilizia" id="edilizia" class="form-control form-control-sm" (change)="mostraOpzioniByEdilizia(edilizia)"
                                #edilizia="ngModel" [(ngModel)]="sito.edilizia" [ngClass]="{ 'is-invalid': (submitted && edilizia.invalid)}" required >
                            <option value="">---</option>
                            <option value="int-pubblico">Intervento Pubblico</option>
                            <option value="pubblica">Edilizia Pubblica</option>
                            <option value="privata">Edilizia Privata</option>
                            <option value="microzonazione">Microzonazione</option>
                        </select>
                    </div>
                    <div class="col-12 col-md" *ngIf="mostraGenioCivile == true">
                        <label>ID del Genio Civile</label>
                        <input type="number" class="form-control form-control-sm" name="genioCivile" placeholder="ID genio civile" #genioCivile="ngModel" [(ngModel)]="sito.genioCivile">
                    </div>
                    <div class="col-12 col-md" *ngIf="mostraCup == true">
                        <label>CUP *</label>
                        <input type="text" class="form-control form-control-sm" name="cup" placeholder="CUP *"
                               #cup="ngModel" [(ngModel)]="sito.cup" [ngClass]="{ 'is-invalid': (submitted && cup.invalid)}" required>
                    </div>
                    <div class="col-12 col-md" *ngIf="mostraSisma == true">
                        <label>Ambito dell'intervento *</label>
                        <select name="sisma" class="form-control form-control-sm" #sisma="ngModel" [(ngModel)]="sito.sisma" [ngClass]="{ 'is-invalid': (submitted && sisma.invalid)}" required>
                            <option value="sisma-2009" selected>Sisma 2009</option>
                            <option value="sisma-2016" >Sisma 2016</option>
                            <option value="nosisma" >Altro</option>
                        </select>
                    </div>
                    <div class="col-12 col-md" *ngIf="mostraProtocollo == true">
                        <label>Protocollo sisma (AQ-BCE-00000) *</label>
                        <input type="text" class="form-control form-control-sm" name="protocollo" placeholder="Protocollo sisma (AQ-BCE-00000)" #protocollo="ngModel" [(ngModel)]="sito.protocollo" [ngClass]="{ 'is-invalid': (submitted && protocollo.invalid)}" required>
                    </div>
                    <div class="col-12 col-md" *ngIf="mostraIdAggregato == true">
                        <label>ID aggregato *</label>
                        <input type="text" class="form-control form-control-sm" name="idaggregato" placeholder="ID aggregato" #idAggregato="ngModel" [(ngModel)]="sito.idAggregato" [ngClass]="{ 'is-invalid': (submitted && idAggregato.invalid)}" required>
                    </div>
                </div>

                <div class="row">
                    <div class="col-12 col-md-3 mt-4">
                        <label>Fonte del finanziamento *</label>
                        <select name="finanziamento" class="form-control form-control-sm"  #finanziamento="ngModel" [(ngModel)]="sito.finanziamento" [ngClass]="{ 'is-invalid': (submitted && finanziamento.invalid)}" required>
                            <option value="">---</option>
                            <option value="Privato">Privato</option>
                            <option value="Regione">Regione</option>
                            <option value="Comune">Comune</option>
                            <option value="Unione-Europea">Unione Europea</option>
                            <option value="Altro">Altro</option>
                        </select>
                    </div>
                </div>

                <hr class="mt-4 mb-4">

                <h5 class="card-title">Localizzazione sito</h5>
                <div class="row">
                    <div class="col-md">
                        <div class="row">
                            <div class="col-md">

                                <div class="row">
                                    <div class="col-md-2">
                                        <label>Modo identificazione Coordinate</label>
                                        <select class="form-control form-control-sm" name="modIdentcoord"  #modIdentcoord="ngModel" [(ngModel)]="sito.modIdentcoord" >
                                            <option value="">---</option>
                                            <option *ngFor="let item of tipoCoor" [value]="item.legTipoCoor">{{ item.legTipoCoor }}</option>
                                        </select>
                                    </div>

                                    <div *ngIf="tipo == 'lineari'; then coordinateLineariBlock else coordinatePuntualiBlock"></div>
                                    <ng-template #coordinateLineariBlock>
                                        <div class="col-md">
                                            <label>Coordinate Ax</label><br>
                                            <input type=number step=0.00001 class="form-control form-control-sm" name="acoordX" #acoordX="ngModel" [(ngModel)]="sito.AcoordX" >
                                        </div>
                                        <div class="col-md">
                                            <label>Coordinate Ay</label><br>
                                            <input type=number step=0.00001 class="form-control form-control-sm"  name="acoordY" #acoordY="ngModel" [(ngModel)]="sito.AcoordY" >
                                        </div>
                                        <div class="col-md">
                                            <label>Coordinate Bx</label><br>
                                            <input type=number step=0.00001 class="form-control form-control-sm"  name="bcoordX" #bcoordX="ngModel" [(ngModel)]="sito.BcoordX" (keyup)="onKeyUpB(bcoordX, bcoordY)">
                                        </div>
                                        <div class="col-md">
                                            <label>Coordinate By</label><br>
                                            <input type=number step=0.00001 class="form-control form-control-sm" name="bcoordY" #bcoordY="ngModel" [(ngModel)]="sito.BcoordY" (keyup)="onKeyUpB(bcoordX, bcoordY)">
                                        </div>
                                        <div class="col-md border-left">
                                            <label>Quota A</label><br>
                                            <input type="text" class="form-control form-control-sm" name="aquota" placeholder="Quota A" #aquota="ngModel" [(ngModel)]="sito.aquota" >
                                        </div>
                                        <div class="col-md">
                                            <label>Quota B</label><br>
                                            <input type="text" class="form-control form-control-sm" name="bquota" placeholder="Quota B" #bquota="ngModel" [(ngModel)]="sito.bquota" >
                                        </div>
                                    </ng-template>
                                    <ng-template #coordinatePuntualiBlock>
                                        <div class="col-md">
                                            <label>Coordinate X</label><br>
                                            <input type="text" name="coordX" class="form-control form-control-sm" #coordX="ngModel" [(ngModel)]="sito.coordX" (keyup)="onKeyUpA(coordX, coordY)">
                                        </div>
                                        <div class="col-md">
                                            <label>Coordinate Y</label><br>
                                            <input type="text" name="coordY" class="form-control form-control-sm" #coordY="ngModel" [(ngModel)]="sito.coordY" (keyup)="onKeyUpA(coordX, coordY)">
                                        </div>
                                        <div class="col-md border-left">
                                            <label>Modo identificazione quota</label>
                                            <select class="form-control form-control-sm" name="modoQuota" #modoQuota="ngModel" [(ngModel)]="sito.modoQuota" >
                                                <option value="">---</option>
                                                <option *ngFor="let item of tipoCoor" [value]="item.legTipoCoor">{{ item.legTipoCoor }}</option>
                                            </select>
                                        </div>
                                        <div class="col-md">
                                            <label>Quota SLM</label><br>
                                            <input type="text" class="form-control form-control-sm" name="quotaSlm" placeholder="Quota SLM" #quotaSlm="ngModel" [(ngModel)]="sito.quotaSlm" >
                                        </div>

                                    </ng-template>


                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12">
                        <span class="red">Le coordinate vanno inserite nel formato WGS84 - Decimale</span>
                    </div>
                </div>

                <hr class="mt-4 mb-4">

                <div class="row">

                    <div class="col-md-6">
                        <h5 class="card-title">Visualizza su mappa <small> | La visualizzazione ha la sola finalità di individuare i siti sulla mappa.</small></h5>
                        <div class="row">
                            <div class="col-md-5">
                                <select class="form-control form-control-sm" name="comune" required
                                        #comuneId="ngModel" [(ngModel)]="sito.comuneId" [ngClass]="{ 'is-invalid': (submitted && comuneId.invalid)}" >
                                    <option value="" selected="selected">Indica il comune *</option>
                                    <option *ngFor="let item of comuni" [value]="item.id">{{ item.comune }} [ISTAT {{item.istat}}]</option>
                                </select>
                            </div>

                            <div class="col-md-2">
                                <input type="text" class="form-control form-control-sm" name="cap" placeholder="CAP *" #cap="ngModel" [(ngModel)]="sito.cap" [ngClass]="{ 'is-invalid': (submitted && cap.invalid)}" required>
                            </div>

                            <div class="col-md">
                                <input type="text" class="form-control form-control-sm" name="frazione" placeholder="Frazione" #frazione="ngModel" [(ngModel)]="sito.frazione" >
                            </div>

                        </div>
                        <div class="row mt-3">
                            <div class="col-md-12">

                            <label>Indirizzo *</label>
                            <ng-select class="required" name="indirizzoSearch" #indirizzoSearch="ngModel" [(ngModel)]="indirizzoSelected" [items]="indirizzi | async"
                                       [ngClass]="{ 'is-invalid': (submitted && indirizzoSearch.invalid )}" required
                                       placeholder="Inserisci l'indirizzo"
                                       bindLabel="title"
                                       addTagText="Seleziona: "
                                       [addTag]="true"
                                       [(loading)]="isLoading"
                                       loadingText="Loading..."
                                       (search)="geolocalizzaIndirizzo(indirizzoSearch)"
                                       [searchFn]="customSearchFn"
                                       (change)="onChangeIndirizzo(indirizzoSearch)"
                                       (clear)="resetMap()"
                                       [editableSearchTerm]="true"
                            >
                            </ng-select>

                            <span class="badge mr-2" [ngClass]="sito.lat > 0 ? 'badge-light' : 'badge-danger'">Latitudine: {{ sito?.lat }}</span>
                            <span class="badge mr-2" [ngClass]="sito.lat > 0 ? 'badge-light' : 'badge-danger'">Longitudine: {{ sito?.lng }}</span>

                            <div *ngIf="sito?.indirizzo && !sito?.lat" class="alert alert-danger mt-3" role="alert">
                                Attenzione! L'indirizzo <strong>{{ sito.indirizzo }}</strong> non è stato localizzato. Aggiungere il marker facendo click sulla mappa.
                            </div>



                            <input type="hidden" class="form-control" name="indirizzo" #indirizzo="ngModel" [(ngModel)]="sito.indirizzo" [ngClass]="{ 'is-invalid': (submitted && indirizzo.invalid)}" required>
                            <input type="hidden" class="form-control" name="lat" #lat="ngModel" [(ngModel)]="sito.lat" [ngClass]="{ 'is-invalid': (submitted && lat.invalid)}" required>
                            <input type="hidden" class="form-control" name="lng" #lng="ngModel" [(ngModel)]="sito.lng" [ngClass]="{ 'is-invalid': (submitted && lng.invalid)}" required>


                            </div>
                        </div>
                        <div class="row mt-2">
                            <div class="col-md-12">
                                <small id="emailHelp" class="form-text text-muted pt-1 pl-2 d-inline-block"> Se ritieni che la localizzazione non si precisa puoi spostare il marker presente sulla mappa nella posizione corretta.</small>
                            </div>
                        </div>

                    </div>
                    <div [ngClass]="tipo == 'lineari' ? 'col-md-3' : 'col-md-6'">
                        <div style="height: 200px;" [ngClass]="{ 'map-invalid': (submitted && lat.invalid)}"
                             leaflet
                             [(leafletZoom)]="zoomMap"
                             [leafletPanOptions]="zoomOptions"
                             [(leafletCenter)]="centerMap"
                             [leafletLayer]="markerIndirizzo"
                             [leafletOptions]="optionMap"
                             (leafletClick)="onClickMap($event)">
                        </div>
                        <div class="mappa-label"><strong>A</strong></div>
                    </div>
                    <div class="col-md-3" *ngIf="tipo == 'lineari'">
                        <div style="height: 200px;"
                             leaflet
                             [(leafletZoom)]="zoomMap_B"
                             [leafletPanOptions]="zoomOptions_B"
                             [(leafletCenter)]="centerMap_B"
                             [leafletLayer]="markerIndirizzo_B"
                             [leafletOptions]="optionMap_B"
                             (leafletClick)="onClickMap_B($event)">
                        </div>
                        <div class="mappa-label"><strong>B</strong></div>
                    </div>
                </div>

                <hr class="mt-4 mb-4">

                <h5 class="card-title">Relazioni da allegare</h5>

                    <div *ngIf="sito.id; then puoiAggiungereAllegati else nonPuoiAggiungereAllegati"></div>
                    <ng-template #puoiAggiungereAllegati>
                        <div class="row">
                            <div class="col-12 col-md">
                                <label>Relazione Geologica e modellazione sismica o relazione illustrativa della MS *</label>
                                <div [ngClass]="{ 'map-invalid': submitted && (validazioneGeologica == 0)}">
                                    <app-allegati-siti (riabilitaValidazioneGeologica)="aggiungiValidazione($event)" (unFileCaricatoGeologica)="rimuoviValidazione($event)" [idSito]="sito.id"  [tipoSito]="sito.tipo" [arrayFileRelGeologica]="sito.allegati_geologica" [type]="'relazioneGeologica'" [textButton]="'File'" [accept]="'.pdf'"></app-allegati-siti>
                                </div>
                            </div>

                            <div class="col-12 col-md" *ngIf="edilizia.value != 'microzonazione'">
                                <label>Relazione Geotecnica sulle indagini *</label>
                                <div [ngClass]="{ 'map-invalid': submitted && (validazioneGeotecnica == 0) }">
                                    <app-allegati-siti (riabilitaValidazioneGeotecnica)="aggiungiValidazione($event)" (unFileCaricatoGeotecnica)="rimuoviValidazione($event)" [idSito]="sito.id" [tipoSito]="sito.tipo"  [arrayFileRelGeotecnica]="sito.allegati_geotecnica" [type]="'relazioneGeotecnica'" [textButton]="'File'" [accept]="'.pdf'"></app-allegati-siti>
                                </div>
                            </div>

                            <div class="col-12 col-md">
                                <label>Altro</label>
                                <div [ngClass]="{ 'map-invalid': (submitted && sito.allegati_altro.length)}">
                                    <app-allegati-siti [idSito]="sito.id"  [tipoSito]="sito.tipo" [arrayFileAltro]="sito.allegati_altro" [type]="'altro'" [textButton]="'File'" [accept]="'.pdf'"></app-allegati-siti>
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <ng-template #nonPuoiAggiungereAllegati>
                        <div class="alert alert-danger" role="alert">
                            Devi salvare prima di poter aggiungere allegati.
                        </div>
                    </ng-template>




                <hr class="mt-4 mb-4">

                <div class="row">
                    <div class="col-md-12">
                        <div *ngIf="idSito == 'new'; then salvaNewBlock else salvaBlock"></div>
                        <ng-template #salvaNewBlock>
                            <button type="submit" class="btn btn-success">SALVA SITO ED AGGIUNGI INDAGINI</button>
                        </ng-template>
                        <ng-template #salvaBlock>
                            <button type="submit" class="btn btn-success">AGGIORNA SITO</button>
                        </ng-template>
                    </div>
                </div>


            </div>
        </div>
    </div>
</section>

</form>




<hr>

<div class="container-fluid">
  <div class="card">
    <div class="card-header">
      <h1>
        Indagini del sito


        <ng-template [ngIf]="(idSito != 'new' && (validazioneGeologica == 1 && validazioneGeotecnica == 1)) || (idSito != 'new' && sito.edilizia == 'microzonazione' && validazioneGeologica == 1)">

          <div *ngIf="sito.sisma == 'sisma-2009'; then thenBlockIndagine1 else thenBlockIndagine2"></div>
          <ng-template #thenBlockIndagine1>
              <div class="btn-group float-right" dropdown>
                <button id="button-basic" dropdownToggle type="button" class="btn btn-primary dropdown-toggle" aria-controls="dropdown-basic">
                  Aggiungi indagine <span class="caret"></span>
                </button>
                <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu"
                    role="menu" aria-labelledby="button-basic">
                  <li role="menuitem"><a href="javascript: void(0)" class="dropdown-item" (click)="openModalAggiungiIndagine(modalIndagine, null)">Nuova</a></li>
                  <div class="dropdown-divider"></div>
                  <li role="menuitem"><a href="javascript: void(0)" class="dropdown-item" (click)="openModalAggiungiIndagineEsistente(modalIndagineEsistente, null)">Esistente</a></li>
                </ul>
              </div>
          </ng-template>
          <ng-template #thenBlockIndagine2>
              <button type="button" class="btn btn-primary float-right" (click)="openModalAggiungiIndagine(modalIndagine, null)">
                Aggiungi indagine
              </button>
          </ng-template>
        </ng-template>



      </h1>

    </div>
    <div class="card-body">

      <!-- ######################### FILTRI ####################### -->
      <div id="search-collapse" class="mb-4">
        <div class="form-row">
          <div class="col-12 col-md">
            <label for="filter_classe_indagine">Classe di indagine</label>
            <ng-select class="" name="filter_classe_indagine" placeholder="Classe" #filterClasseIndagine="ngModel" [ngModel] [clearable]="true" (clear)="filtroTipoIndagine = null; filtroParametro = null">
              <ng-option [value]="item.sigClasseIndagine" *ngFor="let item of classeIndagine">{{ item.sigClasseIndagine }} - {{ item.desClasseIndagine }}</ng-option>
            </ng-select>
          </div>

          <div class="col-12 col-md">
            <label for="filter_tipo_indagine">Tipo di indagine</label>
            <ng-select class="" name="filter_tipo_indagine" placeholder="Tipo" #filterTipoIndagine="ngModel" [(ngModel)]="filtroTipoIndagine" [clearable]="true" [disabled]="filterClasseIndagine.value == null"  (clear)="filtroParametro = null">
              <ng-container *ngFor="let item of tipoIndagine">
                <ng-template [ngIf]="(filterClasseIndagine.value == '' || filterClasseIndagine.value == item.classe)">
                  <ng-option [value]="item.sigTipoIndagine" [attr.tipoIndagine]="item.tipoIndagine">{{ item.sigTipoIndagine }} - {{ item.desTipoIndagine }}</ng-option>
                </ng-template>
              </ng-container>
            </ng-select>
          </div>

          <div class="col-12 col-md">
            <label>Parametro</label>
            <ng-select class="" name="filter_tipo_indagine" placeholder="Parametro" #filterParametro="ngModel" [(ngModel)]="filtroParametro" [clearable]="true" [disabled]="filterClasseIndagine?.value == null || filterTipoIndagine?.value == null ">
              <ng-container *ngFor="let item of parametriIndagine">
                <ng-template [ngIf]="((item.tipo).split('-')).indexOf(filterTipoIndagine?.value) > -1">
                  <ng-option [value]="item.tipoPar">{{ item.descrPar }} | {{ item.par }} | {{ item.tipoPar }} | {{ item.uMisura }} | {{ item.valMin }} / {{ item.valMax }}</ng-option>
                </ng-template>
              </ng-container>
            </ng-select>
          </div>

        </div>
      </div>






      <div *ngIf="idSito == 'new'; then salvaSitoPrima else aggiungiIndagine"></div>

      <ng-template #aggiungiIndagine>

        <div *ngIf="(validazioneGeologica == 1 && validazioneGeotecnica == 1) || (sito.edilizia == 'microzonazione' && validazioneGeologica == 1); then sitoCompletato else completaSitoPrima"></div>
        <ng-template #completaSitoPrima>
          <div class="alert alert-danger" role="alert">
            Devi aggiornare il sito prima di poter aggiungere indagini.
          </div>
        </ng-template>
        <ng-template #sitoCompletato>
          <div id="list" class="row">
            <div class="col">
              <div class="table-responsive-sm">
                <table class="table table-bordered text-left">
                  <thead>
                  <tr>
                    <th scope="col" class="text-center" style="width:3%">
                      #ID <a href="" class="sortById desc"><i class="fas fa-caret-down"></i></a>
                    </th>
                    <th scope="col">
                      Classe indagine
                    </th>
                    <th scope="col">
                      Tipologia indagine
                    </th>
                    <th scope="col">
                      Numero parametri
                    </th>
                    <th scope="col">
                      Data creazione
                    </th>
                    <th scope="col">
                      Documenti
                    </th>

                    <th scope="col" class="text-center">
                      Azioni
                    </th>
                  </tr>
                  </thead>
                  <tbody>

                  <tr *ngFor="let item of (sito.indagini)?.slice().reverse()" [@deleteAnimation] [@.disabled]="noAnimazione" [hidden]="confrontaFiltro(item, filterClasseIndagine.value, filterTipoIndagine.value, filterParametro.value)">
                    <td scope="row" class="text-center row-id">
                      {{ item.id }}
                    </td>
                    <td class="title">
                      {{ item.classeInd }} - {{ item.classeIndTitle }} <!--{{ classe[item.classeInd].desClasseIndagine }}-->
                    </td>
                    <td>
                      {{ item.tipoInd }} - {{ item.tipoIndTitle }}<!--{{ tipo[item.tipoInd].desTipoIndagine }}-->
                    </td>
                    <td>
                      {{ item.numParametri }}
                    </td>
                    <td>
                      <strong>{{ item.dataInd }}</strong>
                    </td>
                    <td>
                      <ng-template [ngIf]="item.documento && item.documento.length > 0">
                        <fa-icon [icon]="['fas', 'upload']"></fa-icon> <a class="ml-2" href="javascript: void(0)" (click)="leggiFileById(item.documento[0].id)">{{ item.documento[0].name }}</a>
                      </ng-template>
                      <ng-template [ngIf]="item.documento && item.documento.length == 0">
                        -
                      </ng-template>
                    </td>
                    <td class="actions text-center">
                      <button type="button" class="btn btn-primary" (click)="openModalAggiungiIndagine(modalIndagine, item)">
                        <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                      </button>
                      |
                      <button type="button" class="btn btn-danger" (click)="openModalDeleteIndagine(modalDeleteIndagine,item)">
                        <fa-icon [icon]="['fas', 'trash-alt']"></fa-icon>
                      </button>

                    </td>
                  </tr>

                  </tbody>
                </table>

              </div>
            </div>
          </div>
        </ng-template>

      </ng-template>
      <ng-template #salvaSitoPrima>
        <div class="alert alert-danger" role="alert">
          Devi salvare il sito prima di poter aggiungere indagini.
        </div>
      </ng-template>



    </div>
  </div>
</div>















<!-- ############################# MODAL INDAGINE - FORM ############################# -->
<ng-template #modalIndagine>
  <form id="formContatti" #formIndagine="ngForm" (ngSubmit)="onSubmitFormIndagine(formIndagine)" enctype="multipart/form-data">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
        <div *ngIf="indagineSelected.id; then thenBlockModifica else elseBlockAggiungi"></div>
        <ng-template #thenBlockModifica>Modifica indagine {{ indagineSelected.id }}</ng-template>
        <ng-template #elseBlockAggiungi>
          Aggiungi indagine <!-- {{ tipo }} -->
        </ng-template>
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">

          <input hidden type="text" class="form-control" name="idIndagine" #fieldIdIndagine="ngModel" [(ngModel)]="idIndagine">

          <div class="col-12 col-md">
            <label for="filter_classe_indagine">Classe di indagine</label>
            <select class="form-control chosen" id="filter_classe_indagine" name="filter_classe_indagine" placeholder="Inizia a scrivere"  #fieldClasseIndagine="ngModel" [(ngModel)]="indagineSelected.classeInd"
                    [ngClass]="{ 'is-invalid': (submittedIndagine && fieldClasseIndagine.invalid)}" required [disabled]="indagineSelected.id">
<!--              <option value="">Tutte</option>-->
              <option [value]="item.sigClasseIndagine" *ngFor="let item of classeIndagine">{{ item.sigClasseIndagine }} - {{ item.desClasseIndagine }}</option>
            </select>
          </div>
          <div class="col-12 col-md">
            <label for="filter_tipo_indagine">Tipo di indagine</label>
            <select class="form-control chosen" id="filter_tipo_indagine" name="filter_tipo_indagine" placeholder="Inizia a scrivere"  #fieldTipoIndagine="ngModel" [(ngModel)]="indagineSelected.tipoInd"
                    [ngClass]="{ 'is-invalid': (submittedIndagine && fieldTipoIndagine.invalid)}" required [disabled]="indagineSelected.id">
<!--              <option value="">Tutti</option>-->

              <ng-container *ngFor="let item of tipoIndagine">
<!--
                <ng-template [ngIf]="(campoClasseIndagine == '' || campoClasseIndagine == item.classe) && ((tipo == 'lineari' && item.tipoIndagine == 'lineare') || (tipo == 'puntuali' && item.tipoIndagine != 'lineare'))">
-->
                <ng-template [ngIf]="(fieldClasseIndagine.value == '' || fieldClasseIndagine.value == item.classe)">
                  <option [value]="item.sigTipoIndagine" [attr.tipoIndagine]="item.tipoIndagine">{{ item.sigTipoIndagine }} - {{ item.desTipoIndagine }}</option>
                </ng-template>
              </ng-container>
            </select>
          </div>

          <div class="col-12 col-md custom-file">
            <label>Documento pdf *</label>
            <div class="custom-file">
              <label class="custom-file-label form-control" for="fileIndagine" [ngClass]="{ 'is-invalid': (submittedIndagine && (indagineSelected.labelDocumentoIndagine == null ) && (!indagineSelected.documento || (indagineSelected.documento).length == 0) )}" >{{ indagineSelected.labelDocumentoIndagine }}</label>
              <input accept="application/pdf" type="file" class="form-control custom-file-input" id="fileIndagine" name="fileIndagine"  (change)="handleFileIndagine($event.target.files)" ngModel [required]="!indagineSelected.documento || (indagineSelected.documento).length == 0">
            </div>
          </div>

          <div class="col-12 col-md">
            <div class="row">
                <br><br>
            </div>
            <ng-template [ngIf]="indagineSelected.documento && (indagineSelected.documento).length > 0">
              <div class="row">
                <div class="col-12 col-md">
                    <a href="javascript: void(0)" (click)="leggiFileById(indagineSelected.documento[0].id)">{{ indagineSelected.documento[0].name }}</a>
                </div>
              </div>
            </ng-template>
          </div>
      </div>



      <ng-template [ngIf]="(parametriIndagineSigle).indexOf(fieldTipoIndagine.value) > -1">

          <ng-container *ngFor="let item of arrayParametri; let i = index" [ngModelGroup]="'parametro_' + i">
              <ng-template [ngIf]="item">
                <div class="row mt-4">

                <!-- ################### PARAMETRI ################### -->
                <div class="col-12 col-md-4">
                  <label>Parametro *</label>
                  <select class="form-control chosen" name="parametroTipo" placeholder="Inizia a scrivere"  #fieldParametroIndagine="ngModel" [(ngModel)]="item.parametroTipo"
                          [ngClass]="{ 'is-invalid': (submittedIndagine && fieldParametroIndagine.invalid)}" required>
                    <option value="">Tutti</option>
                    <ng-container *ngFor="let item of parametriIndagine">
                      <ng-template [ngIf]="((item.tipo).split('-')).indexOf(indagineSelected.tipoInd) > -1">
                        <option [value]="item.tipoPar">{{ item.descrPar }} | {{ item.par }} | {{ item.tipoPar }} | {{ item.uMisura }} | {{ item.valMin }} / {{ item.valMax }}</option>
                      </ng-template>
                    </ng-container>
                  </select>
                </div>
                <div class="col-12 col-md">
                  <div class="form-group">
                    <label>Valore *</label>
                    <input type="number" class="form-control" name="parametroValore" #fieldValore="ngModel" [(ngModel)]="item.parametroValore"
                           [ngClass]="{ 'is-invalid': (submittedIndagine && fieldValore.invalid)}" required>
                  </div>
                </div>
                <div class="col-12 col-md">
                  <div class="form-group">
                    <label>Prof TOP</label>
                    <input type="number" class="form-control" name="parametroProfTop" #fieldProfTop="ngModel" [(ngModel)]="item.parametroProfTop">
                  </div>
                </div>
                <div class="col-12 col-md">
                  <div class="form-group">
                    <label>Prof BOT</label>
                    <input type="number" class="form-control" name="parametroProfBot" #fieldProfBot="ngModel" [(ngModel)]="item.parametroProfBot">
                  </div>
                </div>
                <div class="col-12 col-md">
                  <div class="form-group">
                    <label>Spessore</label>
                    <input type="number" class="form-control" name="parametroSpessore" #fieldSpessore="ngModel" [(ngModel)]="item.parametroSpessore">
                  </div>
                </div>
                <div class="col-12 col-md">
                  <div class="form-group">
                    <label>Quota SLM Top</label>
                    <input type="number" class="form-control" name="parametroQuotaSlmTop" #fieldQuotaSlmTop="ngModel" [(ngModel)]="item.parametroQuotaSlmTop">
                  </div>
                </div>
                <div class="col-12 col-md">
                  <div class="form-group">
                    <label>Quota SLM Bot</label>
                    <input type="number" class="form-control" name="parametroQuotaSlmBot" #fieldQuotaSlmBot="ngModel" [(ngModel)]="item.parametroQuotaSlmBot">
                  </div>
                </div>

                <div class="col-12 col-md-1">
                  <label style="visibility: hidden">-</label>
                  <div *ngIf="(i) > 0; then thenBlockInd else elseBlockInd"></div>
                  <ng-template #thenBlockInd>
                    <button type="button" class="btn btn-primary form-control" (click)="onEliminaParametro(i,formIndagine)">-</button>
                  </ng-template>
                  <ng-template #elseBlockInd>
                    <button type="button" class="btn btn-primary form-control" (click)="onAggiungiParametro(i,formIndagine)">+</button>
                  </ng-template>
                </div>



                <div *ngIf="item.parametroTipo == 'L' || item.parametroTipo == 'LID'; then thenBlockStratigrafia"></div>
                <ng-template #thenBlockStratigrafia>
                    <!-- ################### STRATIGRAFIA ################### -->
                    <div class="stratigrafia w-100">
                      <div class="col-12 col-md-12">
                        <h5>Stratigrafia</h5>
                      </div>
                      <div class="col-12 col-md-12">
                        <table class="table">
                          <thead>
                          <tr>
                            <th>Profondità da (m)</th>
                            <th>Profondità a (m)</th>
                            <th>Spessore (m)</th>
                            <th>Sigla CARG</th>
                            <th>Descrizione Litologica</th>
                            <th> </th>
                          </tr>
                          </thead>
                          <tbody>

                          <ng-container *ngFor="let itemStrat of item.stratigrafia; let k = index" [ngModelGroup]="'stratigrafia_' + k">
                            <ng-template [ngIf]="itemStrat">
                              <tr>
                                <td>
                                   <input type="number" class="form-control form-control-sm" name="stratigrafiaProfonditaDa" #profonditaDa="ngModel" [(ngModel)]="itemStrat.profonditaDa"
                                            [ngClass]="{ 'is-invalid': (submittedIndagine && profonditaDa.invalid)}" required>
                                </td>
                                <td>
                                   <input type="number" class="form-control form-control-sm" name="stratigrafiaProfonditaA" #profonditaA="ngModel" [(ngModel)]="itemStrat.profonditaA"
                                            [ngClass]="{ 'is-invalid': (submittedIndagine && profonditaA.invalid)}" required>
                                </td>
                                <td>
                                   <input type="number" class="form-control form-control-sm" name="stratigrafiaSpessore" #spessore="ngModel" [(ngModel)]="itemStrat.spessore"
                                            [ngClass]="{ 'is-invalid': (submittedIndagine && spessore.invalid)}" required>
                                </td>
                                <td>
                                   <input type="text" class="form-control form-control-sm" name="stratigrafiaSiglaCarg" #siglaCarg="ngModel" [(ngModel)]="itemStrat.siglaCarg"
                                            [ngClass]="{ 'is-invalid': (submittedIndagine && siglaCarg.invalid)}" required>
                                </td>
                                <td>
                                  <select class="form-control form-control-sm chosen" name="stratigrafiaLitologica" placeholder="Inizia a scrivere"  #litologica="ngModel" [(ngModel)]="itemStrat.litologica"
                                          [ngClass]="{ 'is-invalid': (submittedIndagine && litologica.invalid)}" required>
<!--                                      <option value="">Seleziona</option>-->
                                      <option [selected]="litologica.value == 'Terreni contenenti resti di attività antropica RI 0,0,0,100'" >Terreni contenenti resti di attività antropica RI 0,0,0,100</option>
                                      <option>Ghiaie pulite con granulometria ben assortita, miscela di ghiaia e sabbie GW 0,0,0,100</option>
                                      <option>Ghiaie pulite con granulometria poco assortita, miscela di ghiaia e sabbia GP 0,0,0,100</option>
                                      <option>Ghiaie limose, miscela di ghiaia, sabbia e limo GM 0,0,0,100</option>
                                      <option>Ghiaie argillose, miscela di ghiaia, sabbia e argilla GC 0,0,0,100</option>
                                      <option>Sabbie pulite e ben assortite, sabbie ghiaiose SW 0,0,0,100</option>
                                      <option>Sabbie pulite con granulometria poco assortita SP 0,0,0,100</option>
                                      <option>Sabbie limose, miscela di sabbia e limo SM 0,0,0,100</option>
                                      <option>Sabbie argillose, miscela di sabbia e argilla SC 0,0,0,100</option>
                                      <option>Limi organici, argille limose organiche di bassa plasticità OL 0,0,0,100</option>
                                      <option>Argille organiche di medio-alta plasticità, limi organici OH 0,0,0,100</option>
                                      <option>Limi inorganici, sabbie fini, limi micacei o diatomicei MH 0,0,0,100</option>
                                      <option>Limi inorganici, farina di roccia, Sabbie fini limose o argillose, limi argillosi di bassa plasticità ML 0,0,0,100</option>
                                      <option>Argille inorganiche di medio-bassa plasticità, Argille ghiaiose o sabbiose, argille limose, argille magre CL 0,0,0,100</option>
                                      <option>Argille inorganiche di alta plasticità, argille grasse CH 0,0,0,100</option>
                                      <option>Torbe ed altre terre fortemente organiche PT 0,0,0,100</option>
                                      <option>Substrato geologico lapideo LP 0,0,0,100</option>
                                      <option>Substrato geologico granulare cementato GR 0,0,0,100</option>
                                      <option>Substrato geologico coesivo sovraconsolidato CO 0,0,0,100</option>
                                      <option>Substrato geologico alternanza di litotipi AL 0,0,0,100</option>
                                      <option>Substrato geologico lapideo stratificato LPS 0,0,0,100</option>
                                      <option>Substrato geologico granulare cementato stratificato GRS 0,0,0,100</option>
                                      <option>Substrato geologico coesivo sovraconsolidato stratificato COS 0,0,0,100</option>
                                      <option>Substrato geologico alternanza di litotipi stratificato ALS 0,0,0,100</option>
                                      <option>Substrato geologico lapideo fratturato/alterato SFLP 0,0,0,100</option>
                                      <option>Substrato geologico granulare cementato fratturato/alterato SFGR 0,0,0,100</option>
                                      <option>Substrato geologico coesivo sovraconsolidato fratturato/alterato SFCO 0,0,0,100</option>
                                      <option>Substrato geologico alternanza di litotipi fratturato/alterato SFAL 0,0,0,100</option>
                                      <option>Substrato geologico lapideo stratificato fratturato/alterato SFLPS 0,0,0,100</option>
                                      <option>Substrato geologico granulare cementato fratturato/alterato stratificato SFGRS 0,0,0,100</option>
                                      <option>Substrato geologico coesivo sovraconsolidato fratturato/alterato stratificato SFCOS 0,0,0,100</option>
                                      <option>Substrato geologico alternanza di litotipi fratturato/alterato stratificato SFALS 0,0,0,100</option>
                                  </select>

                                </td>
                                <td>
                                    <div *ngIf="(k) > 0; then thenBlockStrat else elseBlockStrat"></div>
                                    <ng-template #thenBlockStrat>
                                      <button type="button" class="btn btn-primary form-control form-control-sm" (click)="onEliminaStratigrafia(k,i,formIndagine)">-</button>
                                    </ng-template>
                                    <ng-template #elseBlockStrat>
                                      <button type="button" class="btn btn-primary form-control form-control-sm" (click)="onAggiungiStratigrafia(k,i,formIndagine)">+</button>
                                    </ng-template>
                                </td>
                              </tr>
                            </ng-template>
                          </ng-container>
                          </tbody>
                        </table>
                      </div>
                    </div>
                </ng-template>

                </div>

                <hr style="height:2px;border-width:0;color:gray;background-color:gray">
              </ng-template>
           </ng-container>

      </ng-template>


    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">Annulla</button>

      <div *ngIf="indagineSelected.id; then thenBlock else elseBlock"></div>
      <ng-template #thenBlock>
        <button type="submit" class="btn btn-success">Modifica</button>
      </ng-template>

      <ng-template #elseBlock>
        <button type="submit" class="btn btn-success">Aggiungi</button>
      </ng-template>
    </div>
  </form>
</ng-template>




<!-- ############################# MODAL DELETE ANAGRAFICA ############################# -->
<ng-template #modalDeleteIndagine>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Elimina indagine</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Sei sicuro di voler eliminare l'indagine <strong>{{bsModalRef.content.id}}</strong>? <br>
      Verranno eliminati anche tutti i suoi parametri e stratigrafie collegate.
    </p>

  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">Annulla</button>
    <button type="button" class="btn btn-danger" (click)="deleteIndagine(bsModalRef.content.id)">Elimina</button>
  </div>
</ng-template>



<!-- ############################# MODAL INDAGINE ESISTENTE ############################# -->
<ng-template #modalIndagineEsistente>
  <form id="formIndagineEsistente" #formIndagineEsistente="ngForm" (ngSubmit)="onSubmitFormIndagineEsistente(formIndagineEsistente)">
    <div class="modal-header">
      <h4 class="modal-title pull-left">
          Aggiungi indagine
      </h4>
      <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div class="row">
        <div class="col-12 col-md-6">
            <p>
              Tramite questa sezione puoi utilizzare indagini già presenti dal sistema inserite da altri professionisti.
              Se conosci il codige GIS puoi inserirlo nell'appositocampo qui sotto.
              Altrimenti puoi navigare la mappa ed individuarlo.
            </p>

            <div class="form-check">
              <input class="form-check-input" type="checkbox" id="autorizzo" name="autorizzo"  #campoAutorizzo="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submittedIndagineEsistente && campoAutorizzo.invalid)}" required>
              <label class="form-check-label" for="autorizzo">
                Dichiaro di essere autorizzato ad utilizzare le indagini
              </label>
            </div>
            <div class="alert alert-danger mt-2 d-none" role="alert" [ngClass]="{ 'd-block': (submittedIndagineEsistente && campoAutorizzo.invalid)}">
              Accettare le condizioni!
            </div>

            <hr>
            <ng-select placeholder="Inserisci l'ID GIS per trovare le indagini" class="mt-4" name="gis"  #gis="ngModel" [(ngModel)]="gisDefault" (change)="onChangeGis()" >
              <ng-option *ngFor="let i of sitiGis" [value]="i">{{ i }}</ng-option>
            </ng-select>
            <hr>

            <div id="list" class="row">
              <div class="col">
                <div class="table-responsive-sm">
                  <table class="table table-bordered text-left">
                    <thead>
                    <tr>
                      <th scope="col" class="text-center" style="width:3%">
                        #ID <a href="" class="sortById desc"><i class="fas fa-caret-down"></i></a>
                      </th>
                      <th scope="col">
                        Classe indagine
                      </th>
                      <th scope="col">
                        Tipologia indagine
                      </th>
                      <th scope="col">
                        Numero parametri
                      </th>
                      <th scope="col">
                        Data creazione
                      </th>

                      <th scope="col" class="text-center">
                        Azioni
                      </th>
                    </tr>
                    </thead>
                    <tbody>

                    <tr *ngFor="let item of indaginiGis"  [ngClass]="(item.gis == gis.value) ? '' : 'd-none'">
                          <td scope="row" class="text-center row-id">
                            {{ item.idIndagine }}
                          </td>
                          <td class="title">
                            {{ item.classeInd }} - {{ item.classeIndTitle }}
                          </td>
                          <td>
                            {{ item.tipoInd }} - {{ item.tipoIndTitle }}
                          </td>
                          <td>
                            {{ item.numParametri }}
                          </td>
                          <td>
                            <strong>{{ item.dataInd }}</strong>
                          </td>
                          <td>
                            <div class="form-check">
                              <input class="form-check-input" type="checkbox" id="ind_{{item.tipoIndagine}}_{{item.idIndagine}}" name="ind"  #campoIndagini="ngModel"  [(ngModel)]="defaultCheckboxIndagine[item.tipoIndagine + item.idIndagine]"  (change)="onChangeCheckboxIndagini($event, item.tipoIndagine, item.idIndagine)"
                                     [ngClass]="{ 'is-invalid': (submittedIndagineEsistente && campoIndagini.invalid)}" required>
                            </div>
                          </td>
                    </tr>

                    </tbody>
                  </table>

                  <div class="alert alert-danger mt-2 d-none" role="alert" [ngClass]="{ 'd-block': (submittedIndagineEsistente && (indaginiSelezionate | json) == '{}')}">
                    Seleziona almeno un'indagine!
                  </div>

                </div>
              </div>
            </div>


        </div>
        <div class="col-12 col-md-6">
          <div id="mapIndagini" style="min-height: 400px"
               leaflet
               [leafletOptions]="optionMapIndagini"
               [leafletLayers]="arrayMarkerIndagini"
               [leafletFitBounds]="markerBoundsIndagini"
               (leafletMapReady)="onMapIndaginiReady($event)"
          >
          </div>
        </div>
      </div>
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">Annulla</button>
      <button type="submit" class="btn btn-success">Aggiungi</button>
    </div>

  </form>
</ng-template>
