import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getFromObject',
  pure: false
})
export class GetFromObjectPipe implements PipeTransform {

  transform(value: any, parametro: string): any {
    if (value != undefined) {
      return value[parametro];
    }
    return;
  }

}
