import {Component, OnInit, TemplateRef} from '@angular/core';
import {GlobalConstants} from "../global-constants";
import {LocalStorageService, SessionStorageService, LocalStorage, SessionStorage} from 'angular-web-storage';
import {HttpClient} from "@angular/common/http";
import {DataService} from "../service/data.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {Router} from "@angular/router";

@Component({
  selector: 'app-registration',
  templateUrl: './registration.component.html',
  styleUrls: ['./registration.component.less']
})
export class RegistrationComponent implements OnInit {

  constructor(private httpClient: HttpClient, private local: LocalStorageService, private dataService: DataService, private modalService: BsModalService, private toastr: ToastrService, private router: Router) { }

  name: string = "";
  lastname: string = "";
  submitted = false;

  provinceTemp;
  comuniProvince;
  comuniProvinceStudio;
  province;
  condizioniValue = false;
  bsModalRef: BsModalRef; //oggetto della modal

  ngOnInit(): void {

    this.provinceTemp = this.local.get("province");
    if (this.provinceTemp == null) {
      console.log("api NON in localStorage, fare la chiamata");
      this.httpClient.get(GlobalConstants.API_PROVINCE).subscribe((risposta: any) => {
        this.province = risposta;
        this.local.set("province", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
      });
    } else {
      console.log("api presa da localStorage");
      this.province = this.local.get("province"); //prendo l'api dal localStorage
    }
    console.log("province", this.province);
  }

  onChangeProvincia(provincia): any {
    this.dataService.getComuniProvincia(provincia)
      .subscribe(risposta => {
        console.log("comuni provincia", risposta);
        this.comuniProvince = risposta;
      });
  }  onChangeProvinciaStudio(provinciaStudio): any {
    this.dataService.getComuniProvincia(provinciaStudio)
      .subscribe(risposta => {
        console.log("comuni provinciaStudio", risposta);
        this.comuniProvinceStudio = risposta;
      });
  }


  onSubmit(formRegistration): any {
    this.submitted = true; // abbiamo fatto un submit, serve per la validazione
    console.log(formRegistration.invalid);
    console.log(formRegistration);
    if (formRegistration.invalid || (formRegistration.form.value.password != formRegistration.form.value.confermaPassword)) { // stop here if formContatti is invalid
      return;
    }

    var valori = formRegistration.form.value; //prendo i valori inseriti nella form
    console.log("valori form", valori);


    this.dataService.userRegistration(valori).subscribe((risposta: any) => {
      console.log("risposta registrazione", Object.values(risposta));

      if (risposta.stato == "esistente") {
        this.toastr.error( GlobalConstants.ERRORE_REGISTRAZIONE_EXIST_TESTO.replace("{username}",risposta.username), GlobalConstants.ERRORE_REGISTRAZIONE_EXIST_TITOLO);
        return;
      }

      this.toastr.success( GlobalConstants.SUCCESS_REGISTRAZIONE_TESTO.replace("{username}",risposta.username), GlobalConstants.SUCCESS_REGISTRAZIONE_TITOLO);
      this.router.navigate(['/login']); //redirect (alla login?)
    });
  }


  openModalCondizioniCheckbox(modalCondizioni: TemplateRef<any>) {
    this.bsModalRef = this.modalService.show(modalCondizioni, {class: 'modal-lg'}); //apre la modal
  }
  accettoCondizioni(): any {
    this.condizioniValue = true;
    this.bsModalRef.hide(); //chiudo la modal dei contatti
    return;
  }
}
