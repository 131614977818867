import { Component, OnInit } from '@angular/core';
import {DataService} from '../service/data.service';
import {LocalStorageService, SessionStorageService, LocalStorage, SessionStorage} from 'angular-web-storage';
import {ActivatedRoute, Router} from '@angular/router';
import jwt_decode from "jwt-decode";
import {GlobalConstants} from "../global-constants";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.less']
})
export class LoginComponent implements OnInit {

  constructor(private dataService: DataService, private local: LocalStorageService, private router: Router, private route: ActivatedRoute, private toastr: ToastrService) { }

  usernameUtente: string = "";
  passwordUtente: string = "";
  submitted = false;
  confirmCode;


  ngOnInit(): void {

    this.route.queryParams.subscribe(params => {
      //console.log('confirm', params);
      if (params.confirm == 'ok') {
        this.toastr.success( GlobalConstants.SUCCESS_CONFIRM_REGISTRATION_TESTO, GlobalConstants.SUCCESS_CONFIRM_REGISTRATION_TITOLO);
      }
    });

    localStorage.clear();
  }

  onSubmit(form) { //al submit
    this.submitted = true; // abbiamo fatto un submit, serve per la validazione
    if (form.invalid) { // stop here if form is invalid
      return;
    }

    this.dataService.loginCheck(form.value).subscribe(risposta => {
      //console.log("response", risposta);
      this.local.set("jwtGA", risposta, 6, 'h');

      if (this.local.get("jwtGA").attivazioneGA == 0) {
        this.router.navigate(['attivazioneGA']);
      }
      if (this.local.get("jwtGA").attivazioneGA == 1) {
        this.router.navigate(['verificaGA']);
      }


/*
      //CODICE RIMANDATO AL COMPONENTE di verifica google authenticator


      var tok = this.local.get("jwt").token;
      var decoded = jwt_decode(tok);// decodifica il token jwt per prendere le sue info

      if (decoded['isActive'] == 1) {
        this.local.set("jwt", risposta, 6, 'h');

        this.local.set("userLogin", decoded, 6, 'h');

        this.dataService.changeDecodedJwt(decoded); //cambiamo la variabile con servizio perchè serve al component header

        this.router.navigate(['/siti']); //redirect (alla login?)
      } else {
        this.local.clear();
        this.toastr.error( GlobalConstants.ERROR_NO_ACTIVE_TESTO.replace("{username}",decoded['username']), GlobalConstants.ERROR_NO_ACTIVE_TITOLO);
      }

      this.local.set('storico', null);
*/

    })

  }

}
