<div ngxUiLoaderBlurred>
    <!-- chiamo il component header -->
    <app-header></app-header>

    <!-- i component delle route -->
    <router-outlet></router-outlet>

    <!-- fare eventualmente un component per il footer -->
    <section id="footer" class="mt-5 mb-5">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-12">

                </div>
            </div>
        </div>
    </section>
</div>

<!-- il loader -->
<ngx-ui-loader></ngx-ui-loader>

