import {icon, tileLayer, latLng, MapOptions} from 'leaflet';
 import {NgxUiLoaderConfig} from 'ngx-ui-loader';

export class GlobalConstants {

    //######## API

    public static liveSite: string = "https://geologiback.mister-wolf.it/public";
    //public static liveSite: string = "https://protezionecivile.regione.abruzzo.it/geologi/back/public";
    public static apiPath: string = "";

    public static API_COMUNI_BIG = GlobalConstants.liveSite + GlobalConstants.apiPath + "/comuniBig";
    public static API_COMUNI = GlobalConstants.liveSite + GlobalConstants.apiPath + "/comuni";
    public static API_PROVINCE = GlobalConstants.liveSite + GlobalConstants.apiPath + "/province";
    public static API_COMUNI_PROVINCE = GlobalConstants.liveSite + GlobalConstants.apiPath + "/comuniProvince";
    public static API_DES_CLASSE_INDAGINE = GlobalConstants.liveSite + GlobalConstants.apiPath + "/des_classe_indagine";
    public static API_DES_TIPO_INDAGINE = GlobalConstants.liveSite + GlobalConstants.apiPath + "/des_tipo_indagine";
    public static API_DES_PARAMETRI_INDAGINE = GlobalConstants.liveSite + GlobalConstants.apiPath + "/des_parametri_indagine";
    public static API_DES_TIPO_COORD = GlobalConstants.liveSite + GlobalConstants.apiPath + "/des_tipo_coor";
    public static API_SITI_LINEARI = GlobalConstants.liveSite + GlobalConstants.apiPath + "/siti/lineari";
    public static API_SITI_PUNTUALI = GlobalConstants.liveSite + GlobalConstants.apiPath + "/siti/puntuali";
    public static API_SITI = GlobalConstants.liveSite + GlobalConstants.apiPath + "/siti";
    public static API_UTENTI = GlobalConstants.liveSite + GlobalConstants.apiPath + "/api/utenti";
    public static API_INDAGINI_SITI = GlobalConstants.liveSite + GlobalConstants.apiPath + "/siti/{tipo}/{idSito}/indagini/{idIndagine}";

    public static API_LOGIN_CHECK = GlobalConstants.liveSite + GlobalConstants.apiPath + "/api/login_check";
    public static API_FILES = GlobalConstants.liveSite + GlobalConstants.apiPath + "/file"; // con /{id} del file
    public static API_UPLOADS = GlobalConstants.liveSite + GlobalConstants.apiPath + "/{apientity}/{id}/uploads/{type}";
    public static API_INVIA_SITI = GlobalConstants.liveSite + GlobalConstants.apiPath + "/invioSiti/";
    public static API_READ_FILE = GlobalConstants.liveSite + GlobalConstants.apiPath + "/api/getFileById/";
    public static API_EXPORT_SITI = GlobalConstants.liveSite + GlobalConstants.apiPath + "/export/siti";
    public static API_READ_ZIP_COMUNE = GlobalConstants.liveSite + GlobalConstants.apiPath + "/api/getFileZipComune/";


    //######## TESTI FRONT

    public static siteTitle: string = "This is example of title";
    public static UPDATE_SITO_TITOLO: string = "Elemento aggiornato!";
    public static UPDATE_SITO_TESTO: string = "<strong>{denominazione}</strong> aggiornato con successo";
    public static CREATE_INDAGINE_TITOLO: string = "Indagine creata!";
    public static CREATE_INDAGINE_TESTO: string = "Indagine <strong>{idIndagine}</strong> creata con successo";
    public static UPDATE_INDAGINE_TITOLO: string = "Indagine aggiornata!";
    public static UPDATE_INDAGINE_TESTO: string = "Indagine <strong>{idIndagine}</strong> aggiornata con successo";
    public static UPDATE_UTENTE_TITOLO: string = "Utente aggiornato!";
    public static UPDATE_UTENTE_TESTO: string = "Utente <strong>{username}</strong> aggiornato con successo";
    public static CREATE_UTENTE_TITOLO: string = "Utente creato!";
    public static CREATE_UTENTE_TESTO: string = "Utente <strong>{username}</strong> creato con successo";

    public static ERRORE_FORMATO_UPLOAD_TITOLO:  string = "Formato non corretto.";
    public static ERRORE_FORMATO_UPLOAD_TESTO:  string = "I file di tipo <strong>{tipoFile}</strong> non sono consentiti";
    public static DELETE_FILES_TITOLO:  string = "File eliminato!";
    public static DELETE_FILES_TESTO:  string = "Il file <strong>{nomeFile}</strong> è stato eliminato.";
    public static DELETE_INDAGINE_TITOLO:  string = "Indagine eliminata!";
    public static DELETE_INDAGINE_TESTO:  string = "L'indagine <strong>{id}</strong> è stata eliminata.";

    public static ERRORE_REGISTRAZIONE_EXIST_TITOLO:  string = "Attenzione!";
    public static ERRORE_REGISTRAZIONE_EXIST_TESTO:  string = "La mail <strong>{username}</strong> è già presente nel sistema. Se non ricordi gli accessi puoi effettuare un <strong><a href='/recuperaPassword'>recupera password</a></strong>";
    public static ERRORE_RECUPERA_PWD_TITOLO:  string = "Attenzione!";
    public static ERRORE_RECUPERA_PWD_TESTO:  string = "La mail <strong>{username}</strong> non è presente nel sistema. Puoi effettuare una <strong><a href='/registration'>registrazione</a></strong>";
    public static SUCCESS_RECUPERA_PWD_TITOLO:  string = "Invio email!";
    public static SUCCESS_RECUPERA_PWD_TESTO:  string = "E' stata inviata una mail con la nuova password a <strong>{username}</strong>";
    public static SUCCESS_CONFIRM_REGISTRATION_TITOLO:  string = "Account attivato!";
    public static SUCCESS_CONFIRM_REGISTRATION_TESTO:  string = "Il tuo account è stato attivato con successo";
    public static ERROR_NO_ACTIVE_TITOLO:  string = "Account non attivo!";
    public static ERROR_NO_ACTIVE_TESTO:  string = "Attenzione, il tuo account risulta non attivo";
    public static SUCCESS_REGISTRAZIONE_TITOLO:  string = "Registrazione eseguita";
    public static SUCCESS_REGISTRAZIONE_TESTO:  string = "E' stata inviata una mail a <strong>{username}</strong>. Segui le istruzioni per confermare l'account";
    public static DELETE_UTENTE_TITOLO:  string = "Utente eliminato!";
    public static DELETE_UTENTE_TESTO:  string = "L'utente <strong>{username}</strong> è stato eliminato";
    public static USERNAME_EXIST_TITOLO:  string = "Attenzione!";
    public static USERNAME_EXIST_TESTO:  string = "La mail <strong>{username}</strong> è già presente nel sistema";

    public static ERROR_CODICE2FATTORI_TITOLO:  string = "Attenzione!";
    public static ERROR_CODICE2FATTORI_TESTO:  string = "Il codice inserito non è valido";
    public static ERROR_ATTIVAZIONE2FATTORI_TITOLO:  string = "Attenzione!";
    public static ERROR_ATTIVAZIONE2FATTORI_TESTO:  string = "Alcuni dati del tuo account non sono validi";
    public static SUCCESS_RESET_2FA_TITOLO:  string = "Autenticazione 2FA reimpostata";
    public static SUCCESS_RESET_2FA_TESTO:  string = "Accedi al sistema per riabilitare l'autenticazione 2FA";
    public static ERROR_CONFERMA_2FA_TITOLO:  string = "Attenzione!";
    public static ERROR_CONFERMA_2FA_TESTO:  string = "Il codice inserito non è valido";
    public static SUCCESS_MAIL_2FA_TITOLO:  string = "Invio email!";
    public static SUCCESS_MAIL_2FA_TESTO:  string = "E' stata inviata una mail con il codice di verifica a <strong>{username}</strong>";

    //######## MAPPA LEAFLET

    public static ICONA_MARKER = icon({
        iconSize: [25, 41],
        iconAnchor: [13, 41],
        iconUrl: 'assets/marker-icon.png',
        shadowUrl: 'assets/marker-shadow.png',
        popupAnchor: [0, -40]
    });
    public static OPTION_MAP : MapOptions= {
        layers: [
            tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {id: 'mapbox/streets-v11', accessToken:"pk.eyJ1IjoibWFyaW9yb3NzaW1hcGJveCIsImEiOiJjbGY4MzJxanMyMmJpM3hyMHlwa25xang3In0.XCiiPdn18U_t0gCe1Dy9kA", maxZoom: 18, attribution: '...' })
        ],
        scrollWheelZoom: false, //disabilita scroll con rotella
        zoom: 10,
        center: latLng(42.35152978245961, 13.39974050291366) //centro l'aquila
    };
    public static OPTION_MAP_B : MapOptions = {
        layers: [
            tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {id: 'mapbox/streets-v11', accessToken:"pk.eyJ1IjoibWFyaW9yb3NzaW1hcGJveCIsImEiOiJjbGY4MzJxanMyMmJpM3hyMHlwa25xang3In0.XCiiPdn18U_t0gCe1Dy9kA", maxZoom: 18, attribution: '...' })
        ],
        scrollWheelZoom: false, //disabilita scroll con rotella
        zoom: 12,
        center: latLng(42.35152978245961, 13.39974050291366) //centro l'aquila
    };
  public static OPTION_MAP_INDAGINI : MapOptions= {
    layers: [
      tileLayer('https://api.mapbox.com/styles/v1/{id}/tiles/{z}/{x}/{y}?access_token={accessToken}', {id: 'mapbox/streets-v11', accessToken:"pk.eyJ1IjoibWFyaW9yb3NzaW1hcGJveCIsImEiOiJjbGY4MzJxanMyMmJpM3hyMHlwa25xang3In0.XCiiPdn18U_t0gCe1Dy9kA", maxZoom: 18, attribution: '...' })
    ],
    scrollWheelZoom: false, //disabilita scroll con rotella
    zoom: 10,
    center: latLng(42.35152978245961, 13.39974050291366) //centro l'aquila
  };


    //per personalizzare il loader vedere qui: https://tdev.app/ngx-ui-loader/demo/
    public static ngxUiLoaderConfig : NgxUiLoaderConfig= {
      bgsColor: '#2a4065',
      bgsOpacity: 0.5,
      bgsPosition: 'bottom-right',
      bgsSize: 60,
      bgsType: 'circle',
      blur: 3,
      delay: 0,
      fastFadeOut: true,
      fgsColor: '#2a4065',
      fgsPosition: 'center-center',
      fgsSize: 90,
      fgsType: 'circle',
      gap: 24,
      logoPosition: 'center-center',
      logoSize: 120,
      // logoUrl: 'assets/angular.png',
      masterLoaderId: 'master',
      overlayBorderRadius: '0',
      overlayColor: 'rgba(40, 40, 40, 0.6)',
      pbColor: 'red',
      pbDirection: 'ltr',
      pbThickness: 5,
      hasProgressBar: true,
      text: '',
      textColor: '#FFFFFF',
      textPosition: 'center-center',
      maxTime: -1,
      minTime: 0
    };



}
