<div class="container-fluid mt-3">

  <form id="formUtente" class="form" #formUtente="ngForm" (ngSubmit)="onSubmit(formUtente)">
    <div class="card text-left">
      <div class="card-header">
        DATI PERSONALI
      </div>
      <div class="card-body">

        <div class="form-row mt-4">
          <div class="col-md">
            <label>Provincia *</label>
            <ng-select class="" name="provincia" #provincia="ngModel" [(ngModel)]="utente.provincia" [clearable]="false"  (change)="onChangeProvincia(provincia.value)" [ngClass]="{ 'is-invalid': (submitted && provincia.invalid )}" required>
              <ng-option  *ngFor="let item of province" value="{{ item.provincia }}">{{ item.provincia }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md">
            <label for="cap">Comune *</label>
            <ng-select class="" name="comune" #comune="ngModel" [(ngModel)]="utente.comune" [clearable]="false"  [ngClass]="{ 'is-invalid': (submitted && comune.invalid )}" required [disabled]="provincia.invalid">
              <ng-option  *ngFor="let item of comuniProvince" value="{{ item.comune }}">{{ item.comune }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md">
            <label for="cap">CAP *</label>
            <input type="text" class="form-control" id="cap" name="cap" #cap="ngModel" maxlength="5" [(ngModel)]="utente.cap" [ngClass]="{ 'is-invalid': (submitted && cap.invalid )}" required>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="col-md-8">
            <label for="indirizzo">Indirizzo *</label>
            <input type="text" class="form-control required" id="indirizzo" name="indirizzo" #indirizzo="ngModel" [(ngModel)]="utente.indirizzo" [ngClass]="{ 'is-invalid': (submitted && indirizzo.invalid )}" required>
          </div>
          <div class="col-md">
            <label for="telefono">Telefono *</label>
            <input type="text" class="form-control" id="telefono" name="telefono" #telefono="ngModel" [(ngModel)]="utente.telefono" [ngClass]="{ 'is-invalid': (submitted && telefono.invalid )}" required>
          </div>
        </div>
        <hr>
        <div class="form-row">
          <div class="col-md">
            <label>Codice Fiscale *</label>
            <input type="text" class="form-control" name="cf" #cf="ngModel" [(ngModel)]="utente.cf" [ngClass]="{ 'is-invalid': (submitted && cf.invalid )}" required>
          </div>
          <div class="col-md">
            <label>Partita I.V.A.</label>
            <input type="text" class="form-control" name="pi" #pi="ngModel" [(ngModel)]="utente.pi">
          </div>
          <div class="col-md">
            <label>Regione dell'Ordine Professionale *</label>
            <input type="text" class="form-control" name="ordine" #ordine="ngModel" [(ngModel)]="utente.ordine" [ngClass]="{ 'is-invalid': (submitted && ordine.invalid )}" required>
          </div>
          <div class="col-md">
            <label>N. iscrizione Ordine Professionale *</label>
            <input type="text" class="form-control" name="num_iscrizione" #num_iscrizione="ngModel" [(ngModel)]="utente.num_iscrizione" [ngClass]="{ 'is-invalid': (submitted && num_iscrizione.invalid )}" required>
          </div>
        </div>
      </div>
    </div>


    <div class="card text-left mt-3 box-studio">
      <div class="card-header">
        DATI STUDIO PROFESSIONALE
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md">
            <label>Nome studio</label>
            <input type="text" class="form-control" name="studioSede" #studioSede="ngModel" [(ngModel)]="utente.studioSede" [ngClass]="{ 'is-invalid': (submitted && studioSede.invalid)}" >
          </div>
        </div>
        <div class="form-row mt-4">

          <div class="col-md">
            <label>Provincia</label>
            <ng-select class="" name="provinciaStudio" #provinciaStudio="ngModel" [(ngModel)]="utente.studioProvincia" [clearable]="true"  (change)="onChangeProvinciaStudio(provinciaStudio.value)"
                       [ngClass]="{ 'is-invalid': (submitted && provinciaStudio.invalid)}">
              <ng-option  *ngFor="let item of province" value="{{ item.provincia }}">{{ item.provincia }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md">
            <label for="cap">Comune </label>
            <ng-select class="" name="comuneStudio" #comuneStudio="ngModel" [(ngModel)]="utente.studioComune" [clearable]="true"
                       [ngClass]="{ 'is-invalid': (submitted && comuneStudio.invalid)}" [disabled]="provinciaStudio.invalid || provinciaStudio.value === null">
              <ng-option  *ngFor="let item of comuniProvinceStudio" value="{{ item.comune }}">{{ item.comune }}</ng-option>
            </ng-select>
          </div>
          <div class="col-md">
            <label>CAP </label>
            <input type="text" class="form-control" name="studioCap" #studioCap="ngModel" [(ngModel)]="utente.studioCap"
                   [ngClass]="{ 'is-invalid': (submitted && studioCap.invalid) }">

          </div>
        </div>
        <div class="form-row mt-4">
          <div class="col-md">
            <label>Indirizzo </label>
            <input type="text" class="form-control" name="studioIndirizzo" #studioIndirizzo="ngModel" [(ngModel)]="utente.studioIndirizzo"
                   [ngClass]="{ 'is-invalid': (submitted && studioIndirizzo.invalid)}" >
          </div>
        </div>
      </div>
    </div>
    <div class="card text-left mt-4">
      <div class="card-header">
        DATI DI ACCESSO
      </div>
      <div class="card-body">
        <div class="form-row">
          <div class="col-md">
            <label for="name">Nome *</label>
            <input type="text" class="form-control" id="name" name="name" #name="ngModel" [(ngModel)]="utente.nome" [ngClass]="{ 'is-invalid': (submitted && name.invalid )}" required>
          </div>
          <div class="col-md">
            <label for="lastname">Cognome *</label>
            <input type="text" class="form-control" id="lastname" name="lastname" #lastname="ngModel" [(ngModel)]="utente.cognome" [ngClass]="{ 'is-invalid': (submitted && lastname.invalid )}" required>
          </div>
          <div class="col-md">
            <label>Ordine professionale *</label>
            <ng-select class="" name="tipoUtente" #tipoUtente="ngModel" [(ngModel)]="utente.tipoUtente" [clearable]="false" [ngClass]="{ 'is-invalid': (submitted && tipoUtente.invalid )}" required>
              <ng-option value="geologi">Geologi</ng-option>
              <ng-option value="ingegneri">Ingegneri</ng-option>
              <ng-option value="architetti">Architetti</ng-option>
            </ng-select>
          </div>
          <div class="col-md" [hidden]="decodedJwt.roles['role'] == 'ROLE_USER'">
            <label>Gruppo * </label>
            <ng-select class="" name="gruppo" #gruppo="ngModel" [(ngModel)]="utente.gruppo.role" [clearable]="false" [ngClass]="{ 'is-invalid': (submitted && gruppo.invalid )}" required>
              <ng-option value="ROLE_USER">Utente</ng-option>
              <ng-option value="ROLE_ADMIN">Amministratore</ng-option>
            </ng-select>
          </div>
        </div>
        <div class="form-row mt-4">
          <div class="col-md">
            <label>E-mail * </label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><fa-icon [icon]="['fas', 'at']"></fa-icon></div>
              </div>
              <input type="email" class="form-control" name="username" #username="ngModel" [(ngModel)]="utente.username" [ngClass]="{ 'is-invalid': (submitted && username.invalid )}" required>
            </div>
          </div>
          <div class="col-md">
            <label>Password * </label>
            <div class="input-group mb-2">
                <div class="input-group-prepend">
                  <div class="input-group-text"><fa-icon [icon]="['fas', 'lock']"></fa-icon></div>
                </div>
                <input type="password" class="form-control" name="password" #password="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && password.invalid || (submitted && password.value != confermaPassword.value) || (submitted && utente.id == null))}" [required]="utente.id == null" >
            </div>
          </div>
          <div class="col-md">
            <label>Conferma password * </label>
            <div class="input-group mb-2">
              <div class="input-group-prepend">
                <div class="input-group-text"><fa-icon [icon]="['fas', 'lock']"></fa-icon></div>
              </div>
              <input type="password" class="form-control" name="confermaPassword" #confermaPassword="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && confermaPassword.invalid || (submitted && password.value != confermaPassword.value) || (submitted && utente.id == null))}" [required]="utente.id == null">
            </div>
          </div>
          <div class="col-md ml-4" [hidden]="decodedJwt.roles['role'] == 'ROLE_USER'">
            <div class="checkbox">
              <label>Stato</label>
              <div class="input-group mt-2 mb-2">
                <label>
                  <input type="checkbox" name="isActive" #isActive="ngModel" [(ngModel)]="utente.isActive"> Attivo
                </label>
              </div>
            </div>
          </div>
        </div>

      </div>
    </div>

    <hr>

    <button class="btn btn-success registrati">SALVA</button>
  </form>

</div>
