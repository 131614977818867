import { Component, OnInit } from '@angular/core';
import jwt_decode from "jwt-decode";
import {DataService} from "../../service/data.service";
import {GlobalConstants} from "../../global-constants";
import {LocalStorageService} from "angular-web-storage";
import {HttpClient} from "@angular/common/http";
import {Router} from "@angular/router";

@Component({
  selector: 'app-lista-storico',
  templateUrl: './lista-storico.component.html',
  styleUrls: ['./lista-storico.component.less']
})

export class ListaStoricoComponent implements OnInit {

  constructor(private dataService: DataService, private local: LocalStorageService, private httpClient: HttpClient, public router: Router) { }

  storicoList = [];
  storicoComuniList = [];
  logged = false;
  decodedJwt = null;
  filtroCerca = "";
  currentPage = 1;



  ngOnInit(): void {

    if (this.dataService.isAuthenticated()) {
      this.logged = true;
      if (this.decodedJwt != null) {
        this.decodedJwt = jwt_decode(this.decodedJwt);
      } else {
        var tok = localStorage.getItem('jwt');
        this.decodedJwt = jwt_decode(tok);// decodifica il token jwt per prendere le sue info
      }

      console.log("decodedJwt", this.decodedJwt.userId);
    }




    if (this.local.get('storico') == null) {
      //console.log("api NON in localStorage, fare la chiamata");
      this.dataService.getListaStorico(this.decodedJwt.userId).subscribe((risposta: any) => {
        //console.log(risposta);
        this.storicoList = risposta;

        if (risposta.stato) {
          this.router.navigate(['/siti/'])
            .then(() => {
              window.location.reload();//forza reload
            });
        }


        this.local.set("storico", risposta, 180, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
      });
    } else {
      console.log("api presa da localStorage");
      this.storicoList = this.local.get("storico"); //prendo l'api dal localStorage
    }


    if (this.local.get('storicoComunii') == null) {
      //console.log("api NON in localStorage, fare la chiamata");
      this.dataService.getComuniStorico().subscribe((risposta: any) => {
        console.log("comuni: " + risposta);
        this.storicoComuniList = risposta;
        this.local.set("storicoComunii", risposta, 180, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
      });
    } else {
      console.log("api presa da localStorage");
      this.storicoComuniList = this.local.get("storicoComunii"); //prendo l'api dal localStorage
      console.log("comuni: " + this.storicoComuniList);
    }


    //
    //
    // this.dataService.getListaStorico(this.decodedJwt.userId).subscribe((risposta: any) => {
    //   console.log("risposta", Object.values(risposta));
    //   this.storicoList = Object.values(risposta);
    // });

  }


/*
  // FILTRI
  confrontaFiltro(item, filtroCerca): any {
    if ( (filtroCerca !== '' && filtroCerca !== null) &&
      (
          (!item.titolo.toLowerCase().includes(filtroCerca.toLowerCase())) && filtroCerca.length >= 3)
       ) {
      return true;
    } else {
      return false;
    }
  }*/


  // FILTRI
  confrontaFiltro(item, filtroCerca, filtroComune): any {
    if (item.comune === filtroComune + '') {
      console.log('AAAAA',item.comune, filtroComune);
    }


    if ( (filtroCerca !== '' && filtroCerca !== null) &&
      (
        (
          (!item.titolo.toLowerCase().includes(filtroCerca.toLowerCase())) &&
          ((!item.username.toLowerCase().includes(filtroCerca.toLowerCase())) && filtroCerca.length >= 3) &&
          (!item.indirizzo.toLowerCase().includes(filtroCerca.toLowerCase()))
        )
      ) ||
      (filtroComune !== null && item.comune !== filtroComune)
    ) {
      return true;
    } else {
      return false;
    }
  }


  // PAGINAZIONE

  onPageChange(number: number) {
    console.log(`pageChange(${number})`);
    this.currentPage = number;
  }

  onPageBoundsCorrection(number: number) {
    console.log(`pageBoundsCorrection(${number})`);
    this.currentPage = number;
  }


  convertInSlug(stringa): any {
    stringa = stringa.replace(/ /g, "-");
    stringa = stringa.replace(/'/g, "");

    return stringa;
  }



  leggiZipById(filename, fileId): void{
      this.httpClient.get(GlobalConstants.API_READ_ZIP_COMUNE + fileId,{ responseType: 'blob' as 'json'}).subscribe(
      (response: any) =>{
        let dataType = response.type;
        let binaryData = [];
        binaryData.push(response);
        let downloadLink = document.createElement('a');
        downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType}));
        if (filename)
          downloadLink.setAttribute('download', filename);
        document.body.appendChild(downloadLink);
        downloadLink.click();
      }
    )
  }

}
