import {Component, OnInit} from '@angular/core';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import {GlobalConstants} from '../global-constants';
//noinspection TypeScriptCheckImport
import {icon, latLng, Map, marker, point, polyline, tileLayer, latLngBounds, featureGroup, circle, geoJSON} from 'leaflet';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import * as L from 'leaflet';
import {DatePipe} from '@angular/common'
import {LocalStorageService, SessionStorageService, LocalStorage, SessionStorage} from 'angular-web-storage';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.less']
})
export class HomeComponent implements OnInit {

    faChevronRight = faChevronRight;

    constructor(private httpClient: HttpClient, public datepipe: DatePipe, private local: LocalStorageService) {
    }

    optionMap = GlobalConstants.OPTION_MAP; //vedere in costanti
    arrayMarker = []; //array di marker: sono i marker che verranno aggiunti alla mappa
    json;
    dataLayer;
    comuniBig;
    classeIndagine = [];
    tipoIndagine = [];
    filtroSisma = "";
    filtroEdilizia = "";
    filtroClasseIndagine = "";
    filtroTipoIndagine = "";
    filtroDataDa = "";
    filtroDataA = "";
    map: L.Map;
    numeroSitiLineari: number;
    numeroSitiPuntuali: number;
    numeroIndaginiLineari: number;
    numeroIndaginiPuntuali: number;
    numeroComuni: number;

    des_classe_indagine;
    des_tipo_indagine;

    ngOnInit(): void {
        this.des_classe_indagine = this.local.get("des_classe_indagine");
        this.des_tipo_indagine = this.local.get("des_tipo_indagine");

        //############################################ des_classe_indagine -----> CONTROLLO API IN LOCALSTORAGE
        if (this.des_classe_indagine == null) {
            console.log("api NON in localStorage, fare la chiamata");
            this.httpClient.get(GlobalConstants.API_DES_CLASSE_INDAGINE).subscribe((risposta: any) => {
                console.log(risposta);
                this.classeIndagine = risposta;
                this.local.set("des_classe_indagine", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
            });
        } else {
            console.log("api presa da localStorage");
            this.classeIndagine = this.local.get("des_classe_indagine"); //prendo l'api dal localStorage
        }

        //############################################ des_tipo_indagine -----> CONTROLLO API IN LOCALSTORAGE
        if (this.des_tipo_indagine == null) {
            console.log("api NON in localStorage, fare la chiamata");
            this.httpClient.get(GlobalConstants.API_DES_TIPO_INDAGINE).subscribe((risposta: any) => {
                console.log(risposta);
                this.tipoIndagine = risposta;
                this.local.set("des_tipo_indagine", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
            });
        } else {
            console.log("api presa da localStorage");
            this.tipoIndagine = this.local.get("des_tipo_indagine"); //prendo l'api dal localStorage
        }
    }


    onMapReady(map: L.Map) { //quando la mappa è caricata
        console.log("map ready!");

        this.httpClient.get(GlobalConstants.API_COMUNI_BIG).subscribe((risposta: any) => {
            console.log("risposta", risposta);
            this.comuniBig = risposta;

            this.numeroSitiLineari = 0;
            this.numeroSitiPuntuali = 0;
            this.numeroIndaginiLineari = 0;
            this.numeroIndaginiPuntuali = 0;
            this.numeroComuni = 0;
            for (var item in risposta) {
                //console.log("Key:" + item);
                //console.log(risposta[item]);
                for (var item2 in risposta[item]['sitiLineari']) {
                    this.numeroSitiLineari = this.numeroSitiLineari + 1;
                    this.numeroIndaginiLineari = this.numeroIndaginiLineari + risposta[item]['sitiLineari'][item2].indagini.length;
                }
                for (var item3 in risposta[item]['sitiPuntuali']) {
                    this.numeroSitiPuntuali = this.numeroSitiPuntuali + 1;
                    this.numeroIndaginiPuntuali = this.numeroIndaginiPuntuali + risposta[item]['sitiPuntuali'][item3].indagini.length;
                }
                if (risposta[item]['numSitiLineari'] + risposta[item]['numSitiPuntuali'] > 0) {
                    this.numeroComuni++;
                }
            }

            this.map = map;
            this.prendiGeoJson(map, this.comuniBig, this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
        });

    }


    prendiGeoJson(map: L.Map, comuniBig, filtroSisma, filtroEdilizia, filtroClasseIndagine, filtroTipoIndagine, filtroDataDa, filtroDataA) {
        this.httpClient.get('assets/Abruzzo.geojson').subscribe((json: any) => {
            console.log(json);
            this.json = json;

            //### GEOJSON
            var styleGeoJson = {
                "color": "#000000",
                "fillColor": "#ff6600",
                "weight": 4,
                "opacity": 0.20
            };

            if (this.dataLayer != null) {
                map.removeLayer(this.dataLayer);
            }

            //noinspection TypeScriptUnresolvedFunction
            this.dataLayer = L.geoJSON(this.json, {
                style: styleGeoJson
            }).addTo(map);

            this.dataLayer.eachLayer(function (layer) {
                var nameKey = (layer.feature.properties.name).replace(/'/g, "");
                var popupContent = '<div class="info_content">' +
                    '<h4>' + nameKey + '</h4>' +
                    '<strong>' + comuniBig[nameKey].numSitiLineari + '</strong> Siti Lineari<br><strong>' + comuniBig[nameKey].numSitiPuntuali + '</strong> Siti Puntuali</p><hr><p><a href="comuni/' + comuniBig[nameKey].id + '"><strong><i class="fas fa-caret-right"></i> Vai al comune</strong></p>' +
                    '</div>';
                layer.bindPopup(popupContent, {
                    //maxWidth: 400,
                    //minWidth: 400
                });

                //filtri QUI!!!!#}
                var check = 0;

                for (var key in comuniBig[nameKey].sitiLineari) {
                    var classeL = [];
                    var tipoL = [];
                    //console.log("aaa", comuniBig[nameKey].sitiLineari[key]);

                    if (comuniBig[nameKey].sitiLineari[key].indagini != undefined) {
                        classeL = (comuniBig[nameKey].sitiLineari[key].indagini).filter(function (person) {
                            return person.classe == filtroClasseIndagine
                        });
                        tipoL = (comuniBig[nameKey].sitiLineari[key].indagini).filter(function (person) {
                            return person.tipo == filtroTipoIndagine
                        });
                    }
                    if ((comuniBig[nameKey].sitiLineari[key].sisma == filtroSisma || filtroSisma == "") &&
                        (comuniBig[nameKey].sitiLineari[key].edilizia == filtroEdilizia || filtroEdilizia == "") &&
                        (classeL.length > 0 || filtroClasseIndagine == "") &&
                        (tipoL.length > 0 || filtroTipoIndagine == "") &&
                        (comuniBig[nameKey].sitiLineari[key].dataCertificato != null && comuniBig[nameKey].sitiLineari[key].dataCertificato.substring(0, 10) >= filtroDataDa || filtroDataDa == "" || filtroDataDa == null ) &&
                        (comuniBig[nameKey].sitiLineari[key].dataCertificato != null && comuniBig[nameKey].sitiLineari[key].dataCertificato.substring(0, 10) <= filtroDataA || filtroDataA == "" || filtroDataA == null )
                    ) {
                        console.log("sitiL ", comuniBig[nameKey].sitiLineari[key]);
                        check = 1;
                    }
                }
                for (var key in comuniBig[nameKey].sitiPuntuali) {
                    var classeP = [];
                    var tipoP = [];
                    if (comuniBig[nameKey].sitiPuntuali[key].indagini != undefined) {
                        classeP = (comuniBig[nameKey].sitiPuntuali[key].indagini).filter(function (person) {
                            return person.classe == filtroClasseIndagine
                        });
                        tipoP = (comuniBig[nameKey].sitiPuntuali[key].indagini).filter(function (person) {
                            return person.tipo == filtroTipoIndagine
                        });
                    }
                    if ((comuniBig[nameKey].sitiPuntuali[key].sisma == filtroSisma || filtroSisma == "") &&
                        (comuniBig[nameKey].sitiPuntuali[key].edilizia == filtroEdilizia || filtroEdilizia == "") &&
                        (classeP.length > 0 || filtroClasseIndagine == "") &&
                        (tipoP.length > 0 || filtroTipoIndagine == "") &&
                        (comuniBig[nameKey].sitiPuntuali[key].dataCertificato != null && comuniBig[nameKey].sitiPuntuali[key].dataCertificato.substring(0, 10) >= filtroDataDa || filtroDataDa == "" || filtroDataDa == null  ) &&
                        (comuniBig[nameKey].sitiPuntuali[key].dataCertificato != null && comuniBig[nameKey].sitiPuntuali[key].dataCertificato.substring(0, 10) <= filtroDataA || filtroDataA == ""  || filtroDataA == null  )
                    ) {
                        console.log("sitiP ", comuniBig[nameKey].sitiPuntuali[key]);
                        check = 1;
                    }
                }

                if ((comuniBig[nameKey].numSitiLineari > 0 || comuniBig[nameKey].numSitiPuntuali > 0) && (check == 1)) { //se cè almeno un sito
                    layer.setStyle({
                        fillColor: '#004d00',
                    });
                } else {
                    //map.data.remove(feature);#}
                    layer.setStyle({
                        fillColor: '#ff6600',
                    });
                }

            });
        });

    }


    onChangeFiltroEdilizia(filtro) {
        console.log("filtro", filtro.value);
        this.filtroEdilizia = filtro.value;
        this.prendiGeoJson(this.map, this.comuniBig, this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
    }

    onChangeFiltroSisma(filtro) {
        console.log(filtro);
        this.filtroSisma = filtro.value;
        this.prendiGeoJson(this.map, this.comuniBig, this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
    }

    onChangeFiltroClasseIndagine(filtro) {
        console.log(filtro);
        this.filtroClasseIndagine = filtro.value;
        this.prendiGeoJson(this.map, this.comuniBig, this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
    }

    onChangeFiltroTipoIndagine(filtro) {
        console.log(filtro);
        this.filtroTipoIndagine = filtro.value;
        this.prendiGeoJson(this.map, this.comuniBig, this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
    }

    onFilterDAchange(value: Date) {
        let dataFormattata = this.datepipe.transform(value, 'yyyy-MM-dd');
        console.log(dataFormattata);
        this.filtroDataDa = dataFormattata;
        this.prendiGeoJson(this.map, this.comuniBig, this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
    }

    onFilterAchange(value: Date) {
        let dataFormattata = this.datepipe.transform(value, 'yyyy-MM-dd');
        console.log(dataFormattata);
        this.filtroDataA = dataFormattata;
        this.prendiGeoJson(this.map, this.comuniBig, this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
    }


}
