import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "../../service/data.service";
import {LocalStorageService} from "angular-web-storage";
import {DatePipe} from "@angular/common";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {animate, style, transition, trigger} from "@angular/animations";
import {GlobalConstants} from "../../global-constants";
import jwt_decode from "jwt-decode";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService

@Component({
  selector: 'app-lista-microzonazione',
  templateUrl: './lista-microzonazione.component.html',
  styleUrls: ['./lista-microzonazione.component.less'],
  animations: [
    trigger(
      'deleteAnimation', [
        transition(':leave', [
          style({backgroundColor:"#FF0000", opacity: 1}),
          animate('1500ms', style({opacity: 0}))
        ])
      ]
    )
  ]
})
export class ListaMicrozonazioneComponent implements OnInit {

  constructor(private ngxService: NgxUiLoaderService, private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private dataService: DataService, private local: LocalStorageService, public datepipe: DatePipe, private modalService: BsModalService) {
  }

  sitiList = [];
  arrayComuni = [];
  arrayComuniIdTemp = [];
  classeIndagine = [];
  tipoIndagine = [];
  filtroCerca = "";
  filtroTipo = "";
  filtroSisma = "";
  filtroEdilizia = "microzonazione";
  filtroComune = "";
  filtroClasseIndagine = "";
  filtroTipoIndagine = "";
  filtroDataDaA = "";
  filtroDataDa = "";
  filtroDataA = "";
  filtroStato = "";
  des_classe_indagine;
  des_tipo_indagine;
  siti_microzonazione;
  comuni_siti;

  bsModalRef: BsModalRef; //oggetto della modal
  submittedGenera = false;

  noAnimazione = 0; //serve per non far eseguire l'animazione in caso di modifica (solo in delete)

  logged = false;
  decodedJwt = null;
  page = 1;


  ngOnInit(): void {


    this.des_classe_indagine = this.local.get("des_classe_indagine");
    this.des_tipo_indagine = this.local.get("des_tipo_indagine");
    this.siti_microzonazione = this.local.get("siti_microzonazione");
    this.comuni_siti = this.local.get("comuni_siti");

    //############################################ des_classe_indagine -----> CONTROLLO API IN LOCALSTORAGE
    if (this.des_classe_indagine == null) {
      console.log("api NON in localStorage, fare la chiamata");
      this.httpClient.get(GlobalConstants.API_DES_CLASSE_INDAGINE).subscribe((risposta: any) => {
        //console.log(risposta);
        this.classeIndagine = risposta;
        this.local.set("des_classe_indagine", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
      });
    } else {
      console.log("api presa da localStorage");
      this.classeIndagine = this.local.get("des_classe_indagine"); //prendo l'api dal localStorage
    }

    //############################################ des_tipo_indagine -----> CONTROLLO API IN LOCALSTORAGE
    if (this.des_tipo_indagine == null) {
      console.log("api NON in localStorage, fare la chiamata");
      this.httpClient.get(GlobalConstants.API_DES_TIPO_INDAGINE).subscribe((risposta: any) => {
        //console.log(risposta);
        this.tipoIndagine = risposta;
        this.local.set("des_tipo_indagine", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
      });
    } else {
      console.log("api presa da localStorage");
      this.tipoIndagine = this.local.get("des_tipo_indagine"); //prendo l'api dal localStorage
    }


    if (this.dataService.isAuthenticated()) {
      this.logged = true;
      if (this.decodedJwt != null) {
        this.decodedJwt = jwt_decode(this.decodedJwt);
      } else {
        var tok = localStorage.getItem('jwt');
        this.decodedJwt = jwt_decode(tok);// decodifica il token jwt per prendere le sue info
      }

      console.log("decodedJwt", this.decodedJwt.userId);
    }



    //this.siti_microzonazione = null;
    if (this.siti_microzonazione == null) {
      console.log("api NON in localStorage, fare la chiamata");
      this.dataService.getListaSitiMicrozonazione().subscribe((risposta: any) => {
        console.log("risposta", Object.values(risposta));
        //ricavo i soli comuni di tutti i siti (per la select)
        this.sitiList = Object.values(risposta);
        for (let i = 0; i < this.sitiList.length; i++) {
          for (let z = 0; z < this.sitiList[i].length; z++) {
            if (this.arrayComuniIdTemp.indexOf(this.sitiList[i][z].comune.id) === -1) {
              this.arrayComuni.push(this.sitiList[i][z].comune);
              this.arrayComuniIdTemp.push(this.sitiList[i][z].comune.id);
            }
            this.sitiList[i][z].countVisible = this.sitiList[i].length;
          }
        }
        this.arrayComuni.sort((a, b) => (a.comune > b.comune) ? 1 : -1);

        console.log("comuni dei siti", this.arrayComuni);
        console.log("this.sitiList", this.sitiList);

        this.local.set("siti_microzonazione", this.sitiList, 3600, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
      });

    } else {
      console.log("api presa da localStorage");
      this.sitiList = this.local.get("siti_microzonazione"); //prendo l'api dal localStorage

      //cache nella cache
      //this.comuni_siti = null;
      if (this.comuni_siti == null) {
        for (let i = 0; i < this.sitiList.length; i++) {
          for (let z = 0; z < this.sitiList[i].length; z++) {
            if (this.arrayComuniIdTemp.indexOf(this.sitiList[i][z].comune.id) === -1) {
              this.arrayComuni.push(this.sitiList[i][z].comune);
              this.arrayComuniIdTemp.push(this.sitiList[i][z].comune.id);
            }
            this.sitiList[i][z].countVisible = this.sitiList[i].length;
          }
        }
        //console.log("AAA", this.arrayComuni);
        this.arrayComuni.sort((a, b) => (a.comune > b.comune) ? 1 : -1);
        this.local.set("comuni_siti", this.arrayComuni, 3600, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)

      } else {
        this.arrayComuni = this.local.get("comuni_siti"); //prendo l'api dal localStorage
      }


      console.log("comuni dei siti", this.arrayComuni);
      console.log("this.sitiList", this.sitiList);
    }







  }


  aggiornaSitiByFiltri(search, tipo, edilizia, sisma, comune, classeIndagini, tipoIndagini, dataDa, dataA, stato) {
    let primoVisibile = 0;
    let countVisible = 0;
    for (let i = 0; i < this.sitiList.length; i++) {
      primoVisibile = 0;
      countVisible = 0;
      for (let z = 0; z < this.sitiList[i].length; z++) {
        if ((search == "" ||
            (this.sitiList[i][z].denominazione.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
            (this.sitiList[i][z].cup != null && this.sitiList[i][z].cup != "" && this.sitiList[i][z].cup.toLowerCase().indexOf(search.toLowerCase()) !== -1) ||
            (this.sitiList[i][z].protocollo != null && this.sitiList[i][z].protocollo != "" && this.sitiList[i][z].protocollo.toLowerCase().indexOf(search.toLowerCase()) !== -1)) &&
          (tipo == "" || tipo == this.sitiList[i][z].tipo) &&
          (edilizia == "" || edilizia == this.sitiList[i][z].edilizia) &&
          (sisma == "" || sisma == this.sitiList[i][z].sisma) &&
          (comune == "" || comune == this.sitiList[i][z].comune.id) &&
          (classeIndagini == "" || this.sitiList[i][z].classeIndagini.indexOf(classeIndagini) !== -1) &&
          (tipoIndagini == "" || this.sitiList[i][z].tipoIndagini.indexOf(tipoIndagini) !== -1) &&
          (stato == "" || stato == this.sitiList[i][z].stato) &&
          (dataDa == "" || dataDa == null || (this.sitiList[i][z].dataSito >= this.datepipe.transform(dataDa, 'yyyy-MM-dd'))) &&
          (dataA == "" || dataA == null || (this.sitiList[i][z].dataSito <= this.datepipe.transform(dataA, 'yyyy-MM-dd')))
        ) {
          this.sitiList[i][z].mostra = 1;
          if (primoVisibile == 0) {
            this.sitiList[i][z].primoVisibile = 1;
            primoVisibile = 1;
          } else {
            this.sitiList[i][z].primoVisibile = 0;
          }
          countVisible++;
        } else {
          this.sitiList[i][z].mostra = 0;
          this.sitiList[i][z].primoVisibile = 0;
        }
      }


      for (let z = 0; z < this.sitiList[i].length; z++) {
        this.sitiList[i][z].countVisible = countVisible;
      }

    }
  }

  onChangeFiltro(filtro) {
    if (filtro != null) {
      console.log('filtro', filtro.value);
      this.aggiornaSitiByFiltri(this.filtroCerca, this.filtroTipo, this.filtroEdilizia, this.filtroSisma, this.filtroComune, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA, this.filtroStato);
    }
  }

  pageChanged(event) {
    console.log("pageEvent", event);
    this.page = event;
  }

}
