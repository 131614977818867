import { Component, OnInit } from '@angular/core';
import {GlobalConstants} from '../../global-constants';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-vista-indagini',
  templateUrl: './vista-indagini.component.html',
  styleUrls: ['./vista-indagini.component.less']
})
export class VistaIndaginiComponent implements OnInit {

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) { }

  private sub: any;
  idSito: number;
  idIndagine: number;
  tipo: string;
  tipoLabel: string;
  indagine;

  ngOnInit(): void {

    //per prendere il parametro (id in questo caso) dalla url
    this.sub = this.route.params.subscribe(params => {
      this.idSito = params['idSito']; // (+) converts string 'id' to a number
      this.tipo = params['tipo'];
      this.idIndagine = params['idIndagine']; // (+) converts string 'id' to a number
      console.log("this.tipo", this.tipo);
      if (this.tipo == "puntuali") { this.tipoLabel = "puntuale"; } else {this.tipoLabel = "lineare"};

      this.httpClient.get(GlobalConstants.API_INDAGINI_SITI.replace("{tipo}/{idSito}/indagini/{idIndagine}", this.tipo + "/" + this.idSito + "/indagini/" + this.idIndagine)).subscribe((risposta: any) => {
        console.log("risposta", risposta);
        this.indagine = risposta;

      });


    });

  }



}
