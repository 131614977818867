import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LocalStorageService} from "angular-web-storage";
import {Router} from "@angular/router";
import {DataService} from "../../service/data.service";
import {GlobalConstants} from "../../global-constants";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-attivazione',
  templateUrl: './attivazione.component.html',
  styleUrls: ['./attivazione.component.less']
})
export class AttivazioneComponent implements OnInit {

  constructor(private dataService: DataService, private httpClient: HttpClient, private local: LocalStorageService,private router: Router,private toastr: ToastrService) {}

  jwtGA;
  qrCode = "";
  secret = "";

  ngOnInit(): void {

    this.jwtGA = this.local.get("jwtGA");
    console.log("jwtGA", this.jwtGA);
    if (this.jwtGA == null) {
      this.router.navigate(['/login']); //redirect (alla login?)
    }

    this.dataService.verificaGA(this.jwtGA).subscribe(rispostaGA => {
      console.log("verificaGA", rispostaGA);
      //se la risposta è vuota, vuol dire che abbiamo già l'attivazione GA
      if (rispostaGA.secret != undefined ) {
        this.qrCode = rispostaGA.qrCode;
        this.secret = rispostaGA.secret;
      } else {
        console.log("redirect al component di verifica codice GA");
        this.router.navigate(['/verificaGA']); //redirect (alla login?)
      }
    });
  }

  onSubmit(form) { //al submit
    var valori = form.form.value; //prendo i valori inseriti nella form
    console.log("valori form", valori);

    this.dataService.verificaGA(valori).subscribe((risposta: any) => {
      console.log("verificaGA qrcode", risposta);
      console.log("risposta.length", risposta.length);

      if (risposta.attivazioneGA == "ok") {
        this.router.navigate(['/verificaGA']); //redirect (alla login?)
      } else {
        this.toastr.error( GlobalConstants.ERROR_ATTIVAZIONE2FATTORI_TESTO, GlobalConstants.ERROR_ATTIVAZIONE2FATTORI_TITOLO);

      }
    })

  }

}
