import { Component, OnInit } from '@angular/core';
import jwt_decode from "jwt-decode";
import {DataService} from "../../service/data.service";
import {LocalStorageService} from "angular-web-storage";
import {HttpClient} from "@angular/common/http";
import {GlobalConstants} from "../../global-constants";
import {ActivatedRoute, Router} from "@angular/router";
import {marker} from "leaflet";

@Component({
  selector: 'app-vista-storico',
  templateUrl: './vista-storico.component.html',
  styleUrls: ['./vista-storico.component.less']
})
export class VistaStoricoComponent implements OnInit {

  constructor(private dataService: DataService, private local: LocalStorageService, private httpClient: HttpClient, private route: ActivatedRoute, public router: Router) { }

  idIntervento = '';
  intervento;
  logged = false;
  decodedJwt = null;
  liveSite = (GlobalConstants.liveSite).replace("/public","");
  private sub: any;

  optionMap = GlobalConstants.OPTION_MAP; //vedere in costanti
  arrayMarker = []; //array di marker: sono i marker che verranno aggiunti alla mappa
  arrayMarkerBounds = []; //array di appoggio che verrà passato a markerBounds
  markerBounds: [number, number][]; //array di coordinate su cui si andrà a centrare la mappa (coordinate, non marker!)


  ngOnInit(): void {
    if (this.dataService.isAuthenticated()) {
      this.logged = true;
      if (this.decodedJwt != null) {
        this.decodedJwt = jwt_decode(this.decodedJwt);
      } else {
        var tok = localStorage.getItem('jwt');
        this.decodedJwt = jwt_decode(tok);// decodifica il token jwt per prendere le sue info
      }

      console.log("decodedJwt", this.decodedJwt.userId);
    }


    this.sub = this.route.params.subscribe(params => {
      this.idIntervento = params['id'];
    });

    if (this.local.get('vistaStorico' + this.idIntervento) == null) {
      console.log("api NON in localStorage, fare la chiamata");
      this.dataService.getVistaStorico(this.idIntervento, this.decodedJwt.username).subscribe((risposta: any) => {
        console.log(risposta);
        if (risposta.stato) {
          this.router.navigate(['/siti/'])
            .then(() => {
              window.location.reload();//forza reload
            });
        }


        this.intervento = risposta;
        this.aggiungiMarkerAllaMappa(this.intervento);
        this.local.set('vistaStorico' + this.idIntervento, risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
      });
    } else {
      console.log("api presa da localStorage");
      this.intervento = this.local.get('vistaStorico' + this.idIntervento); //prendo l'api dal localStorage
      this.aggiungiMarkerAllaMappa(this.intervento);
    }
    console.log('intervento', this.intervento);

  }

  onMapReady(map: L.Map) { //quando la mappa è caricata
    console.log("map ready!");
  }

  aggiungiMarkerAllaMappa(intervento) {
    if (intervento.lat != null) {
      let mark = marker([intervento.lat, intervento.lng], {
        icon: GlobalConstants.ICONA_MARKER
      });

      this.arrayMarker.push(mark);//aggiungiamo il marker all'array che verrà passato alla mappa
      this.arrayMarkerBounds.push([intervento.lat, intervento.lng]); //aggiorniamo l'array per la centratura
    }

    this.markerBounds = this.arrayMarkerBounds; //centriamo in base a i marker
  }


}
