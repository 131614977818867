<section id="actions" class="mt-4 mb-4">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md">
        <h1>Elenco Utenti del sistema</h1>
      </div>
      <div class="col col-md-3 text-md-right text-left">
        <div class="dropdown float-right">
          <a routerLink="/utenti/new" class="btn btn-primary">Nuovo utente</a>
        </div>
      </div>
    </div>
  </div>
</section>

<section id="search" class="mb-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col text-right">
        <div class="card text-left">
          <div class="card-body p-2">
            <div class="row align-items-center">
              <div class="col text-left pb-2 mb-2 border-bottom">
                <span class="">Cerca tra gli utenti</span>
              </div>
            </div>
            <div id="search-collapse">
              <form>
                <div class="form-row">
                  <div class="col-12 col-md">
                    <label for="cerca">Testo libero</label>
                    <input id="cerca" name="cerca" type="text" class="form-control cercaFilter" placeholder="Cerca.." #filterCerca="ngModel" [(ngModel)]="filtroCerca">
                  </div>

                  <div class="col-12 col-md">
                    <div class="form-check">
                      <label for="cerca">Stato utente</label>
                      <select class="form-control chosen statoFilter" id="stato" name="stato" placeholder="Inizia a scrivere"  #filterStato="ngModel" [(ngModel)]="filtroStato">
                        <option value="">Stato</option>
                        <option value="1">Attivo</option>
                        <option value="0">Non Attivo</option>
                      </select>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section id="list" class="request">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="table-responsive-sm">
          <table class="table table-bordered text-left">
            <thead>
            <tr>
              <th scope="col" class="text-center" style="width:3%">
                #ID <a href="" class="sortById desc"><i class="fas fa-caret-down"></i></a>
              </th>
              <th scope="col">
                Nome
              </th>
              <th scope="col">
                Username
              </th>
              <th scope="col">
                Gruppo
              </th>
              <th scope="col" class="text-center">
                Stato
              </th>
              <th scope="col" class="text-center">
                2FA
              </th>
              <th scope="col" class="text-center">
                Azioni
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="task-list-row" *ngFor="let item of utentiList" [hidden]="confrontaFiltro(item, filterCerca.value, filterStato.value)">
              <td scope="row" class="text-center row-id">
                {{ item.id }}
              </td>
              <td class="title text-uppercase">
                <h2><a routerLink="/utenti/{{item.id}}">{{ item.nome }} {{ item.cognome }}</a></h2>
              </td>
              <td>
                <strong>{{ item.username }}</strong>
              </td>
              <td>
                {{ item.gruppo.role }}
              </td>
              <td class="text-center">
                <div *ngIf="item.isActive == 0; then BlockNoActive else BlockActive"></div>
                <ng-template #BlockNoActive><div class="badge badge-danger">NON ATTIVO</div></ng-template>
                <ng-template #BlockActive><div class="badge badge-success">ATTIVO</div></ng-template>
              </td>
              <td class="text-center">
                <div *ngIf="item.secretGA == 0; then BlockNoSecretGA else BlockSecretGA"></div>
                <ng-template #BlockNoSecretGA><div class="badge badge-danger">NON ATTIVO</div></ng-template>
                <ng-template #BlockSecretGA><div class="badge badge-success">ATTIVO</div></ng-template>
              </td>
              <td class="actions text-center">
                <a routerLink="/utenti/{{item.id}}" class="ml-2 mr-2 edit-row" title="" data-toggle="tooltip" data-placement="top" data-original-title="Modifica utente">
                  <fa-icon [icon]="['fas', 'edit']"></fa-icon>
                </a>
                <span>
                  <a href="javascript: void(0)" (click)="openModalDeleteUtente(modalDeleteUtente, item)" class="ml-2 mr-2 delete-modal red" data-id="{{ item.id }}" title="Elimina utente"><fa-icon [icon]="['fas', 'trash-alt']"></fa-icon></a>
                </span>
              </td>
            </tr>

            </tbody>
          </table>


          <div class="alert alert-danger no-result-filter" style="display: none;">
            Nessuna richiesta trovata con i seguenti filtri
          </div>

        </div>
      </div>
    </div>
  </div>
</section>


<!-- ############################# MODAL DELETE UTENTE ############################# -->
<ng-template #modalDeleteUtente>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Elimina Utente</h4>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <p>
      Sei sicuro di voler eliminare l'utente <strong>{{bsModalRef.content?.username}}</strong>? <br>
      Verranno eliminate anche tutte le sue indagini con parametri e stratigrafie collegate.<br>
      <strong>L'Azione è irreversibile.</strong>
    </p>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-primary" (click)="bsModalRef.hide()">Annulla</button>
    <button type="button" class="btn btn-danger" (click)="deleteUtente(bsModalRef.content.id)">Elimina</button>
  </div>
</ng-template>
