import {Component, OnInit, TemplateRef, NgZone } from '@angular/core';
import {GlobalConstants} from '../../global-constants';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute} from '@angular/router';
import {DataService} from '../../service/data.service';
import {LocalStorageService, SessionStorageService, LocalStorage, SessionStorage} from 'angular-web-storage';
import {Router} from '@angular/router';
import { of, Observable, Subject } from 'rxjs';
import { map } from 'rxjs/operators'; //non è per la mappa
import { icon, latLng, Map, marker, point, polyline, tileLayer } from 'leaflet';
import {ToastrService} from 'ngx-toastr';
import {BsModalService, BsModalRef} from 'ngx-bootstrap/modal';
import {trigger, transition, style, animate, query, stagger, state, keyframes} from "@angular/animations";
import * as L from "leaflet";
import jwt_decode from "jwt-decode";

@Component({
    selector: 'app-scheda-siti',
    templateUrl: './scheda-siti.component.html',
    styleUrls: ['./scheda-siti.component.less'],
    animations: [
      trigger(
        'deleteAnimation', [
          transition(':leave', [
            style({backgroundColor:"#FF0000", opacity: 1}),
            animate('1500ms', style({opacity: 0}))
          ])
        ]
      )
    ]

})
export class SchedaSitiComponent implements OnInit {


    constructor(private zone: NgZone, private httpClient: HttpClient, private route: ActivatedRoute, private dataService: DataService, private local: LocalStorageService, private router: Router, private toastr: ToastrService, private modalService: BsModalService) {
    }

    liveSite = (GlobalConstants.liveSite).replace("/public","");

    private sub: any;
    idSito;
    new_gis = "";
    tipo: string;
    sito: any = {};
    submitted = false;

    mostraGenioCivile = false;
    mostraCup = false;
    mostraSisma = false;
    mostraProtocollo = false;
    mostraIdAggregato = false;

    arrayGisL = [];
    arrayGisP = [];
    rispostaObject = [];
    des_tipo_coor;
    tipoCoor = [];
    comuniLS;
    comuni = [];
    utentiList;
    utenti = [];

    noAnimazione = 0; //serve per non far eseguire l'animazione in caso di modifica (solo in delete)

    //per la gestione della geolocalizzazione da input (con api)
    //https://developer.here.com/documentation/geocoding-search-api/dev_guide/topics/endpoint-geocode-brief.html
    indirizzi: Observable<any>;
    isLoading = false;
    indirizzoSelected;
    //per la mappa (indirizzi)
    optionMap = GlobalConstants.OPTION_MAP; //vedere in costanti
    markerIndirizzo: any;
    zoomMap: number = this.optionMap.zoom;
    centerMap = this.optionMap.center;
    zoomOptions = {animate: true, duration: 1.5};

    //per la mappa (B)
    optionMap_B = GlobalConstants.OPTION_MAP_B; //vedere in costanti
    markerIndirizzo_B: any;
    zoomMap_B: number = this.optionMap_B.zoom;
    centerMap_B = this.optionMap_B.center;
    zoomOptions_B = {animate: true, duration: 1.5};

    //per la mappa indagini esistenti
    optionMapIndagini = GlobalConstants.OPTION_MAP_INDAGINI; //vedere in costanti
    arrayMarkerIndagini = []; //array di marker: sono i marker che verranno aggiunti alla mappa
    arrayMarkerBoundsIndagini = []; //array di appoggio che verrà passato a markerBounds
    markerBoundsIndagini: [number, number][]; //array di coordinate su cui si andrà a centrare la mappa (coordinate, non marker!)
    //map: L.Map;

    validazioneGeologica = 0;
    validazioneGeotecnica = 0;

    bsModalRef: BsModalRef; //oggetto della modal
    indagineSelected: any = {}; //per popolare la modal del'indagine una volta aperta
    submittedIndagine = false;
    submittedIndagineEsistente = false;

    campoClasseIndagine = "";
    campoTipoIndagine = "";
    campoParametroIndagine = [];

    classeIndagine = [];
    tipoIndagine = [];
    parametriIndagine = [];
    parametriIndagineSigle = [];
    des_classe_indagine;
    des_tipo_indagine;
    des_parametri;
    idIndagine = "";

    arrayParametri = [{
      "parametroTipo" : "",
      "parametroValore" : "",
      "parametroProfTop" : "",
      "parametroProfBot" : "",
      "parametroSpessore" : "",
      "parametroQuotaSlmTop" : "",
      "parametroQuotaSlmBot" : "",
      "stratigrafia": [{"profonditaDa" : "", "profonditaA" : "", "spessore" : "", "siglaCarg" : "", "litologica" : ""}]
    }];

  logged = false;
  decodedJwt = null;

  filtroTipoIndagine = null;
  filtroParametro = null;

  ngOnInit(): void {

        this.des_tipo_coor = this.local.get("des_tipo_coor");
        this.comuniLS = this.local.get("comuni");
        this.utentiList = this.local.get("utenti");
        //############################################ des_tipo_indagine -----> CONTROLLO API IN LOCALSTORAGE
        if (this.des_tipo_coor == null) {
            console.log("api NON in localStorage, fare la chiamata");
            this.httpClient.get(GlobalConstants.API_DES_TIPO_COORD).subscribe((risposta: any) => {
                //console.log(risposta);
                this.tipoCoor = risposta;
                this.local.set("des_tipo_coor", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
            });
        } else {
            console.log("api presa da localStorage");
            this.tipoCoor = this.local.get("des_tipo_coor"); //prendo l'api dal localStorage
        }

        if (this.comuniLS == null) {
            console.log("api NON in localStorage, fare la chiamata");
            this.httpClient.get(GlobalConstants.API_COMUNI).subscribe((risposta: any) => {
                this.comuni = risposta;
                this.local.set("comuni", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
            });
        } else {
            console.log("api presa da localStorage");
            this.comuni = this.local.get("comuni"); //prendo l'api dal localStorage
        }


        this.des_classe_indagine = this.local.get("des_classe_indagine");
        this.des_tipo_indagine = this.local.get("des_tipo_indagine");
        this.des_parametri = this.local.get("des_parametri");
        //############################################ des_classe_indagine -----> CONTROLLO API IN LOCALSTORAGE
        if (this.des_classe_indagine == null) {
          console.log("api NON in localStorage, fare la chiamata");
          this.httpClient.get(GlobalConstants.API_DES_CLASSE_INDAGINE).subscribe((risposta: any) => {
            //console.log(risposta);
            this.classeIndagine = risposta;
            this.local.set("des_classe_indagine", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
          });
        } else {
          console.log("api presa da localStorage");
          this.classeIndagine = this.local.get("des_classe_indagine"); //prendo l'api dal localStorage
        }
        //############################################ des_tipo_indagine -----> CONTROLLO API IN LOCALSTORAGE
        if (this.des_tipo_indagine == null) {
          console.log("api NON in localStorage, fare la chiamata");
          this.httpClient.get(GlobalConstants.API_DES_TIPO_INDAGINE).subscribe((risposta: any) => {
            //console.log(risposta);
            this.tipoIndagine = risposta;
            this.local.set("des_tipo_indagine", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
          });
        } else {
          console.log("api presa da localStorage");
          this.tipoIndagine = this.local.get("des_tipo_indagine"); //prendo l'api dal localStorage
        }
        //############################################ des_parametri -----> CONTROLLO API IN LOCALSTORAGE
        if (this.des_parametri == null) {
          console.log("api NON in localStorage, fare la chiamataa");
          this.httpClient.get(GlobalConstants.API_DES_PARAMETRI_INDAGINE).subscribe((risposta: any) => {
            //console.log(risposta);
            this.parametriIndagine = risposta;
            this.local.set("des_parametri", risposta, 10, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)

            for (let i = 0; i < (this.parametriIndagine).length; i++) {
              var sigleTemp = this.parametriIndagine[i].tipo.split("-");
              for (let k = 0; k < (sigleTemp).length; k++) {
                if (sigleTemp[k] != "") {
                  this.parametriIndagineSigle.push(sigleTemp[k]);
                }
              }
            }
            this.local.set("des_parametri_sigle", this.parametriIndagineSigle, 10, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
          });
        } else {
          console.log("api presa da localStorage");
          this.parametriIndagine = this.local.get("des_parametri"); //prendo l'api dal localStorage
          this.parametriIndagineSigle = this.local.get("des_parametri_sigle"); //prendo l'api dal localStorage
        }


    console.log("this.tipoIndagine",this.tipoIndagine);

    //per prendere il parametro (id in questo caso) dalla url
        this.sub = this.route.params.subscribe(params => {
            this.idSito = params['id'];
            this.tipo = params['tipo'];
            // se il tipo non è ne lineare e ne puntuale la url è errata
            if (this.tipo != "lineari" && this.tipo != "puntuali") {
                this.router.navigate(['404']);
            }
        });
        console.log("id", this.idSito);
        console.log("tipo", this.tipo);


    if (this.dataService.isAuthenticated()) {
      this.logged = true;
      if (this.decodedJwt != null) {
        this.decodedJwt = jwt_decode(this.decodedJwt);
      } else {
        var tok = localStorage.getItem('jwt');
        this.decodedJwt = jwt_decode(tok);// decodifica il token jwt per prendere le sue info
      }

      console.log("decodedJwt", this.decodedJwt);
    }


    // ############################## SITO NEW
        if (this.idSito == "new") {
            this.sito.edilizia = ""; //settiamo per default
            this.sito.sisma = "sisma-2009"; //settiamo per default la select sisma

            //ricaviamo tutti i gis (per la tendina) dalla stessa api della lista siti
            this.dataService.getListaSiti(this.decodedJwt.userId).subscribe((risposta: any) => {
                console.log("risposta getListaSiti", Object.values(risposta));
                this.rispostaObject = Object.values(risposta);
                for (let i = 0; i < this.rispostaObject.length; i++) {
                    for (let z = 0; z < this.rispostaObject[i].length; z++) {
                        if ((this.rispostaObject[i][z].tipo) == "lineare") {
                            if (this.arrayGisL.indexOf(this.rispostaObject[i][z].gis) === -1) {
                                this.arrayGisL.push(this.rispostaObject[i][z].gis);
                            }
                        } else { //altrimenti è puntuale
                            if (this.arrayGisP.indexOf(this.rispostaObject[i][z].gis) === -1) {
                                this.arrayGisP.push(this.rispostaObject[i][z].gis);
                            }
                        }
                    }
                }
            });

        }


        // ############################## SITO LINEARE
        if (this.tipo == "lineari" && this.idSito != "new") {
            this.httpClient.get(GlobalConstants.API_SITI + "/" + this.tipo + "/" + this.idSito).subscribe((risposta: any) => {
                console.log("risposta sito", risposta);
                this.sito = risposta;

                if (this.sito.stato == "sent") {
                  this.router.navigate(['/siti/' + this.tipo + '/' + this.sito.id + "/vista"]);
                }

                this.sito.dataSito = new Date(this.sito.dataSito); //prepara la data per il datepicker
                this.mostraOpzioniByEdilizia({value: this.sito.edilizia})  //inizializza le opzioni innput in base a edilizia (lo passiamo come oggetto perchè simuliamo come se viene dall'input)
                //se abbiamo già un indirizzo, pre-selezioniamo la select (ng-select)
                if (this.sito.indirizzo != null && this.sito.indirizzo != "") {
                    this.indirizzoSelected = {"title":this.sito.indirizzo};
                }
                //se abbiamo una latitudine, aggiungiamo il marker alla mappa, zommiamo e centriamo su di esso
                if (this.sito.lat != null && this.sito.lat > 0) {
                    this.markerIndirizzo = marker([ this.sito.lat, this.sito.lng ], {
                        //draggable:true,
                        icon: GlobalConstants.ICONA_MARKER
                    });
                    this.zoomMap = 16;
                    this.centerMap = latLng(this.sito.lat, this.sito.lng);
                }
                //se abbiamo una latitudine per mappa B, aggiungiamo il marker alla mappa, zommiamo e centriamo su di esso
                if (parseFloat(this.sito.BcoordX) != null && parseFloat(this.sito.BcoordY) > 0) {
                    this.markerIndirizzo_B = marker([ parseFloat(this.sito.BcoordX), parseFloat(this.sito.BcoordY) ], {
                        //draggable:true,
                        icon: GlobalConstants.ICONA_MARKER
                    });
                    this.zoomMap_B = 16;
                    this.centerMap_B = latLng(parseFloat(this.sito.BcoordX), parseFloat(this.sito.BcoordY));
                }

                if (this.sito.allegati_geologica.length > 0) { this.validazioneGeologica = 1; }
                if (this.sito.allegati_geotecnica.length > 0) { this.validazioneGeotecnica = 1; }

                if (this.decodedJwt.roles['role'] == 'ROLE_USER' && this.decodedJwt.userId !== this.sito.idUtente) {
                  this.router.navigate(['/siti/' + this.tipo + '/' + this.sito.id + "/vista"]);
                }
            });
        }

        // ############################## SITO PUNTUALE
        if (this.tipo == "puntuali" && this.idSito != "new") {
            this.dataService.getSito(this.idSito, this.tipo).subscribe((Response: any[]) => {
                console.log("risposta sito", Response);
                this.sito = Response;

              if (this.sito.stato == "sent") {
                this.router.navigate(['/siti/' + this.tipo + '/' + this.sito.id + "/vista"]);
              }

              this.sito.dataSito = new Date(this.sito.dataSito); //prepara la data per il datepicker
              this.mostraOpzioniByEdilizia({value: this.sito.edilizia})  //inizializza le opzioni innput in base a edilizia (lo passiamo come oggetto perchè simuliamo come se viene dall'input)
              //se abbiamo già un indirizzo, pre-selezioniamo la select (ng-select)
              if (this.sito.indirizzo != null && this.sito.indirizzo != "") {
                  this.indirizzoSelected = {"title":this.sito.indirizzo};
              }
              //se abbiamo una latitudine, aggiungiamo il marker alla mappa, zommiamo e centriamo su di esso
              if (this.sito.lat != null && this.sito.lat > 0) {
                  this.markerIndirizzo = marker([ this.sito.lat, this.sito.lng ], {
                      //draggable:true,
                      icon: GlobalConstants.ICONA_MARKER
                  });
                  this.zoomMap = 16;
                  this.centerMap = latLng(this.sito.lat, this.sito.lng);
              }

              if (this.sito.allegati_geologica.length > 0) { this.validazioneGeologica = 1; }
              if (this.sito.allegati_geotecnica.length > 0) { this.validazioneGeotecnica = 1; }

              if (this.decodedJwt.roles['role'] == 'ROLE_USER' && this.decodedJwt.userId !== this.sito.idUtente) {
                this.router.navigate(['/siti/' + this.tipo + '/' + this.sito.id + "/vista"]);
              }
            });
        }


      if (this.utentiList == null) {
        console.log("api NON in localStorage, fare la chiamata");
        this.dataService.getListaUtenti(this.decodedJwt.userId).subscribe((risposta: any) => {
          console.log("risposta", Object.values(risposta));
          this.utenti = Object.values(risposta);
          this.local.set("utenti", this.utenti, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
        });
      } else {
        console.log("api presa da localStorage");
        this.utenti = this.local.get("utenti"); //prendo l'api dal localStorage
      }





  }




    geolocalizzaIndirizzo(item) { //ad ogni carattere, chiamiamo l'api della geolocalizzazione per trovare indirizzi da aggiungere alla select
        var searchTerm = item.valueAccessor.searchTerm;

        this.isLoading = true; //attiviamo l'animazione di caricamento
        this.indirizzi = this.dataService.getGeolocalizzazione(searchTerm).pipe(
            map( response => {
                this.isLoading = false; //togliamo l'animazione (qui abbiamo i risultati)
                return response['items']; // passiamo i risultati alla select
            })
        );
    }
    //ricerca personalizzata della select indirizzi (ng-select). Vogliamo anche la ricerca parziale per singole parole (trovata in rete già fatta)
    customSearchFn (term: string, item: any[]) {
        term = term.toLowerCase();
        // Creating and array of space saperated term and removinf the empty values using filter
        let splitTerm = term.split(' ').filter(t => t);
        let isWordThere = [];
        // Pushing True/False if match is found
        splitTerm.forEach(arr_term => {
            let search = item['title'].toLowerCase();
            isWordThere.push(search.indexOf(arr_term) != -1);
        });
        const all_words = (this_word) => this_word;
        // Every method will return true if all values are true in isWordThere.
        return isWordThere.every(all_words);
    }
    onChangeIndirizzo(item) { // quando selezioniamo un indirizzo dalla select
        console.log("onChangeIndirizzo", item);
        this.sito.indirizzo = "";
        this.sito.lat = "";
        this.sito.lng = "";

        if(item.viewModel != null) {
            if(item.viewModel.address != null) { //se ha l'oggetto address (che contiene le info dell'indirizzo)
                if(item.viewModel.address.houseNumber != null) { //se ha un civico
                    this.sito.indirizzo = item.viewModel.address.street + " " + item.viewModel.address.houseNumber;
                } else if (item.viewModel.address.street != null) { //se ha un indirizzo (strada)
                    this.sito.indirizzo = item.viewModel.address.street;
                } else { //riportiamo l'indirizzo così come è scritto
                    this.sito.indirizzo = item.viewModel.title;
                }
            } else { //altrimeni l'api non ha trovato nulla e comunque l'indirizzo scritto
                this.sito.indirizzo = item.viewModel.title;
            }

            //se ha delle coordinate
            if(item.viewModel.position != null) {
                //aggiorniamo le coordinate
                this.sito.lat = item.viewModel.position.lat;
                this.sito.lng = item.viewModel.position.lng;

                //aggiorniamo zoom, animazione e centriamo
                this.zoomMap = 16;
                this.zoomOptions = {animate: true, duration: 1.5};
                this.centerMap = latLng(this.sito.lat, this.sito.lng);

                //aggiungiamo il marker alla mappa
                this.markerIndirizzo = marker([ this.sito.lat, this.sito.lng ], {
                    //draggable:true,
                    icon: GlobalConstants.ICONA_MARKER
                });

                if (this.tipo == "puntuali") {
                  this.sito.coordX = this.sito.lat;
                  this.sito.coordY = this.sito.lng;
                } else {
                  this.sito.AcoordX = this.sito.lat;
                  this.sito.AcoordY = this.sito.lng;
                }



                //// se trasciniamo il marker (NON PIU' USATO, ma potrebbe tornare utile in futuro)
                // this.markerIndirizzo.on("dragend", (e)=>{
                //     var marker = e.target;
                //     var position = marker.getLatLng();
                //
                //     console.log("posizione: ", this.anagrafica.lat);
                //
                //     this.zone.run(() => {
                //         this.centerMap = position;
                //     });
                //     this.zoomOptions = {animate: true, duration: 0.5};
                // });
            }
        }

    }
    onClickMap(event) { //quando facciamo click sulla mappa (per aggiungere il marker)
        console.log(event);
        //aggiungiamo il marker
        this.markerIndirizzo = marker(event.latlng, {
            //draggable:true,
            icon: GlobalConstants.ICONA_MARKER
        });
        //aggiorniamo lat e lng della nostra variabile sito
        this.sito.lat = event.latlng.lat.toFixed(5);
        this.sito.lng = event.latlng.lng.toFixed(5);
        this.sito.coordX = event.latlng.lat.toFixed(5);
        this.sito.coordY = event.latlng.lng.toFixed(5);
        this.sito.AcoordX = event.latlng.lat.toFixed(5);
        this.sito.AcoordY = event.latlng.lng.toFixed(5);
        //cambiamo animazione (più veloce) e centriamo
        this.zoomOptions = {animate: true, duration: 0.5};
        this.centerMap = latLng(event.latlng.lat, event.latlng.lng);
    }
    onClickMap_B(event) { //quando facciamo click sulla mappa (per aggiungere il marker)
        console.log(event);
        //aggiungiamo il marker
        this.markerIndirizzo_B = marker(event.latlng, {
            //draggable:true,
            icon: GlobalConstants.ICONA_MARKER
        });
        //aggiorniamo lat e lng della nostra variabile sito
        this.sito.BcoordX = event.latlng.lat.toFixed(5);
        this.sito.BcoordY = event.latlng.lng.toFixed(5);
        //cambiamo animazione (più veloce) e centriamo
        this.zoomOptions_B = {animate: true, duration: 0.5};
        this.centerMap_B = latLng(event.latlng.lat, event.latlng.lng);
    }
    resetMap() { //quando annulliamo la select (click sulla x), eliminiamo il marker e resettiamo la mappa alle impostazioni iniziali
        this.markerIndirizzo = null;
        this.zoomMap = this.optionMap.zoom;
        this.centerMap = this.optionMap.center;
        this.zoomOptions = {animate: true, duration: 1.5};
    }


    mostraOpzioniByEdilizia(edilizia) {
        console.log("edilizia", edilizia.value);
        switch (edilizia.value) {
            case "pubblica":
            case "int-pubblico":
                this.mostraGenioCivile = true;
                this.mostraCup = true;
                this.mostraSisma = true;
                this.mostraProtocollo = true;
                this.mostraIdAggregato = true;
                break;
            case "privata":
                this.mostraGenioCivile = true;
                this.mostraCup = false;
                this.mostraSisma = true;
                this.mostraProtocollo = true;
                this.mostraIdAggregato = true;
                break;
            case "":
            case "microzonazione":
                this.mostraGenioCivile = false;
                this.mostraCup = false;
                this.mostraSisma = false;
                this.mostraProtocollo = false;
                this.mostraIdAggregato = false;
                break;
        }
    }

    onSubmit(form) { //al salvataggio
        this.submitted = true; // abbiamo fatto un submit, serve per la validazione
        if (form.invalid || (((this.validazioneGeotecnica == 0 && this.sito.edilizia != 'microzonazione')|| this.validazioneGeologica == 0)) && this.route.snapshot.paramMap.get('id') != "new") { // stop here if form is invalid
            const firstElementWithError = document.querySelector('.ng-invalid');
            if (firstElementWithError) {
              firstElementWithError.scrollIntoView({ behavior: 'smooth' });
            }
          return;
        }
        console.log("salviamo", form.value);

        //###### CREAZIONE
        if (this.route.snapshot.paramMap.get('id') == "new") { // se il parametro id è uguale a "new"
            console.log("creiamo il sito");
            this.dataService.createSito(this.idSito, this.tipo, form.value)
              .subscribe(risposta => {
                console.log("creato", risposta);
                this.toastr.success(GlobalConstants.UPDATE_SITO_TESTO.replace("{denominazione}",risposta.denominazione), GlobalConstants.UPDATE_SITO_TITOLO);

                //this.router.navigate(['/siti/' + this.tipo + '/' + risposta.id]);
                this.router.navigate(['/siti/' + this.tipo + '/' + risposta.id])
                  .then(() => {
                    window.location.reload();//forza reload
                  });

              });
            return;
        }

        //###### UPDATE
        this.dataService.updateSito(this.idSito, this.tipo, form.value)
            .subscribe(risposta => {
                console.log("aggiornato", risposta);
                this.toastr.success(GlobalConstants.UPDATE_SITO_TESTO.replace("{denominazione}",risposta.denominazione), GlobalConstants.UPDATE_SITO_TITOLO);
            });
    }

    rimuoviValidazione(tipoAllegato) {
        switch (tipoAllegato) {
            case "geologica":
                this.validazioneGeologica = 1;
                break;
            case "geotecnica":
                this.validazioneGeotecnica = 1;
                break;
        }
    }
    aggiungiValidazione(tipoAllegato) {
        switch (tipoAllegato) {
            case "geologica":
                this.validazioneGeologica = 0;
                break;
            case "geotecnica":
                this.validazioneGeotecnica = 0;
                break;
        }
    }





    openModalAggiungiIndagine(modalIndagine: TemplateRef<any>, item) { // quando apriamo la modal di un contatto
      //noinspection TypeScriptValidateTypes
      this.bsModalRef = this.modalService.show(modalIndagine, {class: 'modal-xl'});
      this.indagineSelected = {}; //svuoto la form
      this.submittedIndagine = false; //resetto la validazione
      //resetto i parametri
      this.arrayParametri = [{
        "parametroTipo" : "",
        "parametroValore" : "",
        "parametroProfTop" : "",
        "parametroProfBot" : "",
        "parametroSpessore" : "",
        "parametroQuotaSlmTop" : "",
        "parametroQuotaSlmBot" : "",
        "stratigrafia": [{"profonditaDa" : "", "profonditaA" : "", "spessore" : "", "siglaCarg" : "", "litologica" : ""}]
      }];

      this.noAnimazione = 1; //non procede all'animazione perchè vogliamo solo modificare/aggiungere

      this.idIndagine = "new";

      console.log("dati indagine", item);
      if (item != null) { //se ho passato i dati (item), riempie la modal
        this.indagineSelected = {"id": item.id, "classeInd": item.classeInd, "tipoInd": item.tipoInd, "documento": item.documento}
        this.idIndagine = item.id;

        this.arrayParametri = [];
        for (let x = 0; x < (item.parametri).length; x++) {
          this.arrayParametri.push({
            "parametroTipo" : item.parametri[x].tipoPar,
            "parametroValore" : item.parametri[x].valore,
            "parametroProfTop" : item.parametri[x].profTop,
            "parametroProfBot" : item.parametri[x].profBot,
            "parametroSpessore" : item.parametri[x].spessore,
            "parametroQuotaSlmTop" : item.parametri[x].quotaSlmTop,
            "parametroQuotaSlmBot" : item.parametri[x].quotaSlmBot,
            "stratigrafia": item.parametri[x].stratigrafie
          });
        }
      }


    }
    onSubmitFormIndagine(formIndagine) { //quando si fà submit della form  (nella modal)
      this.submittedIndagine = true; // abbiamo fatto un submit, serve per la validazione
      console.log(formIndagine.invalid);
      console.log(formIndagine);
      if (formIndagine.invalid) { // stop here if formContatti is invalid
        return;
      }

      var valori = formIndagine.form.value; //prendo i valori inseriti nella form

      console.log("valori form", valori);

      //###### CREATE/UPDATE indagine
      this.dataService.createIndagine(this.idSito, this.tipo, this.idIndagine, valori)
        .subscribe(risposta => {
          console.log("indagine aggiunta", risposta);

          if (this.idIndagine == "new") {
            this.sito.indagini.push(risposta); //aggiungo l'indagine alla lista
            this.toastr.success(GlobalConstants.CREATE_INDAGINE_TESTO.replace("{idIndagine}", risposta.id), GlobalConstants.CREATE_INDAGINE_TITOLO);
          } else {

            for (let i = 0; i < this.sito.indagini.length; i++) {
              if (this.sito.indagini[i].id == risposta.id) {
                //metodo per aggiornare un elemento (toglie il vecchio e mette il nuovo alla stessa posizione: và fatto così)
                this.sito.indagini.splice(i, 1, risposta);
              }
            }
            this.toastr.success(GlobalConstants.UPDATE_INDAGINE_TESTO.replace("{idIndagine}", risposta.id), GlobalConstants.UPDATE_INDAGINE_TITOLO);
          }

          //PER CARICARE IL FILE (indagine) UPLOAD
          const formData = new FormData();
          formData.append('file', this.fileToUpload);
          console.log("fileToUpload", this.fileToUpload);
          if (this.fileToUpload != null) {
            this.httpClient.post<any>(GlobalConstants.liveSite + "/" + this.tipo + "/" + risposta.id + "/uploads/indagine", formData).subscribe((res: any) => {
                console.log("RES", res)
                //andiamo a rimettere il documento dell'indagine appena modificata
                for (let i = 0; i < this.sito.indagini.length; i++) {
                  if (this.sito.indagini[i].id == risposta.id) {
                    console.log("indagine in questione", this.sito.indagini[i]);
                    this.sito.indagini[i].documento = [{"id": res.id, "name": res.name, "path": res.path}];
                  }
                }
              }
            );
          }

        });


      this.bsModalRef.hide(); //chiudo la modal dei contatti
      return;
    }

    //quando selezionamo un file indagine
    fileToUpload: File = null;
    handleFileIndagine(files: FileList) {
      this.fileToUpload = files.item(0);
      this.indagineSelected.labelDocumentoIndagine = this.fileToUpload.name;
      this.indagineSelected.documento = [];
      console.log("file", this.fileToUpload);
      console.log("file name", this.fileToUpload.name);
    }


  openModalDeleteIndagine(modalDeleteIndagine: TemplateRef<any>, item) {
    this.bsModalRef = this.modalService.show(modalDeleteIndagine); //apre la modal
    this.noAnimazione = 0; //riattiviamo l'animazione

    //riempie la modal con i dati passati
    this.bsModalRef.content = {"id": item.id}
  }
  deleteIndagine(id) {
    console.log("Vogliamo eliminare l'indagine:  " + id);

    //chiamo l'api della delete passando id
    this.dataService.deleteIndagine(this.idSito, this.tipo, id).subscribe((data: any[])=>{
      console.log("Risultato delete api", data);

      //eliminiamo la riga nel template
      for( let i = 0; i < (this.sito.indagini).length; i++){
        if ( this.sito.indagini[i].id === id) {
          (this.sito.indagini).splice(i, 1);
        }
      }
      // //aggiungo l'alert
      // this.alerts.push({
      //     type: 'success',
      //     msg: "L'elemento <strong>" + ragioneSociale + "</strong> è stato eliminato con successo",
      //     timeout: 5000
      // });

      this.toastr.success( GlobalConstants.DELETE_INDAGINE_TESTO.replace("{id}",id), GlobalConstants.DELETE_INDAGINE_TITOLO);
      this.bsModalRef.hide(); //chiudo la modal
    })

  }



  onMapIndaginiReady(map: L.Map) { //quando la mappa è caricata
    console.log("map ready!");
  }
  gisDefault;
  sitiGis = [];
  indaginiGis = [];
  openModalAggiungiIndagineEsistente(modalIndagineEsistente: TemplateRef<any>, item) { // quando apriamo la modal di indagine esistente
    //noinspection TypeScriptValidateTypes
    this.bsModalRef = this.modalService.show(modalIndagineEsistente, {class: 'modal-xl'});

    this.dataService.getSitiGis(this.tipo)
      .subscribe(risposta => {
        console.log("gis dei siti sent", risposta);

        let arrayRisposta = Object.values(risposta);

        for (let i = 0; i < arrayRisposta.length; i++) {
          this.sitiGis.push(risposta[i].gis);
        }


        for (let i = 0; i < arrayRisposta.length; i++) {
          console.log("LAT",arrayRisposta[i].lat);
          console.log("LNG",arrayRisposta[i].lng);

          if (arrayRisposta[i].lat != null) {
            let markeer = marker([arrayRisposta[i].lat, arrayRisposta[i].lng], {
              icon: GlobalConstants.ICONA_MARKER
            });

            markeer.on('click', e => {
              this.zone.run(() => { //importantissimo!!!!
                this.gisDefault = arrayRisposta[i].gis;
                console.log("trigger gis", this.gisDefault);
              });
            });

            var popupContent = '<div class="info_content">' +
              '<h4>' + arrayRisposta[i].denominazione + '</h4>' +
              '</div>';

            //Aggiungiamo il popup
            //noinspection TypeScriptUnresolvedFunction
            markeer.bindPopup(popupContent);

            this.arrayMarkerIndagini.push(markeer);//aggiungiamo il marker all'array che verrà passato alla mappa
            this.arrayMarkerBoundsIndagini.push([arrayRisposta[i].lat, arrayRisposta[i].lng]); //aggiorniamo l'array per la centratura
          }
        }
        this.markerBoundsIndagini = this.arrayMarkerBoundsIndagini; //centriamo in base a i marker
      });

    this.dataService.getIndaginiGis(this.tipo)
      .subscribe(risposta => {
        console.log("indagini dei gis", risposta);
        this.indaginiGis = Object.values(risposta);
      });
  }

  onSubmitFormIndagineEsistente(formIndagineEsistente) { //quando si fà submit della form  (nella modal)
    this.submittedIndagineEsistente = true; // abbiamo fatto un submit, serve per la validazione
    console.log(formIndagineEsistente.invalid);
    console.log(formIndagineEsistente);
    console.log(this.indaginiSelezionate);
    if (formIndagineEsistente.invalid) { // stop here if formContatti is invalid
      return;
    }

    this.dataService.duplicaIndaginiEsistenti(this.idSito, this.tipo, this.indaginiSelezionate).subscribe(risposta => {
      console.log("Duplico indagini", risposta);


      for (let i = 0; i < (risposta).length; i++) {
        this.sito.indagini.push(risposta[i]); //aggiungo l'indagine alla lista
        this.toastr.success(GlobalConstants.CREATE_INDAGINE_TESTO.replace("{idIndagine}", risposta[i].id), GlobalConstants.CREATE_INDAGINE_TITOLO);
      }


      this.bsModalRef.hide(); //chiudo la modal
    })


    console.log("aggiungi indagine esistente");
  }

  indaginiSelezionate: any = {};
  defaultCheckboxIndagine = [];
  onChangeCheckboxIndagini(items, tipo, idIndagine) {
    if (items.target.checked) {
      this.indaginiSelezionate[idIndagine + "_"+ tipo] = {"tipo":tipo, "idIndagine":idIndagine};
    } else {
      delete this.indaginiSelezionate[idIndagine + "_"+ tipo];
    }
  }

  onChangeGis () {
    this.indaginiSelezionate = {};
    this.defaultCheckboxIndagine = [];
  }












    onAggiungiParametro(position,formIndagine) {
      console.log(position);

      this.arrayParametri.push({
        "parametroTipo" : "",
        "parametroValore" : "",
        "parametroProfTop" : "",
        "parametroProfBot" : "",
        "parametroSpessore" : "",
        "parametroQuotaSlmTop" : "",
        "parametroQuotaSlmBot" : "",
        "stratigrafia": [{"profonditaDa" : "", "profonditaA" : "", "spessore" : "", "siglaCarg" : "", "litologica" : ""}]
      });

      console.log(formIndagine);

      return;
    }
    onEliminaParametro(position, formIndagine) {
      console.log(position);

      //this.arrayParametri.splice(position,1);
      this.arrayParametri[position] = undefined;
      console.log(formIndagine.form);

      return;
    }
    onAggiungiStratigrafia(positionStrat,positionParam, formIndagine) {
      console.log(positionStrat);

      this.arrayParametri[positionParam].stratigrafia.push({"profonditaDa" : "", "profonditaA" : "", "spessore" : "", "siglaCarg" : "", "litologica" : ""});
      console.log(formIndagine);
      return;
    }
    onEliminaStratigrafia(positionStrat,positionParam, formIndagine) {
      console.log(positionStrat);

      //this.arrayParametri.splice(position,1);
      this.arrayParametri[positionParam].stratigrafia[positionStrat] = undefined;
      console.log(formIndagine.form);

      return;
    }


  // FILTRI
  confrontaFiltro(item, filterClasseIndagine, filterTipoIndagine, filterParametro): any {
    if ( (filterClasseIndagine !== null || filterTipoIndagine !== null || filterParametro !== null) &&
      (filterClasseIndagine !== null && item.classeInd !== filterClasseIndagine) ||
      (filterTipoIndagine !== null && item.tipoInd !== filterTipoIndagine) ||
      (filterParametro !== null && !item.parametri.find(o => o.tipoPar === filterParametro))
    ) {
      return true;
    } else {
      return false;
    }
  }



  leggiFileById(fileId) {
    //https://stackoverflow.com/questions/51682514/angular-how-to-download-a-file-from-httpclient
    this.httpClient.get(GlobalConstants.API_READ_FILE + fileId,{
      responseType: 'arraybuffer',headers:{'Content-Type': 'application/pdf'}}
    ).subscribe(response => {
      console.log("risposta",response);
      this.downLoadFile(response, "application/pdf")
    });
  }
  downLoadFile(data: any, type: string) {
    let blob = new Blob([data], { type: type});
    let url = window.URL.createObjectURL(blob);
    let pwa = window.open(url);
    console.log("url",url);
    if (!pwa || pwa.closed || typeof pwa.closed == 'undefined') {
      alert( 'Please disable your Pop-up blocker and try again.');
    }
  }

  onKeyUpA(coordX, coordY) {
        console.log(coordX.value, coordY.value);

        if (coordX.value != undefined && coordY.value != undefined) {
          this.markerIndirizzo = marker([coordX.value, coordY.value], {
            //draggable:true,
            icon: GlobalConstants.ICONA_MARKER
          });
          this.centerMap = latLng(coordX.value, coordY.value);
        }
    }
    onKeyUpB(BcoordX, BcoordY) {
        console.log(BcoordX.value, BcoordY.value);
        if (BcoordX.value != undefined && BcoordY.value != undefined) {
          this.markerIndirizzo_B = marker([BcoordX.value, BcoordY.value], {
            //draggable:true,
            icon: GlobalConstants.ICONA_MARKER
          });
          this.centerMap_B = latLng(BcoordX.value, BcoordY.value);
        }
    }

    ngOnDestroy() {
        this.sub.unsubscribe();
    }

}
