<section id="actions" class="mt-4 mb-4">
  <div class="container-fluid">
    <div class="row align-items-center">
      <div class="col-12 col-md">
        <h1>Storico Siti indagini
        </h1>
      </div>

    </div>
  </div>
</section>

<section id="search" class="mb-3">
  <div class="container-fluid">
    <div class="row">
      <div class="col text-right">
        <div class="card text-left">
          <div class="card-body p-2">
            <div class="row align-items-center">
              <div class="col text-left pb-2 mb-2 border-bottom">
                <span class="">Cerca tra lo storico</span>
              </div>
            </div>
            <div id="search-collapse">
              <form>
                <div class="form-row">
                  <div class="col-12 col-md-4">
                    <label for="cerca">Testo libero</label>
                    <input id="cerca" name="cerca" type="text" class="form-control cercaFilter" placeholder="Cerca per Titolo, Indirizzo o Utente" #filterCerca="ngModel" [(ngModel)]="filtroCerca">
                  </div>


                  <div class="col-6 col-md-4">
                    <label>Comune</label>
                    <ng-select class="" name="comuni" #comuni="ngModel" [ngModel] [clearable]="true">
                      <ng-option  *ngFor="let item of storicoComuniList" value="{{ item.comune }}">{{ item.comune }}</ng-option>
                    </ng-select>
                  </div>

                  <div *ngIf="comuni.value != null && comuni.value != ''" class="col-6 col-md-4 text-center">
                    <label style="visibility: hidden; width: 100%">zip</label>
<!--
                    <a href="file-comuni/{{ convertInSlug(comuni.value) }}.zip" class="btn btn-primary" style="color: white">
                      <fa-icon [icon]="['fas', 'download']"></fa-icon>
                      <small> Scarica le indagini del comune di {{ comuni.value }} (.zip)</small>
                    </a>
-->
                    <a href="javascript: void(0)" class="btn btn-primary" style="color: white" (click)="leggiZipById(convertInSlug(comuni.value) + '.zip', convertInSlug(comuni.value))">
                      <fa-icon [icon]="['fas', 'download']"></fa-icon>
                      <small> Scarica le indagini del comune di {{ comuni.value }} (.zip)</small>
                    </a>

                  </div>


                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<section id="list" class="request">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <div class="table-responsive-sm">
          <table class="table table-bordered text-left">
            <thead>
            <tr>
              <th scope="col" class="text-center" style="width:3%">
                #ID <a href="" class="sortById desc"><i class="fas fa-caret-down"></i></a>
              </th>
              <th scope="col">Titolo</th>
              <th scope="col" style="width: 8%;">Comune</th>
              <th scope="col">Indirizzo</th>
              <th scope="col">Utente</th>
              <th scope="col">Data inserimento</th>

              <th scope="col" class="text-center">
                Azioni
              </th>
            </tr>
            </thead>
            <tbody>
            <tr class="task-list-row" *ngFor="let item of storicoList | filtraLista : filterCerca.value : comuni.value | paginate: {id: 'advanced', itemsPerPage: 20, currentPage: currentPage}" [hidden]="confrontaFiltro(item, filterCerca.value, comuni.value)">
              <td scope="row" class="text-center row-id">
                {{ item.idIntervento }}
              </td>
              <td class="title text-uppercase">
                <h2>{{ item.titolo }}</h2>
              </td>
              <td class="title text-uppercase">
                {{ item.comune }} ({{ item.provincia }})
              </td>
              <td class="title text-uppercase">
                {{ item.indirizzo }}
                <span *ngIf="item.frazione != ''"> ({{ item.frazione }})</span>
              </td>
              <td class="title">
                {{ item.username }}
              </td>
              <td class="">
                {{ item.data_inserimento | date: 'dd/MM/yyyy' }}
              </td>


              <td class="actions text-center">
                <a routerLink="/storico/{{ item.idIntervento}}" class="ml-2 mr-2" title="" tooltip="Visualizza indagine">
                  <fa-icon [icon]="['fas', 'eye']"></fa-icon>
                </a>
              </td>
            </tr>

            </tbody>
          </table>


          <div class="alert alert-danger no-result-filter" style="display: none;">
            Nessuna richiesta trovata con i seguenti filtri
          </div>

        </div>
      </div>
    </div>
  </div>

  <div class="has-text-centered text-center">
    <pagination-controls [id]="'advanced'"
                         [maxSize]="10"
                         [directionLinks]="true"
                         [autoHide]="false"
                         [responsive]="true"
                         [previousLabel]="'Previous'"
                         [nextLabel]="'Next'"
                         [screenReaderPaginationLabel]="'Pagination'"
                         [screenReaderPageLabel]="'Page'"
                         [screenReaderCurrentLabel]="'You\'re on page'"
                         (pageChange)="onPageChange($event)"
                         (pageBoundsCorrection)="onPageBoundsCorrection($event)"></pagination-controls>
  </div>

</section>

