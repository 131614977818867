import { Component, OnInit } from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {LocalStorageService} from "angular-web-storage";
import {DataService} from "../../service/data.service";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import {ToastrService} from "ngx-toastr";
import {Router, ActivatedRoute} from "@angular/router";
import {GlobalConstants} from "../../global-constants";
import jwt_decode from "jwt-decode";

@Component({
  selector: 'app-scheda-utenti',
  templateUrl: './scheda-utenti.component.html',
  styleUrls: ['./scheda-utenti.component.less']
})
export class SchedaUtentiComponent implements OnInit {

  constructor(private httpClient: HttpClient, private local: LocalStorageService, private dataService: DataService, private modalService: BsModalService, private toastr: ToastrService, private router: Router, private route: ActivatedRoute) { }

  name: string = "";
  lastname: string = "";
  submitted = false;

  provinceTemp;
  comuniProvince;
  comuniProvinceStudio;
  province;
  condizioniValue = false;
  bsModalRef: BsModalRef; //oggetto della modal

  utente: any = {gruppo:{role:''}};
  idUtente;
  logged = false;
  decodedJwt = null;

  ngOnInit(): void {
    this.provinceTemp = this.local.get("province");
    if (this.provinceTemp == null) {
      console.log("api NON in localStorage, fare la chiamata");
      this.httpClient.get(GlobalConstants.API_PROVINCE).subscribe((risposta: any) => {
        this.province = risposta;
        this.local.set("province", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
      });
    } else {
      console.log("api presa da localStorage");
      this.province = this.local.get("province"); //prendo l'api dal localStorage
    }
    console.log("province", this.province);

    this.idUtente = this.route.snapshot.paramMap.get('id');
    console.log('idUtente', this.idUtente);

    this.httpClient.get(GlobalConstants.API_UTENTI + '/' + this.idUtente).subscribe((risposta: any) => {
      this.utente = risposta;
      console.log('utente', this.utente);

      this.dataService.getComuniProvincia(this.utente.provincia)
        .subscribe(risposta => {
          console.log("comuni comuniProvince", risposta);
          this.comuniProvince = risposta;
        });
      this.dataService.getComuniProvincia(this.utente.studioProvincia)
        .subscribe(risposta => {
          console.log("comuni comuniProvinceStudio", risposta);
          this.comuniProvinceStudio = risposta;
        });
    });



    if (this.dataService.isAuthenticated()) {
      this.logged = true;
      if (this.decodedJwt != null) {
        this.decodedJwt = jwt_decode(this.decodedJwt);
      } else {
        var tok = localStorage.getItem('jwt');
        this.decodedJwt = jwt_decode(tok);// decodifica il token jwt per prendere le sue info
      }
      console.log("decodedJwt", this.decodedJwt.userId);
    }
    // solo amministratori
    if (this.decodedJwt != null && this.decodedJwt.roles['role'] == 'ROLE_USER' && this.decodedJwt.userId != this.idUtente) {
      this.router.navigate(['/login']);
    }


  }

  onChangeProvincia(provincia): any {
    this.utente.comune = '';
    this.dataService.getComuniProvincia(provincia)
      .subscribe(risposta => {
        console.log("comuni provincia", risposta);
        this.comuniProvince = risposta;
      });
  }
  onChangeProvinciaStudio(provinciaStudio): any {
    this.utente.studioComune = '';
    this.dataService.getComuniProvincia(provinciaStudio)
      .subscribe(risposta => {
        console.log("comuni provinciaStudio", risposta);
        this.comuniProvinceStudio = risposta;
      });
  }


  onSubmit(formUtente): any {
    this.submitted = true; // abbiamo fatto un submit, serve per la validazione
    console.log(formUtente.invalid);
    console.log(formUtente);
    if (formUtente.invalid || (formUtente.form.value.password != formUtente.form.value.confermaPassword && formUtente.form.value.password != '' && formUtente.form.value.confermaPassword != '')) { // stop here if formContatti is invalid
      return;
    }

    var valori = formUtente.form.value; //prendo i valori inseriti nella form
    console.log("valori form", valori);


    this.dataService.createUpdateUtente(this.idUtente, valori).subscribe((risposta: any) => {
      console.log("risposta update/create ", Object.values(risposta));

      if (risposta.id == 'exist') {
        this.toastr.error( GlobalConstants.USERNAME_EXIST_TESTO.replace("{username}",risposta.username), GlobalConstants.USERNAME_EXIST_TITOLO);
        return;
      }

      if (this.idUtente === 'new') {
        this.toastr.success(GlobalConstants.CREATE_UTENTE_TESTO.replace("{username}", risposta.username), GlobalConstants.CREATE_UTENTE_TITOLO);
        this.router.navigate(['/utenti/' + risposta.id])
          .then(() => {
            window.location.reload();//forza reload
          });
      } else {
        this.toastr.success( GlobalConstants.UPDATE_UTENTE_TESTO.replace("{username}",risposta.username), GlobalConstants.UPDATE_UTENTE_TITOLO);
      }

    });

  }

}
