import {Component, OnInit} from '@angular/core';
import {GlobalConstants} from '../../global-constants';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute} from '@angular/router';
import * as L from 'leaflet';
import {icon, latLng, Map, marker, point, polyline, tileLayer, latLngBounds, featureGroup, circle} from 'leaflet';
import {LocalStorageService} from "angular-web-storage";
import {DatePipe} from '@angular/common'

@Component({
    selector: 'app-scheda-comuni',
    templateUrl: './scheda-comuni.component.html',
    styleUrls: ['./scheda-comuni.component.less']
})
export class SchedaComuniComponent implements OnInit {

    constructor(private httpClient: HttpClient, private route: ActivatedRoute, private local: LocalStorageService, public datepipe: DatePipe) {
    }

    comune;
    id: number;
    private sub: any;
    optionMap = GlobalConstants.OPTION_MAP; //vedere in costanti
    arrayMarker = []; //array di marker: sono i marker che verranno aggiunti alla mappa
    arrayMarkerBounds = []; //array di appoggio che verrà passato a markerBounds
    markerBounds: [number, number][]; //array di coordinate su cui si andrà a centrare la mappa (coordinate, non marker!)
    map: L.Map;
    arraySiti = [];

    classeIndagine = [];
    tipoIndagine = [];
    filtroSisma = "";
    filtroEdilizia = "";
    filtroClasseIndagine = "";
    filtroTipoIndagine = "";
    filtroDataDa = "";
    filtroDataA = "";
    des_classe_indagine;
    des_tipo_indagine;

    ngOnInit(): void {

        this.des_classe_indagine = this.local.get("des_classe_indagine");
        this.des_tipo_indagine = this.local.get("des_tipo_indagine");

        //############################################ des_classe_indagine -----> CONTROLLO API IN LOCALSTORAGE
        if (this.des_classe_indagine == null) {
          console.log("api NON in localStorage, fare la chiamata");
          this.httpClient.get(GlobalConstants.API_DES_CLASSE_INDAGINE).subscribe((risposta: any) => {
            console.log(risposta);
            this.classeIndagine = risposta;
            this.local.set("des_classe_indagine", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
          });
        } else {
          console.log("api presa da localStorage");
          this.classeIndagine = this.local.get("des_classe_indagine"); //prendo l'api dal localStorage
        }

        //############################################ des_tipo_indagine -----> CONTROLLO API IN LOCALSTORAGE
        if (this.des_tipo_indagine == null) {
          console.log("api NON in localStorage, fare la chiamata");
          this.httpClient.get(GlobalConstants.API_DES_TIPO_INDAGINE).subscribe((risposta: any) => {
            console.log(risposta);
            this.tipoIndagine = risposta;
            this.local.set("des_tipo_indagine", risposta, 60, 's'); //salvo il risultato dell'api nel localStorage (dura 60 secondi in questo caso)
          });
        } else {
          console.log("api presa da localStorage");
          this.tipoIndagine = this.local.get("des_tipo_indagine"); //prendo l'api dal localStorage
        }



        //per prendere il parametro (id in questo caso) dalla url
          this.sub = this.route.params.subscribe(params => {
              //console.log("params", params['id']);
              this.id = params['id']; // (+) converts string 'id' to a number

              this.httpClient.get(GlobalConstants.API_COMUNI + "/" + this.id).subscribe((risposta: any) => {
                  console.log("risposta", risposta);
                  this.comune = risposta;
                  this.arraySiti = risposta.sitiLineari.concat(risposta.sitiPuntuali);

                  console.log("marker siti", risposta.sitiLineari.concat(risposta.sitiPuntuali));
                  //this.arrayMarker = risposta.sitiLineari.concat(risposta.sitiPuntuali);

                  this.aggiungiMarkerAllaMappa("","","","","","");
              });
          });

      }


    onMapReady(map: L.Map) { //quando la mappa è caricata
        console.log("map ready!");
    }


    aggiungiMarkerAllaMappa(filtroSisma, filtroEdilizia, filtroClasseIndagine, filtroTipoIndagine, filtroDataDa, filtroDataA) { //scorriamo la lista delle anagrafiche (responseData) e per ognuno aggiungiamo un marker
        for (let i = 0; i < this.arraySiti.length; i++) {
            if (this.arraySiti[i].lat != null) {
                let mark = marker([this.arraySiti[i].lat, this.arraySiti[i].lng], {
                    icon: GlobalConstants.ICONA_MARKER
                });

                var sisma = "";
                var edilizia = "";
                var tipo = "";
                if (this.arraySiti[i].edilizia == "microzonazione") {
                    edilizia = "Microzonazione"
                }
                if (this.arraySiti[i].sisma == "sisma-2009") {
                    sisma = "Sisma 2009"
                }
                if (this.arraySiti[i].sisma == "sisma-2016") {
                    sisma = "Sisma 2016"
                }
                if (this.arraySiti[i].sisma == "nosisma") {
                    sisma = "No Sisma"
                }
                if (this.arraySiti[i].edilizia == "privata") {
                    edilizia = "Edilizia Privata"
                }
                if (this.arraySiti[i].edilizia == "pubblica") {
                    edilizia = "Edilizia Pubblica"
                }
                if (this.arraySiti[i].edilizia == 'int-pubblico') {
                    edilizia = "Intervento Pubblico"
                }
                if (this.arraySiti[i].tipo == 'puntuale') {
                    tipo = "puntuali";
                }
                if (this.arraySiti[i].tipo == 'lineare') {
                    tipo = "lineari";
                }

                var popupContent = '<div class="info_content">' +
                    '<h4>' + this.arraySiti[i].denominazione + '</h4>' +
                    '<p><strong>Sito '+ this.arraySiti[i].tipo +':</strong><br>' +
                    edilizia + ' | ' + sisma + '<br>'+
                    'Coordinate X: ' + this.arraySiti[i].lat + '<br>'+
                    'Coordinate Y: ' + this.arraySiti[i].lng + '</p><hr>'+
                    'Numero indagini: ' + this.arraySiti[i].countIndagini + '<hr>'+
                    '<p><a href="/siti/' + tipo + '/' + this.arraySiti[i].id + '/vista"><strong><i class="fas fa-caret-right"></i> Dettaglio</strong></p>' +
                    '</div>';

                //Aggiungiamo il popup
                //noinspection TypeScriptUnresolvedFunction
                mark.bindPopup(popupContent);


              var classeL = [];
              var tipoL = [];

              classeL = (this.arraySiti[i].indagini).filter(function (person) {
                return person.classe == filtroClasseIndagine
              });
              tipoL = (this.arraySiti[i].indagini).filter(function (person) {
                return person.tipo == filtroTipoIndagine
              });


              this.arraySiti[i].edilizia
              if ((this.arraySiti[i].sisma == filtroSisma || filtroSisma == "") &&
                  (this.arraySiti[i].edilizia == filtroEdilizia || filtroEdilizia == "") &&
                  (classeL.length > 0 || filtroClasseIndagine == "") &&
                  (tipoL.length > 0 || filtroTipoIndagine == "") &&

                ((this.arraySiti[i].dataCertificato != null && this.arraySiti[i].dataCertificato.substring(0, 10)  >= filtroDataDa || filtroDataDa == "" ) &&
                  (this.arraySiti[i].dataCertificato != null && this.arraySiti[i].dataCertificato.substring(0, 10)  <= filtroDataA || filtroDataA == "" ))
              ) {

                  this.arrayMarker.push(mark);//aggiungiamo il marker all'array che verrà passato alla mappa
                  this.arrayMarkerBounds.push([this.arraySiti[i].lat, this.arraySiti[i].lng]); //aggiorniamo l'array per la centratura
              }
            }
        }

        this.markerBounds = this.arrayMarkerBounds; //centriamo in base a i marker
    }





    // FILTRI

  onChangeFiltroEdilizia(filtro) {
    console.log("filtro", filtro.value);
    this.filtroEdilizia = filtro.value;
    this.arrayMarker = [];
    this.aggiungiMarkerAllaMappa(this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
  }

  onChangeFiltroSisma(filtro) {
    console.log(filtro);
    this.filtroSisma = filtro.value;
    this.arrayMarker = [];
    this.aggiungiMarkerAllaMappa(this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
  }

  onChangeFiltroClasseIndagine(filtro) {
    console.log(filtro);
    this.filtroClasseIndagine = filtro.value;
    this.arrayMarker = [];
    this.aggiungiMarkerAllaMappa(this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
  }

  onChangeFiltroTipoIndagine(filtro) {
    console.log(filtro);
    this.filtroTipoIndagine = filtro.value;
    this.arrayMarker = [];
    this.aggiungiMarkerAllaMappa(this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
  }

  onFilterDAchange(value: Date) {
    let dataFormattata = this.datepipe.transform(value, 'yyyy-MM-dd');
    console.log(dataFormattata);
    this.filtroDataDa = dataFormattata;
    this.arrayMarker = [];
    this.aggiungiMarkerAllaMappa(this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
  }

  onFilterAchange(value: Date) {
    let dataFormattata = this.datepipe.transform(value, 'yyyy-MM-dd');
    console.log(dataFormattata);
    this.filtroDataA = dataFormattata;
    this.arrayMarker = [];
    this.aggiungiMarkerAllaMappa(this.filtroSisma, this.filtroEdilizia, this.filtroClasseIndagine, this.filtroTipoIndagine, this.filtroDataDa, this.filtroDataA);
  }


}
