<div ngxUiLoaderBlurred>

  <!-- i component delle route -->
  <router-outlet></router-outlet>

</div>

<!-- il loader -->
<ngx-ui-loader></ngx-ui-loader>

