import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {DefaultTemplateComponent} from "./_layout/default-template/default-template.component";
import {PageNotFoundComponent} from "./shared/component/page-not-found/page-not-found.component";
import {HomeComponent} from "./home/home.component";
import {ListaComuniComponent} from "./comuni/lista-comuni/lista-comuni.component";
import {SchedaComuniComponent} from "./comuni/scheda-comuni/scheda-comuni.component";
import {ComuniResolverService} from "./comuni/lista-comuni/comuni-resolver.service";
import {VistaSitiComponent} from "./siti/vista-siti/vista-siti.component";
import {VistaIndaginiComponent} from "./siti/vista-indagini/vista-indagini.component";
import {LoginComponent} from "./login/login.component";
import {ListaSitiComponent} from "./siti/lista-siti/lista-siti.component";
import {AuthGuardService as AuthGuard} from './auth-guard.service';
import {SchedaSitiComponent} from "./siti/scheda-siti/scheda-siti.component";
import {RegistrationComponent} from "./registration/registration.component";
import {RecuperaPasswordComponent} from "./recupera-password/recupera-password.component";
import {SchedaUtentiComponent} from "./utenti/scheda-utenti/scheda-utenti.component";
import {ListaUtentiComponent} from "./utenti/lista-utenti/lista-utenti.component";
import {ListaStoricoComponent} from "./siti/lista-storico/lista-storico.component";
import {VistaStoricoComponent} from "./siti/vista-storico/vista-storico.component";
import {ListaMicrozonazioneComponent} from "./siti/lista-microzonazione/lista-microzonazione.component";
import {AttivazioneComponent} from "./googleAuthenticator/attivazione/attivazione.component";
import {VerificaComponent} from "./googleAuthenticator/verifica/verifica.component";

const routes: Routes = [
  { path: '',
    component: DefaultTemplateComponent,
    children: [
      // { path: 'pagina-protetta', component: PaginaProtettaComponent },
       { path: 'siti/:tipo/:idSito/indagini/:idIndagine/vista', component: VistaIndaginiComponent },
       { path: 'siti/:tipo/:id/vista', component: VistaSitiComponent },
       { path: 'siti/:tipo/:id', component: SchedaSitiComponent, canActivate: [AuthGuard] },
       { path: 'storico/:id', component: VistaStoricoComponent, canActivate: [AuthGuard] },
       { path: 'microzonazione', component: ListaMicrozonazioneComponent, canActivate: [AuthGuard] },
       { path: 'storico', component: ListaStoricoComponent, canActivate: [AuthGuard] },
       { path: 'comuni', component: ListaComuniComponent,   resolve: {singlePost: ComuniResolverService} },
       { path: 'comuni/:id', component: SchedaComuniComponent },
       { path: 'utenti/:id', component: SchedaUtentiComponent, canActivate: [AuthGuard] },
       { path: 'utenti', component: ListaUtentiComponent, canActivate: [AuthGuard] },
       { path: 'registration', component: RegistrationComponent },
       { path: 'recuperaPassword', component: RecuperaPasswordComponent },
       { path: 'login', component: LoginComponent },
       { path: 'siti', component: ListaSitiComponent, canActivate: [AuthGuard] },
       { path: 'attivazioneGA', component: AttivazioneComponent },
       { path: 'verificaGA', component: VerificaComponent },
      // { path: 'utenti/:id', component: UtentiComponent },
      { path: '404', component: PageNotFoundComponent },
      { path: '',   component: HomeComponent }, // redirect to `first-component`
    ]
  },
  { path: '**', component: PageNotFoundComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'legacy' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }

