<div class="container h-75">

  <div class="row justify-content-center h-100 login-form">
    <div class="col col-sm col-md-6 my-auto">
      <div class="card">
        <div class="card-header bg-white">
          <img src="assets/header-ricevuta.png" class="d-inline-block align-top w-100" alt="">
        </div>
        <div class="card-body">
          <h5 class="cart-title">
            Google Authenticator - @Geologi<br>
            <small>
              Scarica l'app <strong><a href="https://support.google.com/accounts/answer/1066447?hl=it&co=GENIE.Platform%3DAndroid&oco=0" target="_blank">Google Authenticator</a></strong> sul tuo telefono e proteggi il tuo account con la verifica a due passaggi 2FA.
              <br>Aggiungi questo dispositivo:
            </small>
          </h5>

          <hr>
          <div class="text-center">
            <img src="{{ qrCode }}" />
            <h5 class="mt-2">{{ secret }}</h5>
          </div>


          <form id="formGA" class="form" #formGA="ngForm" (ngSubmit)="onSubmit(formGA)">

            <input type="hidden"  #fieldUsername="ngModel" [(ngModel)]="jwtGA.username" name="username">
            <input type="hidden"  #fieldQrCode="ngModel" [(ngModel)]="qrCode" name="qrCode">
            <input type="hidden"  #fieldSecret="ngModel" [(ngModel)]="secret" name="secret">
            <input type="hidden"  #fieldP="ngModel" [(ngModel)]="jwtGA.p" name="p">

            <div class="alert alert-warning no-result-filter mt-3">
              Attenzione, ricordati di effettuare un backup di questo codice senza il quale non potrai più eseguire l'accesso. <br> In caso di smarrimento contattare l'amministratore.
            </div>


            <div class="row align-items-center">
              <div class="col-md-3">
                <button id="btn-login" class="btn btn-success">Avanti</button>
              </div>
            </div>

          </form>
        </div>
      </div>
    </div>

  </div>
</div>
