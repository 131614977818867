import { Component, OnInit } from '@angular/core';
import {GlobalConstants} from '../../global-constants';
import {HttpClient, HttpHeaders, HttpErrorResponse} from "@angular/common/http";
import {ActivatedRoute} from '@angular/router';
import {icon, latLng, Map, marker, point, polyline, tileLayer, latLngBounds, featureGroup, circle} from 'leaflet';

@Component({
  selector: 'app-vista-siti',
  templateUrl: './vista-siti.component.html',
  styleUrls: ['./vista-siti.component.less']
})
export class VistaSitiComponent implements OnInit {

  constructor(private httpClient: HttpClient, private route: ActivatedRoute) { }
  private sub: any;
  id: number;
  tipo: string;
  sito : any;
  indagini : any;
  optionMap = GlobalConstants.OPTION_MAP; //vedere in costanti
  arrayMarker = []; //array di marker: sono i marker che verranno aggiunti alla mappa
  arrayMarkerBounds = []; //array di appoggio che verrà passato a markerBounds
  markerBounds: [number, number][]; //array di coordinate su cui si andrà a centrare la mappa (coordinate, non marker!)

  ngOnInit(): void {

    //per prendere il parametro (id in questo caso) dalla url
    this.sub = this.route.params.subscribe(params => {
      //console.log("params", params['id']);
      this.id = params['id']; // (+) converts string 'id' to a number
      this.tipo = params['tipo'];
      console.log("this.tipo", this.tipo);


      if (this.tipo == "lineari") {
        this.httpClient.get(GlobalConstants.API_SITI_LINEARI + "/" + this.id).subscribe((risposta: any) => {
          console.log("risposta", risposta);

          this.sito = risposta;
          this.indagini = risposta.indagini;
          this.aggiungiMarkerAllaMappa(this.sito);
        });
      }
      if (this.tipo == "puntuali") {
        this.httpClient.get(GlobalConstants.API_SITI_PUNTUALI + "/" + this.id).subscribe((risposta: any) => {
          console.log("risposta", risposta);

          this.sito = risposta;
          this.indagini = risposta.indagini;
          this.aggiungiMarkerAllaMappa(this.sito);
        });
      }


    });
  }


  onMapReady(map: L.Map) { //quando la mappa è caricata
    console.log("map ready!");
  }


  aggiungiMarkerAllaMappa(sito) { //scorriamo la lista delle anagrafiche (responseData) e per ognuno aggiungiamo un marker
    console.log("sito", sito);
    if (sito.lat != null) {
      let mark = marker([sito.lat, sito.lng], {
        icon: GlobalConstants.ICONA_MARKER
      });

      this.arrayMarker.push(mark);//aggiungiamo il marker all'array che verrà passato alla mappa
      this.arrayMarkerBounds.push([sito.lat, sito.lng]); //aggiorniamo l'array per la centratura
    }

    this.markerBounds = this.arrayMarkerBounds; //centriamo in base a i marker
  }


}
