<section id="head" class="mt-2">
    <div class="container-fluid">
        <div class="row align-items-center">
            <div class="col-12 col-md-8">
                <h3>Comuni</h3>

            </div>
            <div class="col-12 col-md">
                <div class="card">
                    <div class="card-body text-center">
                        <div class="row">
                            <div class="col-12 col-md">
                                Comuni
                                <h3>{{ numeroComuni }}</h3>
                            </div>
                            <div class="col-12 col-md">
                                Siti <i class="fal fa-info-circle" data-toggle="tooltip" data-placement="top" title="{{ numeroSitiLineari }} lineari e {{ numeroSitiPuntuali }} puntuali"
                                        data-html="true"></i>
                                <h3>{{ numeroSitiLineari + numeroSitiPuntuali }}</h3>
                            </div>
                            <div class="col-12 col-md">
                                Indagini <i class="fal fa-info-circle" data-toggle="tooltip" data-placement="top" title="{{ numeroIndaginiLineari }} lineari e {{ numeroIndaginiPuntuali }} puntuali"
                                            data-html="true"></i>
                                <h3>{{ numeroIndaginiLineari + numeroIndaginiPuntuali }}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

</section>


<section id="elenco" class="mt-4 mb-4" style="background: #fff;">
    <div class="table-responsive">
        <table class="table">
            <thead>
            <tr>
                <th class="headerSort" tabindex="0" role="columnheader button" title="Ordinamento crescente">Comune</th>
                <th class="headerSort" tabindex="0" role="columnheader button" title="Ordinamento crescente">Provincia</th>
                <th class="headerSort" tabindex="0" role="columnheader button" title="Ordinamento crescente">Siti puntuali</th>
                <th class="headerSort" tabindex="0" role="columnheader button" title="Ordinamento crescente">Siti lineari</th>
                <th class="headerSort" tabindex="0" role="columnheader button" title="Ordinamento crescente">Indagini</th>
                <th class="headerSort" tabindex="0" role="columnheader button" title="Ordinamento crescente"></th>
            </tr>
            </thead>
            <tbody>
            <tr *ngFor="let item of comuniList">
                <td><a [routerLink]="['/comuni', item.id]" title="{{ item.comune }}">{{ item.comune }} [ISTAT {{item.istat}}]</a></td>
                <td>{{item.provincia}}</td>
                <td>{{ item.numSitiPuntuali }}</td>
                <td>{{ item.numSitiLineari }}</td>
                <td>{{ item.numeroIndaginiLineari + item.numeroIndaginiPuntuali }}</td>
                <td><a href="#"><i class="fal fa-eye"></i></a></td>
            </tr>

            </tbody>
            <tfoot></tfoot>
        </table>
    </div>
</section>

<!--
<pre>{{ comuniList | json}}</pre>
-->
