<div class="container h-75">

    <div class="row justify-content-center h-100 login-form">
        <div class="col col-sm col-md-6 my-auto">
            <div class="card">
                <div class="card-header bg-white">
                    <img src="assets/header-ricevuta.png" class="d-inline-block align-top w-100" alt="">
                </div>
                <div class="card-body">
                    <h5 class="cart-title">
                        Login @Geologi<br>
                        <small>Accesso riservato ai tecnici per il caricamento delle indagini geologiche</small>
                    </h5>

                    <hr>

                    <form id="formLogin" class="form" #formLogin="ngForm" (ngSubmit)="onSubmit(formLogin)">

                        <div class="row">
                            <div class="form-group col">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                      <div class="input-group-text"><fa-icon [icon]="['fas', 'at']"></fa-icon></div>
                                    </div>
                                    <input type="text" class="form-control" name="username" #username="ngModel" [(ngModel)]="usernameUtente" [ngClass]="{ 'is-invalid': (submitted && username.invalid )}" required placeholder="Enter username">
                                </div>
                            </div>
                        </div>
                        <div class="row">
                            <div class="form-group col">
                                <div class="input-group mb-2">
                                    <div class="input-group-prepend">
                                        <div class="input-group-text"><fa-icon [icon]="['fas', 'lock']"></fa-icon></div>
                                    </div>
                                    <input required type="password" class="form-control" name="password" #password="ngModel" [(ngModel)]="passwordUtente" [ngClass]="{ 'is-invalid': (submitted && password.invalid )}" required placeholder="Enter password">
                                </div>
                            </div>
                        </div>
<!--
                        <div class="input-group">
                            <div class="checkbox">
                                <label>
                                    <input id="login-remember" type="checkbox" name="remember" value="1"> Ricordami
                                </label>
                            </div>
                        </div>
-->
                        <div class="row align-items-center">
                            <div class="col-md-3">
                                <button id="btn-login" class="btn btn-success">Login</button>
                            </div>
                            <div class="col-md-9 text-right">
                                <a routerLink="/registration" class="pt-1"><strong>Registrati</strong></a> |
                                <a routerLink="/recuperaPassword" class="pt-1">Recupera Password</a> |
                                <!--a href="#" class="pt-1"><i class="fal fa-info-square"></i> Manuale</a-->
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>

    </div>
</div>
