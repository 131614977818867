import {Component, OnInit, TemplateRef} from '@angular/core';
import {HttpClient} from "@angular/common/http";
import {ActivatedRoute, Router} from "@angular/router";
import {DataService} from "../../service/data.service";
import {LocalStorageService} from "angular-web-storage";
import {DatePipe} from "@angular/common";
import {BsModalRef, BsModalService} from "ngx-bootstrap/modal";
import jwt_decode from "jwt-decode";
import {ToastrService} from "ngx-toastr";
import {GlobalConstants} from '../../global-constants';

@Component({
  selector: 'app-lista-utenti',
  templateUrl: './lista-utenti.component.html',
  styleUrls: ['./lista-utenti.component.less']
})
export class ListaUtentiComponent implements OnInit {

  constructor(private httpClient: HttpClient, private route: ActivatedRoute, private router: Router, private dataService: DataService, private local: LocalStorageService, public datepipe: DatePipe, private modalService: BsModalService, private toastr: ToastrService) {}

  utentiList = [];
  logged = false;
  decodedJwt = null;
  filtroCerca = '';
  filtroStato = '';
  bsModalRef: BsModalRef; //oggetto della modal
  noAnimazione = 0; //serve per non far eseguire l'animazione in caso di modifica (solo in delete)

  ngOnInit(): void {

    if (this.dataService.isAuthenticated()) {
      this.logged = true;
      if (this.decodedJwt != null) {
        this.decodedJwt = jwt_decode(this.decodedJwt);
      } else {
        var tok = localStorage.getItem('jwt');
        this.decodedJwt = jwt_decode(tok);// decodifica il token jwt per prendere le sue info
      }
      console.log("decodedJwt", this.decodedJwt.userId);
    }
    // solo amministratori
    if (this.decodedJwt.roles['role'] == 'ROLE_USER') {
      this.router.navigate(['/login']);
    }

    this.dataService.getListaUtenti(this.decodedJwt.userId).subscribe((risposta: any) => {
      console.log("risposta", Object.values(risposta));
      this.utentiList = Object.values(risposta);
    });

  }



  // FILTRI
  confrontaFiltro(item, filtroCerca, filtroStato): any {
    if ( (filtroCerca !== '' || filtroStato !== null) &&
      (filtroCerca !== '' &&
        ( (!item.nome.toLowerCase().includes(filtroCerca.toLowerCase())) &&
          (!item.cognome.toLowerCase().includes(filtroCerca.toLowerCase())) &&
          (!item.username.toLowerCase().includes(filtroCerca.toLowerCase())) && filtroCerca.length >= 3)
      ) ||
      (filtroStato !== '' && (item.isActive + '') !== filtroStato) ) {
      return true;
    } else {
      return false;
    }
  }

  // DELETE
  openModalDeleteUtente(modalDeleteUtente: TemplateRef<any>, item) {
    this.bsModalRef = this.modalService.show(modalDeleteUtente); //apre la modal
    this.noAnimazione = 0; //riattiviamo l'animazione

    //riempie la modal con i dati passati
    this.bsModalRef.content = item;
  }
  deleteUtente(id) {
    console.log("Vogliamo eliminare utente:  " + id);

    //chiamo l'api della delete passando id
    this.dataService.deleteUtente(id).subscribe((data: any[])=>{
      console.log("Risultato delete api", data);

      // redirect
      this.router.navigate(['/utenti'])
        .then(() => {
          window.location.reload();//forza reload
        });

      this.toastr.success( GlobalConstants.DELETE_UTENTE_TESTO.replace("{id}",id), GlobalConstants.DELETE_UTENTE_TITOLO);
      this.bsModalRef.hide(); //chiudo la modal
    })

  }


}
