<section id="head" class="mt-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-12 col-md text-center">
        <h3>Registrati</h3>
        Registrati al portale Indagini Geologiche per poter inviare le tue relazioni ed indagini.<br>
        <em>Tutti i campi contrassegnati con asterisco (*) sono obbligatori.</em>
      </div>
    </div>
  </div>
</section>

<hr>
<section id="registrati" class="mt-4 mb-4">
  <div class="container">
    <div class="row">
      <div class="col-8 col-md text-center">

        <form id="formRegistration" class="form" #formRegistration="ngForm" (ngSubmit)="onSubmit(formRegistration)">
          <div class="card text-left">
            <div class="card-header">
              DATI PERSONALI
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-md">
                  <label for="name">Nome *</label>
                  <input type="text" class="form-control" id="name" name="name" #name="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && name.invalid )}" required>
                </div>
                <div class="col-md">
                  <label for="lastname">Cognome *</label>
                  <input type="text" class="form-control" id="lastname" name="lastname" #lastname="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && lastname.invalid )}" required>
                </div>
                <div class="col-md">
                  <label>Ordine professionale *</label>
                  <ng-select class="" name="tipoUtente" #tipoUtente="ngModel" [ngModel] [clearable]="false" [ngClass]="{ 'is-invalid': (submitted && tipoUtente.invalid )}" required>
                    <ng-option value="geologi">Geologi</ng-option>
                    <ng-option value="ingegneri">Ingegneri</ng-option>
                    <ng-option value="architetti">Architetti</ng-option>
                  </ng-select>
                </div>

              </div>
              <div class="form-row mt-4">
                <div class="col-md">
                  <label>Provincia *</label>
                  <ng-select class="" name="provincia" #provincia="ngModel" [ngModel] [clearable]="false"  (change)="onChangeProvincia(provincia.value)" [ngClass]="{ 'is-invalid': (submitted && provincia.invalid )}" required>
                    <ng-option  *ngFor="let item of province" value="{{ item.provincia }}">{{ item.provincia }}</ng-option>
                  </ng-select>
                </div>
                <div class="col-md">
                  <label for="cap">Comune *</label>
                  <ng-select class="" name="comune" #comune="ngModel" [ngModel] [clearable]="false"  [ngClass]="{ 'is-invalid': (submitted && comune.invalid )}" required [disabled]="provincia.invalid">
                    <ng-option  *ngFor="let item of comuniProvince" value="{{ item.id }}">{{ item.comune }}</ng-option>
                  </ng-select>
                </div>
                <div class="col-md">
                  <label for="cap">CAP *</label>
                  <input type="text" class="form-control" id="cap" name="cap" #cap="ngModel" maxlength="5" [ngModel] [ngClass]="{ 'is-invalid': (submitted && cap.invalid )}" required>
                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-md-8">
                  <label for="indirizzo">Indirizzo *</label>
                  <input type="text" class="form-control required" id="indirizzo" name="indirizzo" #indirizzo="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && indirizzo.invalid )}" required>
                </div>
                <div class="col-md">
                  <label for="telefono">Telefono *</label>
                  <input type="text" class="form-control" id="telefono" name="telefono" #telefono="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && telefono.invalid )}" required>
                </div>
              </div>
              <hr>
              <div class="form-row">
                <div class="col-md">
                  <label>Codice Fiscale *</label>
                  <input type="text" class="form-control" name="cf" #cf="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && cf.invalid )}" required>
                </div>
                <div class="col-md">
                  <label>Partita I.V.A.</label>
                  <input type="text" class="form-control" name="pi" #pi="ngModel" [ngModel]>
                </div>
                <div class="col-md">
                  <label>Regione dell'Ordine Professionale *</label>
                  <input type="text" class="form-control" name="ordine" #ordine="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && ordine.invalid )}" required>
                </div>
                <div class="col-md">
                  <label>N. iscrizione Ordine Professionale *</label>
                  <input type="text" class="form-control" name="num_iscrizione" #num_iscrizione="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && num_iscrizione.invalid )}" required>
                </div>
              </div>
            </div>
          </div>


          <hr>
          <div class="input-group">
            <div class="checkbox mt-2">
              <label>
                <input type="checkbox" name="haiStudio" value="1"  #haiStudio="ngModel" [ngModel] class="mr-1">Hai uno studio?
              </label>
            </div>
          </div>

<!--          -->
          <div class="card text-left mt-3 box-studio" [hidden]="haiStudio.value != true">
            <div class="card-header">
              DATI STUDIO PROFESSIONALE
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-md">
                  <label>Nome studio *</label>
                  <input type="text" class="form-control" name="studioSede" #studioSede="ngModel" [ngModel] [ngClass]="{ 'is-invalid': ((submitted && studioSede.invalid) || (submitted && haiStudio.value == true))}" [required]="haiStudio.value == true" >
                </div>
              </div>
              <div class="form-row mt-4">

                <div class="col-md">
                  <label>Provincia *</label>
                  <ng-select class="" name="provinciaStudio" #provinciaStudio="ngModel" [ngModel] [clearable]="false"  (change)="onChangeProvinciaStudio(provinciaStudio.value)"
                             [ngClass]="{ 'is-invalid': ((submitted && provinciaStudio.invalid) || (submitted && haiStudio.value == true))}" [required]="haiStudio.value == true">
                    <ng-option  *ngFor="let item of province" value="{{ item.provincia }}">{{ item.provincia }}</ng-option>
                  </ng-select>
                </div>
                <div class="col-md">
                  <label for="cap">Comune *</label>
                  <ng-select class="" name="comuneStudio" #comuneStudio="ngModel" [ngModel] [clearable]="false"
                             [ngClass]="{ 'is-invalid': ((submitted && comuneStudio.invalid) || (submitted && haiStudio.value == true))}" [required]="haiStudio.value == true"  [disabled]="provinciaStudio.invalid">
                    <ng-option  *ngFor="let item of comuniProvinceStudio" value="{{ item.id }}">{{ item.comune }}</ng-option>
                  </ng-select>
                </div>
                <div class="col-md">
                  <label>CAP *</label>
                  <input type="text" class="form-control" name="studioCap" #studioCap="ngModel" [ngModel]
                         [ngClass]="{ 'is-invalid': ((submitted && studioCap.invalid) || (submitted && haiStudio.value == true)) }" [required]="haiStudio.value == true">

                </div>
              </div>
              <div class="form-row mt-4">
                <div class="col-md">
                  <label>Indirizzo *</label>
                  <input type="text" class="form-control" name="studioIndirizzo" #studioIndirizzo="ngModel" [ngModel]
                         [ngClass]="{ 'is-invalid': ((submitted && studioIndirizzo.invalid) || (submitted && haiStudio.value == true))}" [required]="haiStudio.value == true">
                </div>
              </div>
            </div>
          </div>
          <div class="card text-left mt-4">
            <div class="card-header">
              DATI DI ACCESSO
            </div>
            <div class="card-body">
              <div class="form-row">
                <div class="col-md">
                  <label>E-mail * </label>
                  <input type="email" class="form-control" name="username" #username="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && username.invalid )}" required>
                </div>
                <div class="col-md">
                  <label>Password * </label>
                  <input type="password" class="form-control" name="password" #password="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && password.invalid || (submitted && password.value != confermaPassword.value) )}" required>
                </div>
                <div class="col-md">
                  <label>Conferma password * </label>
                  <input type="password" class="form-control" name="confermaPassword" #confermaPassword="ngModel" [ngModel] [ngClass]="{ 'is-invalid': (submitted && confermaPassword.invalid || (submitted && password.value != confermaPassword.value))}" required>
                </div>
              </div>
              <small id="emailHelp" class="form-text text-muted">E-mail e password ti serviranno per accedere al sistema.</small>
            </div>
          </div>

          <hr>

          <div class="input-group">
            <div class="checkbox">
              <label [ngClass]="{ 'is-invalid': (submitted && condizioni.invalid)}">
                <input type="checkbox" name="condizioni" #condizioni="ngModel" [(ngModel)]="condizioniValue" required> Accetto le
                <a href="javascript: void(0)" (click)="openModalCondizioniCheckbox(modalCondizioni)">condizioni di utilizzo del presente portale</a>
              </label>
            </div>
          </div>

          <hr>
          <button class="btn btn-success registrati">REGISTRATI</button>
        </form>

      </div>
    </div>
  </div>
</section>


<!-- ############################# MODAL CONDIZIONI ############################# -->
<ng-template #modalCondizioni>
  <div class="modal-header">
    <h5 class="modal-title">Il portale Indagini Geologiche della Regione Abruzzo</h5>
    <button type="button" class="close pull-right" aria-label="Close" (click)="bsModalRef.hide()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
      ...
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-secondary" (click)="bsModalRef.hide()">Chiudi</button>
    <button type="button" class="btn btn-primary" (click)="accettoCondizioni()">Accetto</button>

  </div>
</ng-template>
