import { Component, OnInit } from '@angular/core';
import {DataService} from "../../service/data.service";
import {HttpClient} from "@angular/common/http";
import {LocalStorageService} from "angular-web-storage";
import {Router} from "@angular/router";
import jwt_decode from "jwt-decode";
import {GlobalConstants} from "../../global-constants";
import {ToastrService} from "ngx-toastr";

@Component({
  selector: 'app-verifica',
  templateUrl: './verifica.component.html',
  styleUrls: ['./verifica.component.less']
})
export class VerificaComponent implements OnInit {

  constructor(private dataService: DataService, private httpClient: HttpClient, private local: LocalStorageService,private router: Router, private toastr: ToastrService) {}

  jwtGA;
  usernamePrivate = '';
  submitted = false;
  submittedCodice = false;

  recuperaCodice: boolean = false;
  resetCode: boolean = false;

  ngOnInit(): void {
    this.jwtGA = this.local.get("jwtGA");
    //console.log("jwtGA", this.jwtGA);
    if (this.jwtGA == null) {
      this.router.navigate(['/login']); //redirect (alla login?)
    }

    //rende "privata" l'etichetta dell'email
    for (var i = 0; i < (this.jwtGA.username).length; i++) {
      if (i < 4 || i >= ((this.jwtGA.username).length - 4)) {
        this.usernamePrivate = this.usernamePrivate + this.jwtGA.username[i];
      } else {
        this.usernamePrivate = this.usernamePrivate + '*';
      }
    }
  }



  onSubmit(form) { //al submit
    this.submitted = true; // abbiamo fatto un submit, serve per la validazione
    if (form.invalid) { // stop here if form is invalid
      return;
    }


    var valori = form.form.value; //prendo i valori inseriti nella form
    //console.log("valori form", valori);

    this.dataService.verificaGA(valori).subscribe((risposta: any) => {
      //console.log("verificaGA qrcode", risposta);

      if (risposta.verificaGA == "ok") {
        this.local.clear();
        this.local.set("jwt", risposta, 6, 'h');

        var tok = this.local.get("jwt").token;
        var decoded = jwt_decode(tok);// decodifica il token jwt per prendere le sue info

        if (decoded['isActive'] == 1) {
          //this.local.set("jwt", risposta, 6, 'h');

          this.local.set("userLogin", decoded, 6, 'h');

          this.dataService.changeDecodedJwt(decoded); //cambiamo la variabile con servizio perchè serve al component header

          this.router.navigate(['/siti']); //redirect (alla login?)
        } else {
          this.local.clear();
          this.toastr.error( GlobalConstants.ERROR_NO_ACTIVE_TESTO.replace("{username}",decoded['username']), GlobalConstants.ERROR_NO_ACTIVE_TITOLO);
        }

        this.local.set('storico', null);
      } else if (risposta.verificaGA == "no-Ok") {
        this.toastr.error( GlobalConstants.ERROR_CODICE2FATTORI_TESTO, GlobalConstants.ERROR_CODICE2FATTORI_TITOLO);
      } else {
        this.router.navigate(['/login']);
      }

    })
  }


  mostraRecuperaCodice(recuperaCodice) {
    if (recuperaCodice == false) {
      this.recuperaCodice = true;
    } else {
      this.recuperaCodice = false;
    }
    //console.log("recuperaCodice", recuperaCodice);
  }

  inviaEmailResetCode() {
    if (this.resetCode == false) {
      this.dataService.recuperaCodiceGA(this.jwtGA.username).subscribe((risposta: any) => {
        //console.log("recuperaCodiceGA", risposta);
        this.toastr.success( GlobalConstants.SUCCESS_MAIL_2FA_TESTO.replace("{username}",this.usernamePrivate), GlobalConstants.SUCCESS_MAIL_2FA_TITOLO);
      });
      this.resetCode = true;
    }
  }


  onSubmitCodice(form) { //al submit
    this.submittedCodice = true; // abbiamo fatto un submit, serve per la validazione
    if (form.invalid) { // stop here if form is invalid
      return;
    }

    var valori = form.form.value; //prendo i valori inseriti nella form
    //console.log("valori form", valori);

    this.dataService.confermaCodiceGA(this.jwtGA.username, valori.codiceEmail).subscribe((risposta: any) => {
      //console.log("recuperaCodiceGA", risposta);

      if (risposta.stato == "ok") {
        this.toastr.success( GlobalConstants.SUCCESS_RESET_2FA_TESTO, GlobalConstants.SUCCESS_RESET_2FA_TITOLO);
        this.router.navigate(['/login']);
      } else {
        this.toastr.error( GlobalConstants.ERROR_CONFERMA_2FA_TESTO, GlobalConstants.ERROR_CONFERMA_2FA_TITOLO);
      }
    });

  }



}
