import {Component, OnInit} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DataService} from '../../../service/data.service';
import jwt_decode from "jwt-decode";
import {NgxUiLoaderService} from "ngx-ui-loader";

@Component({
    selector: 'app-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

    constructor(private ngxService: NgxUiLoaderService, private titleService: Title, private dataService: DataService) {
    }

    logged = false;
    decodedJwt = null;

    ngOnInit(): void {
        this.dataService.decodedJwt$.subscribe(aaa=> this.decodedJwt = aaa);


        if (this.dataService.isAuthenticated()) {
            this.logged = true;
            if (this.decodedJwt != null) {
                this.decodedJwt = jwt_decode(this.decodedJwt);
            } else {
                var tok = localStorage.getItem('jwt');
                this.decodedJwt = jwt_decode(tok);// decodifica il token jwt per prendere le sue info
            }

            //console.log("decodedJwt", this.decodedJwt);
        }


    }

    setSiteTitle(title: string) {
        //console.log('current title:::::' + this.titleService.getTitle());
        this.titleService.setTitle(title);
    }


    logOut () {
        this.logged = false;
        this.decodedJwt = null;
        localStorage.removeItem('jwt');
        localStorage.removeItem('userLogin');
        localStorage.clear();
    }


  mostraLoader() {
    console.log("deve partire il loader!");
/*
    this.ngxService.start(); // start foreground spinner of the master loader with 'default' taskId
    // Stop the foreground loading after 5s
    setTimeout(() => {
      this.ngxService.stop(); // stop foreground spinner of the master loader with 'default' taskId
    }, 4000);
*/

  }


}
